import React from "react";
import { connect } from "react-redux";

import { BrowserRouter, Route, Switch } from "react-router-dom";

import { ContractsStateController } from "../core/contracts";
import { loadMultipleContractAbis } from "../storage/state/contracts/actions";
import { AppReducer, AppState } from "../storage/types";
import { BlockChain } from "../core/chain";

import {
  destroyBlockChainController,
  saveBlockChainController,
  setBlockChainError,
  setCustomer,
} from "../storage/state/blockChain/actions";

import {
  changeEmployeesPagination,
  saveAppData,
  toggleLeftNavigation,
  toggleLoader,
} from "../storage/state/app/actions";

import { ContractsState } from "../storage/state/contracts/state";
import { appConfig, AppErrorCode, Contract } from "../core/app";
import { BlockChainState } from "../storage/state/blockChain/state";
import { NavWindow } from "./types";
import { BlockChainHelpers } from "../core/helpers/chain";
import { Customer } from "../core/customer";
import { UtilsHelpers } from "../core/helpers/utils";
import { ErrorComponent } from "./molecules/error";
import { TopNavigation } from "./organisms/navigation/top";
import { ApplicationState } from "../storage/state/app/state";
import { BuilderPage } from "./pages/builder";
import { Loader } from "./organisms/loader";
import { Footer } from "./organisms/navigation/footer";
import { LeftNavigation } from "./organisms/navigation/left";
import { AppData } from "../core/types";
import { FactoryBuilderPage } from "./pages/factories";
import {
  gameAddEmployeeToSelection,
  gameChangeSelectedFactory,
  gameRemoveEmployeeFromSelection,
  gameResetEmployeeSelection,
} from "../storage/state/game/actions";
import { RelationsGameState } from "../storage/state/game/state";
import { MultiEmployeesPage } from "./pages/multiEmployee";
import { MiniEmployeesPage } from "./pages/miniEmployees";
import {
  changeSelectedEmployee,
  onClearEmployeeSelection,
  removeSelectedEmployee,
} from "../storage/state/students/actions";
import { StudentsGameState } from "../storage/state/students/state";
import { UpgradeEmployeesPage } from "./pages/upgrades";

import { toast, ToastContainer } from "react-toastify";
import { NFTBridgePage } from "./pages/bridge";
import { FactoryNFT } from "../core/nfts/factories";
import { EmployeeNFT } from "../core/nfts/employee";
import { CitiesBuilderPage } from "./pages/cities";
import { UpgradeCitiesPage } from "./pages/cityUpgrades";
import { RelationsPage } from "./pages/relations";
import { ProfilePage } from "./pages/profile";
import { TeamBuilderPage } from "./pages/teamBuilder";

interface AppComponentProps {
  loadMultipleContractAbis: (contracts: Contract[]) => void;
  saveBlockChainController: (controller: BlockChain) => void;
  destroyBlockChainController: () => void;
  setBlockChainError: (error: AppErrorCode | null) => void;
  setCustomer: (customer: Customer) => void;
  toggleLeftNavigation: (force?: boolean) => void;
  toggleLoader: (force?: boolean) => void;
  changeEmployeesPagination: (page: number) => void;
  saveAppData: (data: AppData) => void;
  gameChangeSelectedFactory: (factory: FactoryNFT | undefined) => void;
  gameAddEmployeeToSelection: (employee: EmployeeNFT) => void;
  gameResetEmployeeSelection: () => void;
  gameRemoveEmployeeFromSelection: (employee: EmployeeNFT) => void;
  changeSelectedEmployee: (employee: EmployeeNFT) => void;
  removeSelectedEmployee: (employee: EmployeeNFT) => void;
  onClearEmployeeSelection: () => void;
  relationsGameState: RelationsGameState;
  studentsGameState: StudentsGameState;
  appState: ApplicationState;
  contracts: ContractsState;
  blockChain: BlockChainState;
}

interface AppComponentState {
  contractLoading: boolean;
}

const mapStateToProps = (state: AppState) => {
  return {
    appState: state[AppReducer.APP],
    contracts: state[AppReducer.CONTRACTS],
    blockChain: state[AppReducer.BLOCK_CHAIN] as BlockChainState,
    relationsGameState: state[AppReducer.RELATIONS_GFAME],
    studentsGameState: state[AppReducer.STUDENTS_GAME],
  };
};

const mapDispatchToProps = {
  loadMultipleContractAbis,
  saveBlockChainController,
  destroyBlockChainController,
  setBlockChainError,
  setCustomer,
  toggleLeftNavigation,
  toggleLoader,
  changeEmployeesPagination,
  saveAppData,
  gameChangeSelectedFactory,
  gameResetEmployeeSelection,
  gameRemoveEmployeeFromSelection,
  gameAddEmployeeToSelection,
  changeSelectedEmployee,
  removeSelectedEmployee,
  onClearEmployeeSelection,
};

export class App extends React.Component<AppComponentProps, AppComponentState> {
  constructor(props: AppComponentProps) {
    super(props);

    this.state = {
      contractLoading: false,
    };
  }

  async componentDidMount() {
    toast.success("Welcome to BusinessBuilders.");
    this._validateAndLoadChainNetwork();
  }

  private async _validateAndLoadChainNetwork() {
    const connected = await BlockChainHelpers.loadWeb3();

    if (connected) {
      if (appConfig.open) {
        UtilsHelpers.debugger("Link start.");
        const windowParse = window as NavWindow;

        if (windowParse.ethereum) {
          windowParse.ethereum.on("chainChanged", async () => {
            this.props.toggleLoader(true);
            this._loadContracts();
          });

          windowParse.ethereum.on("accountsChanged", () => {
            this.props.toggleLoader(true);
            this._loadContracts();
          });

          await this._loadContracts();
        } else {
          this.props.setBlockChainError(AppErrorCode.INVALID_PROVIDER);
          UtilsHelpers.debugger("[APP] Error, Invalid provider.");
        }
      }
    }
  }

  private async _loadContracts() {
    await BlockChainHelpers.validateBlockChain((correct: boolean) => {
      if (correct) {
        if (!ContractsStateController.isLoaded(this.props.contracts)) {
          this.props.loadMultipleContractAbis([
            Contract.TOKEN,
            Contract.EMPLOYEES,
            Contract.BASE_DEPLOYER,
            Contract.EMPLOYEE_DEPLOYER,
            Contract.FACTORIES,
            Contract.FACTORY_DEPLOYER,
            Contract.MULTI_EMPLOYEE,
            Contract.MULTI_EMPLOYEE_D,
            Contract.MINI_EMPLOYEES,
            Contract.MINI_EMPLOYEES_D,
            Contract.EMPLOYEES_EXPANDED,
            Contract.NFT_BRIDGE_STORAGE,
            Contract.CITIES,
            Contract.CITIES_DEPLOYER,
            Contract.SPECIAL_TOKEN,
            Contract.CITIES_STORAGE,
            Contract.UNIVERSITIES,
            Contract.CITY_GETTERS,
            Contract.TEAM_LEADER_V,
            Contract.TEAM_LEADER,
            Contract.TEAM_BUILDER,
            Contract.EMPLOYEES_TEAM,
          ]);
        }
      } else {
        this.props.setBlockChainError(AppErrorCode.INCORRECT_BLOCKCHAIN_NETWORK);

        this.props.destroyBlockChainController();
        this.props.toggleLoader(false);
      }
    });
  }

  componentDidUpdate() {
    if (!this.state.contractLoading && ContractsStateController.isLoaded(this.props.contracts)) {
      this.setState({ contractLoading: true }, () => {
        if (appConfig.open) this.loadBlockChain();
      });
    }
  }

  async changeToAppNetwork() {
    await BlockChainHelpers.reloadOrChangeNetwork();
  }

  loadBlockChain() {
    try {
      let blockChain = new BlockChain();

      blockChain.principalListener.on(AppErrorCode.INCORRECT_BLOCKCHAIN_NETWORK, () => {
        this.props.setBlockChainError(AppErrorCode.INCORRECT_BLOCKCHAIN_NETWORK);
      });
      blockChain.loadBlockChainData(this.props.contracts, async (err: AppErrorCode | null) => {
        if (err) this.props.setBlockChainError(err);
        this.props.saveBlockChainController(blockChain);
        this.loadCustomerData(true);
      });
    } catch (error) {
      console.log(error);
    }
  }

  async loadCustomerData(inTheEnd: boolean = false) {
    try {
      if (this.props.blockChain.controller) {
        let { customer, appData, error } = await Customer.loadCustomerData(this.props.blockChain.controller);

        if (error) this.props.setBlockChainError(error);
        else {
          if (customer) this.props.setCustomer(customer);
          if (appData) this.props.saveAppData(appData);
        }

        if (inTheEnd) this.props.toggleLoader(false);
      }
    } catch (error) {
      toast.error("Invalid user loading, please report the error in the telegram group.");
    }
  }

  componentWillUnmount() {
    this.props.destroyBlockChainController();
  }

  render() {
    return (
      <BrowserRouter>
        <div className="ct-app-container">
          <Loader open={this.props.appState.loader} />
          <TopNavigation
            onLoadCustomerData={() => {
              this.props.toggleLoader(true);
              this.loadCustomerData(true);
            }}
            onToggleLeftNav={() => this.props.toggleLeftNavigation()}
            onLoadBlockChain={() => this._validateAndLoadChainNetwork()}
            customer={this.props.blockChain.customer}
            onToggleLoader={(force: boolean) => this.props.toggleLoader(force)}
          ></TopNavigation>
          <LeftNavigation
            onToggleLeftNavigation={() => this.props.toggleLeftNavigation()}
            open={this.props.appState.leftNavigation}
          />
          {this.props.blockChain.error !== null ? (
            <ErrorComponent
              onRemoveError={() => this.props.setBlockChainError(null)}
              validateBlockChain={() => this._validateAndLoadChainNetwork()}
              code={this.props.blockChain.error}
              blockChain={this.props.blockChain.controller}
              customer={this.props.blockChain.customer}
              onChangeNetwork={() => {
                this.props.setBlockChainError(null);
                this.props.toggleLoader(true);
                this.changeToAppNetwork();
              }}
            />
          ) : (
            ""
          )}
          {appConfig.open ? (
            this.props.blockChain.controller && this.props.contracts ? (
              <Switch>
                <Route exact path="/employees">
                  {this.props.appState.appData && this.props.blockChain && this.props.blockChain.customer ? (
                    <BuilderPage
                      appState={this.props.appState}
                      blockChain={this.props.blockChain}
                      onLoadBlockChain={() => {
                        this._validateAndLoadChainNetwork();
                      }}
                      onToggleLoader={(force: boolean) => this.props.toggleLoader(force)}
                      onSetBlockChainError={(error: AppErrorCode) => {
                        this.props.toggleLoader(false);
                        this.props.setBlockChainError(error);
                      }}
                      onLoadCustomerData={(inTheEnd: boolean) => this.loadCustomerData(inTheEnd)}
                      onChangeEmployeesPagination={(page: number) => this.props.changeEmployeesPagination(page)}
                    />
                  ) : (
                    ""
                  )}
                </Route>
                <Route exact path="/team-building">
                  {this.props.appState.appData && this.props.blockChain && this.props.blockChain.customer ? (
                    <TeamBuilderPage
                      appState={this.props.appState}
                      blockChain={this.props.blockChain}
                      onLoadCustomerData={(inTheEnd: boolean) => {
                        this.loadCustomerData(inTheEnd);
                      }}
                      onSetBlockChainError={(error: AppErrorCode) => {
                        this.props.setBlockChainError(error);
                      }}
                      onToggleLoader={(force?: boolean) => this.props.toggleLoader(force)}
                    />
                  ) : (
                    ""
                  )}
                </Route>
                <Route exact path="/factories">
                  {this.props.appState.appData && this.props.blockChain && this.props.blockChain.customer ? (
                    <FactoryBuilderPage
                      appState={this.props.appState}
                      blockChain={this.props.blockChain}
                      onLoadBlockChain={() => {
                        this._validateAndLoadChainNetwork();
                      }}
                      onToggleLoader={(force: boolean) => this.props.toggleLoader(force)}
                      onSetBlockChainError={(error: AppErrorCode) => {
                        this.props.setBlockChainError(error);
                      }}
                      onLoadCustomerData={(inTheEnd: boolean) => this.loadCustomerData(inTheEnd)}
                      onChangeEmployeesPagination={(page: number) => this.props.changeEmployeesPagination(page)}
                    />
                  ) : (
                    ""
                  )}
                </Route>
                <Route exact path="/multi-employees">
                  {this.props.blockChain.customer && this.props.appState.appData && this.props.blockChain.controller ? (
                    <MultiEmployeesPage
                      appState={this.props.appState}
                      blockChain={this.props.blockChain}
                      onLoadCustomerData={(inTheEnd: boolean) => {
                        this.loadCustomerData(inTheEnd);
                      }}
                      onSetBlockChainError={(error: AppErrorCode) => {
                        this.props.setBlockChainError(error);
                      }}
                      onToggleLoader={(force?: boolean) => this.props.toggleLoader(force)}
                    />
                  ) : (
                    ""
                  )}
                </Route>
                <Route exact path="/cities">
                  {this.props.blockChain.customer && this.props.appState.appData && this.props.blockChain.controller ? (
                    <CitiesBuilderPage
                      appState={this.props.appState}
                      blockChain={this.props.blockChain}
                      onLoadCustomerData={(inTheEnd: boolean) => {
                        this.loadCustomerData(inTheEnd);
                      }}
                      onSetBlockChainError={(error: AppErrorCode) => {
                        this.props.setBlockChainError(error);
                      }}
                      onToggleLoader={(force?: boolean) => this.props.toggleLoader(force)}
                    />
                  ) : (
                    ""
                  )}
                </Route>
                <Route exact path="/cities-upgrade">
                  {this.props.blockChain.customer && this.props.appState.appData && this.props.blockChain.controller ? (
                    <UpgradeCitiesPage
                      appState={this.props.appState}
                      blockChain={this.props.blockChain}
                      onLoadCustomerData={(inTheEnd: boolean) => {
                        this.loadCustomerData(inTheEnd);
                      }}
                      onSetBlockChainError={(error: AppErrorCode) => {
                        this.props.setBlockChainError(error);
                      }}
                      onToggleLoader={(force?: boolean) => this.props.toggleLoader(force)}
                    />
                  ) : (
                    ""
                  )}
                </Route>
                <Route exact path="/merge-employees">
                  {this.props.blockChain.customer && this.props.appState.appData && this.props.blockChain.controller ? (
                    <UpgradeEmployeesPage
                      appState={this.props.appState}
                      blockChain={this.props.blockChain}
                      onLoadCustomerData={(inTheEnd: boolean) => {
                        this.loadCustomerData(inTheEnd);
                      }}
                      onSetBlockChainError={(error: AppErrorCode) => {
                        this.props.setBlockChainError(error);
                      }}
                      onToggleLoader={(force?: boolean) => this.props.toggleLoader(force)}
                    />
                  ) : (
                    ""
                  )}
                </Route>
                <Route exact path="/mini-employees">
                  {this.props.blockChain.customer && this.props.appState.appData && this.props.blockChain.controller ? (
                    <MiniEmployeesPage
                      appState={this.props.appState}
                      blockChain={this.props.blockChain}
                      onLoadCustomerData={(inTheEnd: boolean) => {
                        this.loadCustomerData(inTheEnd);
                      }}
                      onSetBlockChainError={(error: AppErrorCode) => {
                        this.props.setBlockChainError(error);
                      }}
                      onToggleLoader={(force?: boolean) => this.props.toggleLoader(force)}
                    />
                  ) : (
                    ""
                  )}
                </Route>
                <Route exact path="/">
                  {this.props.blockChain.customer && this.props.appState.appData && this.props.blockChain.controller ? (
                    <ProfilePage
                      appState={this.props.appState}
                      blockChain={this.props.blockChain}
                      onLoadCustomerData={(inTheEnd: boolean) => {
                        this.loadCustomerData(inTheEnd);
                      }}
                      onSetBlockChainError={(error: AppErrorCode) => {
                        this.props.setBlockChainError(error);
                      }}
                      onToggleLoader={(force?: boolean) => this.props.toggleLoader(force)}
                    />
                  ) : (
                    ""
                  )}
                </Route>
                <Route exact path="/nft-bridge">
                  {this.props.blockChain.customer && this.props.appState.appData && this.props.blockChain.controller ? (
                    <NFTBridgePage
                      appState={this.props.appState}
                      blockChain={this.props.blockChain}
                      onLoadCustomerData={(inTheEnd: boolean) => {
                        this.loadCustomerData(inTheEnd);
                      }}
                      onSetBlockChainError={(error: AppErrorCode) => {
                        this.props.setBlockChainError(error);
                      }}
                      onToggleLoader={(force?: boolean) => this.props.toggleLoader(force)}
                    />
                  ) : (
                    ""
                  )}
                </Route>
                <Route exact path="/relations">
                  {this.props.blockChain.customer && this.props.appState.appData && this.props.blockChain.controller ? (
                    <RelationsPage
                      appState={this.props.appState}
                      blockChain={this.props.blockChain}
                      onLoadCustomerData={(inTheEnd: boolean) => {
                        this.loadCustomerData(inTheEnd);
                      }}
                      onSetBlockChainError={(error: AppErrorCode) => {
                        this.props.setBlockChainError(error);
                      }}
                      onToggleLoader={(force?: boolean) => this.props.toggleLoader(force)}
                    />
                  ) : (
                    ""
                  )}
                </Route>
              </Switch>
            ) : (
              <ErrorComponent
                onRemoveError={() => this.props.setBlockChainError(null)}
                validateBlockChain={() => this._validateAndLoadChainNetwork()}
                blockChain={this.props.blockChain.controller}
                customer={this.props.blockChain.customer}
                onChangeNetwork={() => {
                  this.props.setBlockChainError(null);
                  this.props.toggleLoader(true);
                  this.changeToAppNetwork();
                }}
                code={this.props.blockChain.error ? this.props.blockChain.error : AppErrorCode.INVALID_CONTRACT_LOADING}
              />
            )
          ) : (
            ""
          )}

          <ToastContainer theme="light" limit={3} />
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
