import React from "react";
import { appConfig, AppErrorCode } from "../../../../core/app";
import { TokenController } from "../../../../core/modules/token";
import { CityNFT } from "../../../../core/nfts/cities";
import { CitiesController } from "../../../../core/modules/cities";

import "./cities.styles.css";

export interface StaticNFTProps {
  small?: boolean;
  deactivated?: boolean;
  selected?: boolean;
  ultraSmall?: boolean;
  nft: CityNFT;
  className?: string;
  staticImage?: boolean;
  travel?: boolean;
  token?: TokenController;
  cities: CitiesController;
  validateRelations: boolean;
  onSell?: (price: number) => void;
  onClick?: (city: CityNFT) => void;
}

export interface StaticCityNFTState {
  actionMessage: null | string;
  sellPrice: string;
  loader: boolean;
  hasRelations: boolean;
  error: AppErrorCode | null;
}

export class StaticCityNFT extends React.Component<
  StaticNFTProps,
  StaticCityNFTState
> {
  constructor(props: StaticNFTProps) {
    super(props);

    this.state = {
      actionMessage: null,
      sellPrice: "",
      loader: false,
      error: null,
      hasRelations: false,
    };
  }

  componentDidMount() {
    this.reloadCityData();
  }

  async reloadCityData() {
    let hasRelations = false;

    if (this.props.cities?.relationStorage) {
      hasRelations =
        Number(
          await this.props.cities.relationStorage.getTotalCityRelations(
            this.props.nft.id
          )
        ) > 0;
    }

    this.setState({
      loader: false,
      hasRelations,
    });
  }

  render() {
    let selectedMessage = null;

    switch (this.state.actionMessage) {
      default:
        selectedMessage = null;
        break;
    }

    return (
      <div
        onClick={() =>
          this.props.onClick
            ? this.props.selected
              ? this.props.onClick(this.props.nft)
              : !this.props.validateRelations ||
                (this.props.validateRelations && !this.state.hasRelations)
              ? this.props.onClick(this.props.nft)
              : null
            : null
        }
        className={
          "ct-nft" +
          (this.props.className ? this.props.className : "") +
          (this.props.small ? " ct-small" : "") +
          (this.props.ultraSmall ? " ct-ultra-small" : "") +
          (this.props.onClick ? " ct-click" : "") +
          (this.props.deactivated ||
          (this.props.validateRelations &&
            this.state.hasRelations &&
            !this.props.selected)
            ? " ct-un-click"
            : "") +
          (this.props.selected ? " ct-selected" : "")
        }
      >
        {this.state.loader ? (
          <div className="ct-loader">
            <div className="spinner"></div>
          </div>
        ) : (
          ""
        )}
        <div className="ct-image">
          <img
            src={
              this.props.staticImage
                ? this.props.nft.uri
                : this.props.nft.uri + "&onlyImage=true"
            }
            alt=""
          />
        </div>
        <div className="ct-data">
          <span>
            <strong>ID: #</strong> {this.props.nft.id}
          </span>
          {typeof this.props.nft.name === "string" ? (
            <span>
              <strong>Name: </strong> {this.props.nft.name}
            </span>
          ) : (
            ""
          )}
          {typeof this.props.nft.world === "number" ? (
            <span>
              <strong>World: </strong> {this.props.nft.world}
            </span>
          ) : (
            ""
          )}
          {typeof this.props.nft.points === "number" ? (
            <span>
              <strong>Points: </strong> {this.props.nft.points}
            </span>
          ) : (
            ""
          )}
          {typeof this.props.nft.lands === "number" ? (
            <span>
              <strong>Lands: </strong> {this.props.nft.lands}
            </span>
          ) : (
            ""
          )}
          {typeof this.props.nft.townHall === "number" ? (
            <span>
              <strong>TownHall: </strong>{" "}
              {(!!this.props.nft.townHall).toString()}
            </span>
          ) : (
            ""
          )}
          {typeof this.props.nft.university === "number" ? (
            <span>
              <strong>University: </strong>{" "}
              {(!!this.props.nft.university).toString()}
            </span>
          ) : (
            ""
          )}
          {typeof this.state.hasRelations === "boolean" ? (
            <span>
              <strong>Has relations: </strong>{" "}
              {(!!this.state.hasRelations).toString()}
            </span>
          ) : (
            ""
          )}
        </div>
        <div className="ct-actions-container">
          <div className="ct-actions">
            {this.props.travel ? (
              <a
                href={
                  appConfig.universeUrl +
                  "?world=" +
                  this.props.nft.world +
                  "&city=" +
                  this.props.nft.id
                }
                target="_blank"
                rel="noopener noreferrer"
                className="ct-main-button"
              >
                <span className="fas fa-plane"></span>
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="ct-action-message">{selectedMessage}</div>
        </div>
      </div>
    );
  }
}
