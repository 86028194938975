import React from "react";
import { AppErrorCode, Contract } from "../../core/app";
import { FactoryBuilderActions } from "../organisms/builder/factories/actions";
import { BuilderFactoriesGrid } from "../organisms/builder/factories/grid";
import { BlockChainState } from "../../storage/state/blockChain/state";
import { ApplicationState } from "../../storage/state/app/state";
import { TokenController } from "../../core/modules/token";
import { FactoryDeployerController } from "../../core/modules/factoryDeployer";
import { FactoriesController } from "../../core/modules/factories";
import { MarketplaceController } from "../../core/modules/marketplace";
import { FactoryStakingController } from "../../core/modules/factoryStaking";
import Web3 from "web3";
import { TeamLeader } from "../organisms/teamLeader";

interface FactoryBuilderComponentProps {
  appState: ApplicationState;
  blockChain: BlockChainState;
  onLoadCustomerData: (inTheEnd: boolean) => void;
  onLoadBlockChain: () => void;
  onToggleLoader: (froce: boolean) => void;
  onChangeEmployeesPagination: (page: number) => void;
  onSetBlockChainError: (error: AppErrorCode) => void;
}

interface FactoryBuilderComponentState {
  factoryDeployer: null | FactoryDeployerController;
  factories: null | FactoriesController;
  token: null | TokenController;
  marketplace: null | MarketplaceController;
  factoryStaking: null | FactoryStakingController;
  factoriesLoader: boolean;
  factoryDeployerAllowance: number;
}

export class FactoryBuilderPage extends React.PureComponent<
  FactoryBuilderComponentProps,
  FactoryBuilderComponentState
> {
  constructor(props: FactoryBuilderComponentProps) {
    super(props);

    this.state = {
      factoryDeployer: null,
      token: null,
      marketplace: null,
      factories: null,
      factoryStaking: null,
      factoriesLoader: false,
      factoryDeployerAllowance: 0,
    };
  }

  async componentDidMount() {
    this.preloadControllers();
    this.loadAndSetFactoriesController();
  }

  async preloadControllers() {
    let token = null;
    let factoryDeployer = null;
    let factoryDeployerAllowance = 0;

    //Load Token info
    if (
      this.props.blockChain.controller?.token &&
      this.props.appState.appData &&
      this.props.blockChain.controller.factoryDeployer?.address
    ) {
      token = new TokenController(this.props.blockChain.controller.token);

      factoryDeployerAllowance = Number(
        Web3.utils.fromWei(
          await token.getAllowance(
            this.props.blockChain.controller.factoryDeployer?.address
          ),
          "ether"
        )
      );
    }

    //Load Factory deployer info
    if (
      this.props.blockChain.controller?.factoryDeployer &&
      this.props.appState.appData
    ) {
      factoryDeployer = new FactoryDeployerController(
        this.props.blockChain.controller.factoryDeployer
      );
    }

    this.setState(
      {
        token,
        factoryDeployer,
        factoriesLoader: false,
        factoryDeployerAllowance,
      },
      () => {
        this.props.onToggleLoader(false);
      }
    );
  }

  async onUpdateData(error: AppErrorCode | null) {
    if (error) this.props.onSetBlockChainError(error);
    await this.preloadControllers();
    this.loadAndSetFactoriesController();
    this.props.onLoadCustomerData(true);
  }

  async preloadFactoriesController() {
    let factories = null;

    if (
      this.props.blockChain.controller?.factories &&
      this.props.blockChain.controller?.cityRelationsStorage &&
      this.props.appState.appData
    ) {
      factories = new FactoriesController(
        this.props.blockChain.controller.factories,
        this.props.blockChain.controller.cityRelationsStorage,
        this.props.appState.appData
      );

      await factories.loadFactoriesData();
    }

    return factories;
  }

  async loadAndSetFactoriesController() {
    this.setState({ factoriesLoader: true }, async () => {
      const factories = await this.preloadFactoriesController();
      this.setState({ factories, factoriesLoader: false });
    });
  }

  render() {
    return (
      <React.Fragment>
        {this.props.blockChain.customer?.teamLeader ? (
          <TeamLeader teamLeader={this.props.blockChain.customer?.teamLeader} />
        ) : (
          ""
        )}
        <div className="ct-max-container ct-builder-page">
          <div className="ct-factories">
            {this.props.blockChain.controller &&
            this.props.blockChain.customer &&
            this.props.appState.appData ? (
              <FactoryBuilderActions
                factoryDeployerAllowance={this.state.factoryDeployerAllowance}
                blockChain={this.props.blockChain.controller}
                appData={this.props.appState.appData}
                onMint={(employees: number[]) => {
                  if (this.props.blockChain.controller) {
                    this.props.onToggleLoader(true);
                    this.state.factoryDeployer?.mintFactory(
                      employees,
                      (error: AppErrorCode | null) => {
                        this.onUpdateData(error);
                      }
                    );
                  }
                }}
                onApprove={() => {
                  if (this.props.appState.appData && this.state.token) {
                    this.props.onToggleLoader(true);
                    this.state.token.approveTokenSpend(
                      this.props.appState.appData.contractsAddress[
                        Contract.FACTORY_DEPLOYER
                      ],
                      this.props.appState.appData?.factoriesData.factoryPrice,
                      (error: AppErrorCode | null) => {
                        this.onUpdateData(error);
                      }
                    );
                  }
                }}
              />
            ) : (
              ""
            )}

            {this.props.appState.appData &&
            this.state.factories &&
            this.state.token ? (
              <BuilderFactoriesGrid
                nftsPerPage={8}
                factoriesLoader={this.state.factoriesLoader}
                token={this.state.token}
                factories={this.state.factories}
                appData={this.props.appState.appData}
                page={this.props.appState.employeesPagination}
                onUpdate={() => this.loadAndSetFactoriesController()}
                onChangeFactoriesPage={(page: number) =>
                  this.props.onChangeEmployeesPagination(page)
                }
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
