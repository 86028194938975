import React from "react";
import { AppChain, AppErrorCode, Contract, posibleChains, posibleContractAddress, relatedModeToChain } from "../../core/app";

import { BuilderEmployeesGrid } from "../organisms/builder/employees/grid";
import { BlockChainState } from "../../storage/state/blockChain/state";
import { ApplicationState } from "../../storage/state/app/state";
import { EmployeesController } from "../../core/modules/employees";
import { UtilsHelpers } from "../../core/helpers/utils";
import { FactoriesController } from "../../core/modules/factories";
import { EmployeeNFT } from "../../core/nfts/employee";
import { FactoryNFT } from "../../core/nfts/factories";

import PolygonLogo from "../../assets/images/polygon-matic.png";
import MeterLogo from "../../assets/images/meter-network.png";

import { BlockChainHelpers } from "../../core/helpers/chain";
import { ApiHelpers } from "../../core/helpers/api";
import { toast } from "react-toastify";
import { ReactText } from "hoist-non-react-statics/node_modules/@types/react";

import EmployeeImage from "../../assets/images/employees/4_4_4_4.png";
import MiniEmployeeImage from "../../assets/images/miniEmployees/5_5_3_5.png";
import FactoryImage from "../../assets/images/factories/2.png";
import MultiImage from "../../assets/images/employees/1_1_1_1.png";

import { BuilderFactoriesGrid } from "../organisms/builder/factories/grid";
import Web3 from "web3";

interface NFTBridgeComponentProps {
  appState: ApplicationState;
  blockChain: BlockChainState;
  onLoadCustomerData: (inTheEnd: boolean) => void;
  onToggleLoader: (froce: boolean) => void;
  onSetBlockChainError: (error: AppErrorCode) => void;
}

const bridgeStorageKey = "bridgeStorageRequestBusinessBuilders";

export const LimboChains = {
  [AppChain.GANACHE]: posibleChains[AppChain.GANACHE],
  [AppChain.POLYGON]: posibleChains[AppChain.POLYGON],
  [AppChain.METER]: posibleChains[AppChain.METER],
};

export enum PosibleNFTs {
  EMPLOYEES = "employees",
  MINI_EMPLOYEES = "mini-employees",
  MULTI_EMPLOYEES = "multi-employees",
  FACTORIES = "factories",
}

interface NFTBridgeComponentState {
  employees: null | EmployeesController;
  limboEmployees: null | EmployeesController;
  miniEmployees: null | EmployeesController;
  multiEmployees: null | EmployeesController;
  factories: null | FactoriesController;
  factoriesLimbo: null | FactoriesController;

  employeesLoader: boolean;
  miniEmployeesLoader: boolean;
  multiEmployeesLoader: boolean;
  factoriesLoader: boolean;

  page: number;
  miniPage: number;
  factoriesPage: number;
  multiEmployeesPage: number;
  limboPage: number;

  inTransfer: boolean;
  approvedNFTs: boolean;
  approvedMultiEmployees: boolean;
  approvedMiniEmployees: boolean;
  approvedFactoriesEmployees: boolean;
  limbo: {
    [chain: string]: {
      [contract: string]: number[];
    };
  };
  bridgeData: {
    storage: {
      maxNFTs: number;
      open: boolean;
      validatorsFee: number;
      unformatedPrice: string;
    };
    latestData: any;
  };
  selectedNFTs: Array<EmployeeNFT | FactoryNFT>;
  selectedLimboNFTs: Array<EmployeeNFT | FactoryNFT>;
  selectedNFT: PosibleNFTs;
  selectedLimbo: AppChain;
  bridgeToast: string | null;
}

export class NFTBridgePage extends React.PureComponent<NFTBridgeComponentProps, NFTBridgeComponentState> {
  private bridgeInterval: NodeJS.Timeout | null = null;

  constructor(props: NFTBridgeComponentProps) {
    super(props);

    this.state = {
      employees: null,
      limboEmployees: null,
      miniEmployees: null,
      multiEmployees: null,
      factories: null,
      factoriesLimbo: null,

      employeesLoader: false,
      miniEmployeesLoader: false,
      multiEmployeesLoader: false,
      factoriesLoader: false,
      inTransfer: false,
      page: 0,
      miniPage: 0,
      factoriesPage: 0,
      multiEmployeesPage: 0,
      limboPage: 0,
      approvedNFTs: false,
      approvedMultiEmployees: false,
      approvedMiniEmployees: false,
      approvedFactoriesEmployees: false,
      limbo: {},
      bridgeData: {
        storage: {
          maxNFTs: 10,
          open: false,
          validatorsFee: 0,
          unformatedPrice: "",
        },
        latestData: null,
      },
      selectedNFTs: [],
      selectedLimboNFTs: [],
      selectedNFT: PosibleNFTs.EMPLOYEES,
      selectedLimbo: BlockChainHelpers.getAppChain().chainName as AppChain,
      bridgeToast: null,
    };
  }

  async componentDidMount() {
    this.updateSelectedController();
    this.preloadControllers();
    this._prepareBridgeInterval();
    this.props.onToggleLoader(false);
  }

  private _prepareBridgeInterval() {
    this.bridgeInterval = setInterval(async () => {
      const localData = localStorage.getItem(bridgeStorageKey);
      if (localData) {
        const transactionData = await ApiHelpers.getBridgeTransaction(localData);

        if (transactionData && !transactionData?._active && !transactionData?._rejected) {
          if (this.state.bridgeToast) {
            toast.update(this.state.bridgeToast as ReactText, {
              type: toast.TYPE.SUCCESS,
              closeButton: true,
              render: "Your transaction was completed.",
            });
          } else {
            toast.success("Your transaction was completed.");
          }

          if (this.bridgeInterval) clearInterval(this.bridgeInterval);
        } else if (transactionData && transactionData?._rejected) {
          if (this.state.bridgeToast) {
            toast.update(this.state.bridgeToast as ReactText, {
              type: toast.TYPE.ERROR,
              closeButton: true,
              render:
                "There was an error in the transaction (" +
                (transactionData?._rejectReason ? transactionData?._rejectReason?.substring(0, 100) : "") +
                ")",
            });
          } else {
            toast.error(
              "There was an error in the transaction (" +
                (transactionData?._rejectReason ? transactionData?._rejectReason?.substring(0, 100) : "") +
                ")"
            );
          }

          if (this.bridgeInterval) clearInterval(this.bridgeInterval);
        }
      } else if (this.bridgeInterval) clearInterval(this.bridgeInterval);
    }, 10000);
  }

  async preloadControllers(closeLoader: boolean = false) {
    let bridgeData = { ...this.state.bridgeData };

    if (this.props.blockChain.controller?.nftBridgeStorage) {
      const storageData = await this.props.blockChain.controller.nftBridgeStorage.getBridgeData();
      const latestData = await this.props.blockChain.controller.nftBridgeStorage.getLatestData();
      console.log(latestData);
      if (storageData)
        bridgeData = {
          storage: {
            ...storageData,
            validatorsFee: UtilsHelpers.normalizeWei(storageData.feePerNFT),
            unformatedPrice: storageData.feePerNFT,
          },
          latestData: {
            ...latestData,
          },
        };
    }

    this.setState(
      {
        employeesLoader: false,
        miniEmployeesLoader: false,
        factoriesLoader: false,
        bridgeData,
      },
      () => {
        if (closeLoader) this.props.onToggleLoader(false);
      }
    );
  }

  async changeSelectedNFT(nft: PosibleNFTs) {
    this.setState({ selectedNFT: nft, selectedNFTs: [] }, () => {
      this.updateSelectedController();
    });
  }

  updateSelectedController() {
    if (this.state.selectedNFT === PosibleNFTs.EMPLOYEES) {
      this.loadAndSetEmployeesController();
    } else if (this.state.selectedNFT === PosibleNFTs.MULTI_EMPLOYEES) {
      this.loadAndSetMultiEmployeesController();
    } else if (this.state.selectedNFT === PosibleNFTs.FACTORIES) {
      this.loadAndSetFactoriesController();
    } else if (this.state.selectedNFT === PosibleNFTs.MINI_EMPLOYEES) {
      this.loadAndSetMiniEmployeesController();
    }
  }

  async preloadMiniEmployeeController() {
    let miniEmployees = null;
    let limboEmployees = null;
    let limboData = null;
    let approved = false;

    UtilsHelpers.debugger("Preload employees controller.");

    if (
      this.props.blockChain.controller?.employees &&
      this.props.blockChain.controller?.miniEmployees &&
      this.props.blockChain.controller?.multiEmployees &&
      this.props.blockChain.controller?.employeesExpanded &&
      this.props.blockChain.controller?.miniEmployeesDeployer &&
      this.props.blockChain.controller?.employeesTeam &&
      this.props.blockChain.controller?.nftBridgeStorage &&
      this.props.appState.appData
    ) {
      miniEmployees = new EmployeesController(
        this.props.blockChain.controller.employees,
        this.props.blockChain.controller.employeesExpanded,
        this.props.blockChain.controller.miniEmployees,
        this.props.blockChain.controller.miniEmployeesDeployer,
        this.props.blockChain.controller.multiEmployees,
        this.props.blockChain.controller.employeesTeam,
        this.props.blockChain.controller.nftBridgeStorage,
        this.props.appState.appData
      );

      limboEmployees = new EmployeesController(
        this.props.blockChain.controller.employees,
        this.props.blockChain.controller.employeesExpanded,
        this.props.blockChain.controller.miniEmployees,
        this.props.blockChain.controller.miniEmployeesDeployer,
        this.props.blockChain.controller.multiEmployees,
        this.props.blockChain.controller.employeesTeam,
        this.props.blockChain.controller.nftBridgeStorage,
        this.props.appState.appData
      );

      await miniEmployees.loadMiniEmployeesData();

      try {
        const selectedContractChain = posibleContractAddress[relatedModeToChain[this.state.selectedLimbo]];

        limboData = await ApiHelpers.limboNFTs(
          parseInt(posibleChains[this.state.selectedLimbo].chainId, 16),
          this.props.blockChain.controller.employees.selectedAccount,
          selectedContractChain
            ? selectedContractChain[Contract.MINI_EMPLOYEES] || this.props.blockChain.controller.miniEmployees.address
            : this.props.blockChain.controller.miniEmployees.address
        );

        await limboEmployees.loadSpecificEmployeesData(Array.isArray(limboData?.nfts) ? limboData.nfts : [], true, false);
      } catch (error) {
        console.log(error);
      }
    }

    if (this.props.blockChain.controller?.miniEmployees && this.props.appState.appData?.contractsAddress[Contract.NFT_BRIDGE_STORAGE]) {
      approved = await this.props.blockChain.controller.miniEmployees.isApprovedForAll(
        this.props.appState.appData?.contractsAddress[Contract.NFT_BRIDGE_STORAGE]
      );
    }

    UtilsHelpers.debugger("Finish Preload employees controller.");

    return { miniEmployees, limboEmployees, approvedEmployees: approved };
  }

  async preloadEmployeeController() {
    let employees = null;
    let limboEmployees = null;
    let limboData = null;
    let approved = false;

    UtilsHelpers.debugger("Preload employees controller.");

    if (
      this.props.blockChain.controller?.employees &&
      this.props.blockChain.controller?.miniEmployees &&
      this.props.blockChain.controller?.multiEmployees &&
      this.props.blockChain.controller?.employeesExpanded &&
      this.props.blockChain.controller?.miniEmployeesDeployer &&
      this.props.blockChain.controller?.employeesTeam &&
      this.props.blockChain.controller?.nftBridgeStorage &&
      this.props.appState.appData
    ) {
      employees = new EmployeesController(
        this.props.blockChain.controller.employees,
        this.props.blockChain.controller.employeesExpanded,
        this.props.blockChain.controller.miniEmployees,
        this.props.blockChain.controller.miniEmployeesDeployer,
        this.props.blockChain.controller.multiEmployees,
        this.props.blockChain.controller.employeesTeam,
        this.props.blockChain.controller.nftBridgeStorage,
        this.props.appState.appData
      );

      limboEmployees = new EmployeesController(
        this.props.blockChain.controller.employees,
        this.props.blockChain.controller.employeesExpanded,
        this.props.blockChain.controller.miniEmployees,
        this.props.blockChain.controller.miniEmployeesDeployer,
        this.props.blockChain.controller.multiEmployees,
        this.props.blockChain.controller.employeesTeam,
        this.props.blockChain.controller.nftBridgeStorage,
        this.props.appState.appData
      );

      await employees.loadFullEmployeesData(true);

      try {
        const selectedContractChain = posibleContractAddress[relatedModeToChain[this.state.selectedLimbo]];

        limboData = await ApiHelpers.limboNFTs(
          parseInt(posibleChains[this.state.selectedLimbo].chainId, 16),
          this.props.blockChain.controller.employees.selectedAccount,
          selectedContractChain
            ? selectedContractChain[Contract.EMPLOYEES] || this.props.blockChain.controller.employees.address
            : this.props.blockChain.controller.employees.address
        );

        await limboEmployees.loadSpecificEmployeesData(Array.isArray(limboData?.nfts) ? limboData.nfts : [], false, false);
      } catch (error) {
        console.log(error);
      }
    }

    if (this.props.blockChain.controller?.employees && this.props.appState.appData?.contractsAddress[Contract.NFT_BRIDGE_STORAGE]) {
      approved = await this.props.blockChain.controller.employees.isApprovedForAll(
        this.props.appState.appData?.contractsAddress[Contract.NFT_BRIDGE_STORAGE]
      );
    }

    UtilsHelpers.debugger("Finish Preload employees controller.");

    return { employees, limboEmployees, approvedEmployees: approved };
  }

  async preloadMultiEmployeesController() {
    let employees = null;
    let limboEmployees = null;
    let limboData = null;
    let approved = false;

    UtilsHelpers.debugger("Preload employees controller.");

    if (
      this.props.blockChain.controller?.employees &&
      this.props.blockChain.controller?.miniEmployees &&
      this.props.blockChain.controller?.multiEmployees &&
      this.props.blockChain.controller?.employeesExpanded &&
      this.props.blockChain.controller?.employeesTeam &&
      this.props.blockChain.controller?.miniEmployeesDeployer &&
      this.props.blockChain.controller?.nftBridgeStorage &&
      this.props.appState.appData
    ) {
      employees = new EmployeesController(
        this.props.blockChain.controller.employees,
        this.props.blockChain.controller.employeesExpanded,
        this.props.blockChain.controller.miniEmployees,
        this.props.blockChain.controller.miniEmployeesDeployer,
        this.props.blockChain.controller.multiEmployees,
        this.props.blockChain.controller.employeesTeam,
        this.props.blockChain.controller.nftBridgeStorage,
        this.props.appState.appData
      );

      limboEmployees = new EmployeesController(
        this.props.blockChain.controller.employees,
        this.props.blockChain.controller.employeesExpanded,
        this.props.blockChain.controller.miniEmployees,
        this.props.blockChain.controller.miniEmployeesDeployer,
        this.props.blockChain.controller.multiEmployees,
        this.props.blockChain.controller.employeesTeam,
        this.props.blockChain.controller.nftBridgeStorage,
        this.props.appState.appData
      );

      await employees.loadMultiEmployeesData();

      try {
        const selectedContractChain = posibleContractAddress[relatedModeToChain[this.state.selectedLimbo]];

        limboData = await ApiHelpers.limboNFTs(
          parseInt(posibleChains[this.state.selectedLimbo].chainId, 16),
          this.props.blockChain.controller.multiEmployees.selectedAccount,
          selectedContractChain
            ? selectedContractChain[Contract.MULTI_EMPLOYEE] || this.props.blockChain.controller.multiEmployees.address
            : this.props.blockChain.controller.multiEmployees.address
        );

        await limboEmployees.loadSpecificEmployeesData(Array.isArray(limboData?.nfts) ? limboData.nfts : [], false, true);
      } catch (error) {
        console.log(error);
      }
    }

    UtilsHelpers.debugger("Finish Preload employees controller.");

    return { employees, limboEmployees, approvedEmployees: approved };
  }

  async preloadFactoriesController() {
    let controller = null;
    let limboController = null;
    let approved = false;
    let limboData = null;

    UtilsHelpers.debugger("Preload employees controller.");

    if (this.props.blockChain.controller?.factories && this.props.blockChain.controller?.cityRelationsStorage && this.props.appState.appData) {
      controller = new FactoriesController(
        this.props.blockChain.controller.factories,
        this.props.blockChain.controller.cityRelationsStorage,
        this.props.appState.appData
      );

      limboController = new FactoriesController(
        this.props.blockChain.controller.factories,
        this.props.blockChain.controller.cityRelationsStorage,
        this.props.appState.appData
      );

      await controller.loadFactoriesData();

      try {
        const selectedContractChain = posibleContractAddress[relatedModeToChain[this.state.selectedLimbo]];

        limboData = await ApiHelpers.limboNFTs(
          parseInt(posibleChains[this.state.selectedLimbo].chainId, 16),
          this.props.blockChain.controller.factories.selectedAccount,
          selectedContractChain
            ? selectedContractChain[Contract.FACTORIES] || this.props.blockChain.controller.factories.address
            : this.props.blockChain.controller.factories.address
        );

        await limboController.loadSpecificFactoriesata(Array.isArray(limboData?.nfts) ? limboData.nfts : []);
      } catch (error) {
        console.log(error);
      }
    }

    if (this.props.blockChain.controller?.factories && this.props.appState.appData?.contractsAddress[Contract.NFT_BRIDGE_STORAGE]) {
      approved = await this.props.blockChain.controller.factories.isApprovedForAll(
        this.props.appState.appData?.contractsAddress[Contract.NFT_BRIDGE_STORAGE]
      );
    }

    UtilsHelpers.debugger("Finish Preload employees controller.");

    return { controller, approved, limboController };
  }

  async onUpdateData(error: AppErrorCode | null) {
    if (error) this.props.onSetBlockChainError(error);
    this.preloadControllers();
    this.updateSelectedController();
    this.props.onLoadCustomerData(true);
  }

  async loadAndSetEmployeesController() {
    this.setState({ employeesLoader: true }, async () => {
      const { employees, limboEmployees, approvedEmployees } = await this.preloadEmployeeController();

      this.setState({
        employees,
        limboEmployees,
        approvedNFTs: approvedEmployees,
        employeesLoader: false,
      });
    });
  }

  async loadAndSetMiniEmployeesController() {
    this.setState({ miniEmployeesLoader: true }, async () => {
      const { miniEmployees, limboEmployees, approvedEmployees } = await this.preloadMiniEmployeeController();

      this.setState({
        miniEmployees,
        limboEmployees,
        approvedNFTs: approvedEmployees,
        miniEmployeesLoader: false,
      });
    });
  }

  async loadAndSetFactoriesController() {
    this.setState({ factoriesLoader: true }, async () => {
      const { controller, approved, limboController } = await this.preloadFactoriesController();

      this.setState({
        factories: controller,
        approvedNFTs: approved,
        factoriesLimbo: limboController,
        factoriesLoader: false,
      });
    });
  }

  async loadAndSetMultiEmployeesController() {
    this.setState({ multiEmployeesLoader: true }, async () => {
      const { employees, limboEmployees, approvedEmployees } = await this.preloadMultiEmployeesController();

      this.setState({
        multiEmployees: employees,
        limboEmployees,
        approvedNFTs: approvedEmployees,
        multiEmployeesLoader: false,
      });
    });
  }

  render() {
    console.log(this.state.bridgeData);
    return (
      <React.Fragment>
        <div className="ct-max-container ct-builder-page">
          {this.state.inTransfer ? (
            <div className="ct-transfer-loader">
              <div className="spinner"></div>
            </div>
          ) : (
            ""
          )}
          <div className="ct-page-actions-container">
            <div className="ct-container">
              <div className="ct-data">
                <h1>Ecosystem bridge</h1>
                <small>
                  Transfer your NFTs between networks, Burn to Mint system. We transfer your NFTs to bridge contract, when we validate your NFTs in
                  the other network we will burn your NFTs. If the process in the other network fail your NFTs will be sent to your account again.
                </small>
              </div>
              <div className="ct-actions ct-bridge-actions">
                <p>
                  <strong>Storage Status</strong>: {this.state.bridgeData.storage.open ? "Open" : "Closed"}
                </p>
                <p>
                  <strong>Fee per NFT</strong>: {Number(Web3.utils.fromWei(this.state.bridgeData?.latestData?.feePerNFT || "0")).toFixed(3)} MATIC
                </p>
                <p>
                  <strong>From</strong>: {BlockChainHelpers.getAppChain().chainName} NFTs
                </p>
                <p>
                  <strong>To</strong>: {BlockChainHelpers.getAppChain().chainName} Limbo
                </p>
              </div>
            </div>
          </div>
          <div className="ct-nft-selector">
            <div className="ct-bridge-nft ct-employee" onClick={() => this.changeSelectedNFT(PosibleNFTs.EMPLOYEES)}>
              <img src={EmployeeImage} alt="Employee NFT" />
              <h2>Employees Bridge</h2>
            </div>
            <div className="ct-bridge-nft ct-mini-employee" onClick={() => this.changeSelectedNFT(PosibleNFTs.MINI_EMPLOYEES)}>
              <img src={MiniEmployeeImage} alt="Mini Employee NFT" />
              <h2>Mini Employees Bridge</h2>
            </div>
            <div className="ct-bridge-nft ct-factories" onClick={() => this.changeSelectedNFT(PosibleNFTs.FACTORIES)}>
              <img src={FactoryImage} alt="Factory NFT" />
              <h2>Factories Bridge</h2>
            </div>
            <div className="ct-bridge-nft ct-factories" onClick={() => this.changeSelectedNFT(PosibleNFTs.MULTI_EMPLOYEES)}>
              <img src={MultiImage} alt="Multi Employees NFT" />
              <h2>MultiEmployees Bridge</h2>
            </div>
          </div>

          {this.state.selectedNFT === PosibleNFTs.EMPLOYEES ? (
            <div className="ct-employees">
              {this.state.employees && this.props.appState.appData ? (
                <BuilderEmployeesGrid
                  nftsPerPage={8}
                  employeesLoader={this.state.employeesLoader}
                  employees={this.state.employees}
                  appData={this.props.appState.appData}
                  page={this.state.page}
                  title="Employees (NFT)"
                  gameSelection={this.state.selectedNFTs as EmployeeNFT[]}
                  onChangeEmployeesPage={(page: number) => this.setState({ page })}
                  subtitle={"Selected employees " + this.state.selectedNFTs.length + "/ " + this.state.bridgeData.storage.maxNFTs}
                  onClick={(employee: EmployeeNFT) => {
                    let searchSelected = this.state.selectedNFTs.findIndex((nft) => nft.id === employee.id);

                    if (searchSelected === -1) {
                      if (this.state.selectedNFTs.length < this.state.bridgeData.storage.maxNFTs) {
                        let newState = [...this.state.selectedNFTs];
                        newState.push(employee);

                        this.setState({
                          selectedNFTs: newState as EmployeeNFT[],
                        });
                      }
                    } else {
                      let newState = [...this.state.selectedNFTs];
                      newState.splice(searchSelected, 1);

                      this.setState({
                        selectedNFTs: newState as EmployeeNFT[],
                      });
                    }
                  }}
                  action={
                    this.state.bridgeData.storage.open && this.state.selectedNFTs.length > 0
                      ? {
                          onClick: async () => {
                            if (this.state.approvedNFTs) {
                              if (this.props.blockChain.controller?.nftBridgeStorage && this.props.blockChain.controller.employees?.address) {
                                const latestPrice = await this.props.blockChain.controller.nftBridgeStorage.getRoundPrice(
                                  this.state.bridgeData?.latestData?.roundId,
                                  this.state.selectedNFTs.length
                                );

                                this.props.blockChain.controller?.nftBridgeStorage.createLimboRequestWithMany(
                                  this.props.blockChain.controller.employees?.address,
                                  this.state.bridgeData?.latestData?.roundId,
                                  this.state.selectedNFTs.map((nft) => nft.id),
                                  latestPrice,
                                  (error: AppErrorCode | null) => {
                                    this.setState({ selectedNFTs: [] }, () => this.onUpdateData(error));
                                  }
                                );
                              }
                            } else {
                              if (
                                this.props.blockChain.controller?.employees &&
                                this.props.appState.appData?.contractsAddress[Contract.NFT_BRIDGE_STORAGE]
                              ) {
                                this.props.blockChain.controller?.employees?.setApprovalForAll(
                                  this.props.appState.appData?.contractsAddress[Contract.NFT_BRIDGE_STORAGE],
                                  (error: AppErrorCode | null) => {
                                    this.onUpdateData(error);
                                  }
                                );
                              }
                            }
                          },
                          icon: this.state.approvedNFTs ? "fas fa-exchange-alt" : "fas fa-exclamation-triangle",
                        }
                      : undefined
                  }
                  onUpdate={() => this.updateSelectedController()}
                />
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}

          {this.state.selectedNFT === PosibleNFTs.MULTI_EMPLOYEES ? (
            <div className="ct-employees">
              {this.state.multiEmployees && this.props.appState.appData ? (
                <BuilderEmployeesGrid
                  nftsPerPage={8}
                  employeesLoader={this.state.employeesLoader}
                  employees={this.state.multiEmployees}
                  appData={this.props.appState.appData}
                  page={this.state.page}
                  title="Multi Employees (NFT)"
                  gameSelection={this.state.selectedNFTs as EmployeeNFT[]}
                  onChangeEmployeesPage={(page: number) => this.setState({ page })}
                  subtitle={"Selected employees " + this.state.selectedNFTs.length + "/ " + this.state.bridgeData.storage.maxNFTs}
                  onClick={(employee: EmployeeNFT) => {
                    let searchSelected = this.state.selectedNFTs.findIndex((nft) => nft.id === employee.id);

                    if (searchSelected === -1) {
                      if (this.state.selectedNFTs.length < this.state.bridgeData.storage.maxNFTs) {
                        let newState = [...this.state.selectedNFTs];
                        newState.push(employee);

                        this.setState({
                          selectedNFTs: newState as EmployeeNFT[],
                        });
                      }
                    } else {
                      let newState = [...this.state.selectedNFTs];
                      newState.splice(searchSelected, 1);

                      this.setState({
                        selectedNFTs: newState as EmployeeNFT[],
                      });
                    }
                  }}
                  action={
                    this.state.bridgeData.storage.open && this.state.selectedNFTs.length > 0
                      ? {
                          onClick: async () => {
                            if (this.state.approvedNFTs) {
                              if (this.props.blockChain.controller?.nftBridgeStorage && this.props.blockChain.controller.multiEmployees?.address) {
                                const price = await this.props.blockChain.controller.nftBridgeStorage.getRoundPrice(
                                  this.state.bridgeData?.latestData?.roundId,
                                  this.state.selectedNFTs.length
                                );

                                this.props.blockChain.controller?.nftBridgeStorage.createLimboRequestWithMany(
                                  this.props.blockChain.controller.multiEmployees?.address,
                                  this.state.bridgeData?.latestData?.roundId,
                                  this.state.selectedNFTs.map((nft) => nft.id),
                                  price,
                                  (error: AppErrorCode | null) => {
                                    this.setState({ selectedNFTs: [] }, () => this.onUpdateData(error));
                                  }
                                );
                              }
                            } else {
                              if (
                                this.props.blockChain.controller?.multiEmployees &&
                                this.props.appState.appData?.contractsAddress[Contract.NFT_BRIDGE_STORAGE]
                              ) {
                                this.props.blockChain.controller?.multiEmployees?.setApprovalForAll(
                                  this.props.appState.appData?.contractsAddress[Contract.NFT_BRIDGE_STORAGE],
                                  (error: AppErrorCode | null) => {
                                    this.onUpdateData(error);
                                  }
                                );
                              }
                            }
                          },
                          icon: this.state.approvedNFTs ? "fas fa-exchange-alt" : "fas fa-exclamation-triangle",
                        }
                      : undefined
                  }
                  onUpdate={() => this.updateSelectedController()}
                />
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}

          {this.state.selectedNFT === PosibleNFTs.MINI_EMPLOYEES ? (
            <div className="ct-employees">
              {this.state.miniEmployees && this.props.appState.appData && this.props.blockChain.controller?.miniEmployees ? (
                <BuilderEmployeesGrid
                  nftsPerPage={8}
                  employeesLoader={this.state.miniEmployeesLoader}
                  employees={this.state.miniEmployees}
                  appData={this.props.appState.appData}
                  page={this.state.miniPage}
                  title="Mini Employees (NFTs)"
                  gameSelection={this.state.selectedNFTs as EmployeeNFT[]}
                  onChangeEmployeesPage={(page: number) => this.setState({ miniPage: page })}
                  onlyMiniEmployees
                  subtitle={"Selected employees " + this.state.selectedNFTs.length + "/ " + this.state.bridgeData.storage.maxNFTs}
                  onClick={(employee: EmployeeNFT) => {
                    let searchSelected = this.state.selectedNFTs.findIndex((nft) => nft.id === employee.id);

                    if (searchSelected === -1) {
                      if (this.state.selectedNFTs.length < this.state.bridgeData.storage.maxNFTs) {
                        let newState = [...this.state.selectedNFTs];
                        newState.push(employee);

                        this.setState({
                          selectedNFTs: newState as EmployeeNFT[],
                        });
                      }
                    } else {
                      let newState = [...this.state.selectedNFTs];
                      newState.splice(searchSelected, 1);

                      this.setState({
                        selectedNFTs: newState as EmployeeNFT[],
                      });
                    }
                  }}
                  miniEmployeesContract={this.props.blockChain.controller?.miniEmployees}
                  action={
                    this.state.bridgeData.storage.open && this.state.selectedNFTs.length > 0
                      ? {
                          onClick: async () => {
                            if (this.state.approvedNFTs) {
                              if (this.props.blockChain.controller?.nftBridgeStorage && this.props.blockChain.controller.miniEmployees?.address) {
                                const price = await this.props.blockChain.controller.nftBridgeStorage.getRoundPrice(
                                  this.state.bridgeData?.latestData?.roundId,
                                  this.state.selectedNFTs.length
                                );

                                this.props.blockChain.controller?.nftBridgeStorage.createLimboRequestWithMany(
                                  this.props.blockChain.controller.miniEmployees?.address,
                                  this.state.bridgeData?.latestData?.roundId,
                                  this.state.selectedNFTs.map((nft) => nft.id),
                                  price,
                                  (error: AppErrorCode | null) => {
                                    this.setState({ selectedNFTs: [] }, () => this.onUpdateData(error));
                                  }
                                );
                              }
                            } else {
                              if (
                                this.props.blockChain.controller?.miniEmployees &&
                                this.props.appState.appData?.contractsAddress[Contract.NFT_BRIDGE_STORAGE]
                              ) {
                                this.props.blockChain.controller?.miniEmployees?.setApprovalForAll(
                                  this.props.appState.appData?.contractsAddress[Contract.NFT_BRIDGE_STORAGE],
                                  (error: AppErrorCode | null) => {
                                    this.onUpdateData(error);
                                  }
                                );
                              }
                            }
                          },
                          icon: this.state.approvedNFTs ? "fas fa-exchange-alt" : "fas fa-exclamation-triangle",
                        }
                      : undefined
                  }
                  onUpdate={() => this.updateSelectedController()}
                />
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}

          {this.state.selectedNFT === PosibleNFTs.FACTORIES ? (
            <div className="ct-factories">
              {this.props.appState.appData && this.state.factories ? (
                <BuilderFactoriesGrid
                  nftsPerPage={8}
                  factoriesLoader={this.state.factoriesLoader}
                  factories={this.state.factories}
                  appData={this.props.appState.appData}
                  page={this.state.factoriesPage}
                  subtitle={"Selected factories " + this.state.selectedNFTs.length + "/ " + this.state.bridgeData.storage.maxNFTs}
                  onClick={(factory: FactoryNFT) => {
                    let searchSelected = this.state.selectedNFTs.findIndex((nft) => nft.id === factory.id);

                    if (searchSelected === -1) {
                      if (this.state.selectedNFTs.length < this.state.bridgeData.storage.maxNFTs) {
                        let newState = [...this.state.selectedNFTs];

                        newState.push(factory);

                        this.setState({
                          selectedNFTs: newState as FactoryNFT[],
                        });
                      }
                    } else {
                      let newState = [...this.state.selectedNFTs];
                      newState.splice(searchSelected, 1);

                      this.setState({
                        selectedNFTs: newState as FactoryNFT[],
                      });
                    }
                  }}
                  action={
                    this.state.bridgeData.storage.open && this.state.selectedNFTs.length > 0
                      ? {
                          onClick: async () => {
                            if (this.state.approvedNFTs) {
                              if (this.props.blockChain.controller?.nftBridgeStorage && this.props.blockChain.controller.factories?.address) {
                                const price = await this.props.blockChain.controller.nftBridgeStorage.getRoundPrice(
                                  this.state.bridgeData?.latestData?.roundId,
                                  this.state.selectedNFTs.length
                                );

                                this.props.blockChain.controller?.nftBridgeStorage.createLimboRequestWithMany(
                                  this.props.blockChain.controller.factories?.address,
                                  this.state.bridgeData?.latestData?.roundId,
                                  this.state.selectedNFTs.map((nft) => nft.id),
                                  price,
                                  (error: AppErrorCode | null) => {
                                    this.setState({ selectedNFTs: [] }, () => this.onUpdateData(error));
                                  }
                                );
                              }
                            } else {
                              if (
                                this.props.blockChain.controller?.factories &&
                                this.props.appState.appData?.contractsAddress[Contract.NFT_BRIDGE_STORAGE]
                              ) {
                                this.props.blockChain.controller?.factories?.setApprovalForAll(
                                  this.props.appState.appData?.contractsAddress[Contract.NFT_BRIDGE_STORAGE],
                                  (error: AppErrorCode | null) => {
                                    this.onUpdateData(error);
                                  }
                                );
                              }
                            }
                          },
                          icon: this.state.approvedNFTs ? "fas fa-exchange-alt" : "fas fa-exclamation-triangle",
                        }
                      : undefined
                  }
                  onUpdate={() => this.updateSelectedController()}
                  onChangeFactoriesPage={(page: number) => {
                    this.setState({ factoriesPage: page });
                  }}
                  gameSelection={this.state.selectedNFTs as FactoryNFT[]}
                />
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}

          {this.state.selectedNFT === PosibleNFTs.EMPLOYEES ? (
            <div className="ct-employees">
              {this.state.limboEmployees && this.props.appState.appData ? (
                <BuilderEmployeesGrid
                  nftsPerPage={8}
                  employeesLoader={this.state.employeesLoader}
                  employees={this.state.limboEmployees}
                  appData={this.props.appState.appData}
                  page={this.state.limboPage}
                  title={posibleChains[this.state.selectedLimbo]?.chainName + " Limbo (NFT)"}
                  subtitle={"You can see your Limbo NFTs in other networks, " + this.state.selectedLimboNFTs.length}
                  gameSelection={this.state.selectedLimboNFTs as EmployeeNFT[]}
                  onChangeEmployeesPage={(page: number) => this.setState({ limboPage: page })}
                  onClick={(employee: EmployeeNFT) => {
                    let searchSelected = this.state.selectedLimboNFTs.findIndex((nft) => nft.id === employee.id);

                    if (searchSelected === -1) {
                      let newState = [...this.state.selectedLimboNFTs];
                      newState.push(employee);

                      this.setState({
                        selectedLimboNFTs: newState as EmployeeNFT[],
                      });
                    } else {
                      let newState = [...this.state.selectedLimboNFTs];
                      newState.splice(searchSelected, 1);

                      this.setState({
                        selectedLimboNFTs: newState as EmployeeNFT[],
                      });
                    }
                  }}
                  action={
                    this.state.selectedLimboNFTs.length > 0
                      ? {
                          onClick: async () => {
                            if (this.props.blockChain.controller?.employees && this.props.blockChain.controller.nftBridgeStorage) {
                              const selectedContractChain = posibleContractAddress[relatedModeToChain[this.state.selectedLimbo]];

                              const msg = {
                                domain: {
                                  chainId: parseInt(BlockChainHelpers.getAppChain().chainId, 16),
                                  name: "BusinessBuilders.city",
                                  verifyingContract: this.props.blockChain.controller.nftBridgeStorage?.address,
                                  version: "1",
                                },
                                message: {
                                  fromContract: selectedContractChain
                                    ? selectedContractChain[Contract.EMPLOYEES] || this.props.blockChain.controller.employees.address
                                    : "",
                                  fromNetwork: parseInt(posibleChains[this.state.selectedLimbo].chainId, 16).toString(),
                                  toNetwork: parseInt(BlockChainHelpers.getAppChain().chainId, 16).toString(),
                                  nfts: this.state.selectedLimboNFTs.map((nft) => nft.id),
                                  owner: this.props.blockChain.controller.selectedAccount,
                                },
                                primaryType: "Request",
                                types: {
                                  EIP712Domain: [
                                    { name: "name", type: "string" },
                                    { name: "version", type: "string" },
                                    { name: "chainId", type: "uint256" },
                                    {
                                      name: "verifyingContract",
                                      type: "address",
                                    },
                                  ],
                                  Request: [
                                    {
                                      name: "fromContract",
                                      type: "address",
                                    },
                                    { name: "fromNetwork", type: "string" },
                                    { name: "toNetwork", type: "string" },
                                    { name: "owner", type: "string" },
                                    { name: "nfts", type: "uint256[]" },
                                  ],
                                },
                              };

                              const eth = BlockChainHelpers.getEthWindow();

                              if (eth) {
                                const sign = await eth.request({
                                  method: "eth_signTypedData_v4",
                                  params: [this.props.blockChain.controller.selectedAccount, JSON.stringify(msg)],
                                });

                                this.setState({ selectedLimboNFTs: [] }, async () => {
                                  if (sign) {
                                    const mintingRequest = await ApiHelpers.requestMinting(
                                      msg.message.fromNetwork,
                                      msg.message.toNetwork,
                                      msg.message.fromContract,
                                      msg.message.nfts,
                                      sign
                                    );

                                    if (mintingRequest) {
                                      if (mintingRequest.rejected === false) {
                                        const bridgeToast = toast.loading(
                                          "[Request] Your request has been sent, you will be notified when it is completed. Please, save the transaction serial (" +
                                            mintingRequest.serial +
                                            ")"
                                        );

                                        this.setState(
                                          {
                                            bridgeToast: bridgeToast as string,
                                          },
                                          () => {
                                            localStorage.setItem(bridgeStorageKey, mintingRequest.serial);

                                            this._prepareBridgeInterval();
                                          }
                                        );
                                      } else {
                                        toast.error("[Request] Server rejection. " + mintingRequest.rejected);
                                      }
                                    } else {
                                      toast.error("[Server] The server did not respond correctly ");
                                    }
                                  } else {
                                    toast.error("[Signature] Sign error. Please, send the error the the telegram group.");
                                  }
                                });
                              }
                            }
                          },
                          icon: this.state.approvedNFTs ? "fas fa-exchange-alt" : "fas fa-exclamation-triangle",
                        }
                      : undefined
                  }
                  actions={[
                    {
                      onClick: () => {
                        this.setState(
                          {
                            selectedLimbo: AppChain.POLYGON,
                            selectedLimboNFTs: [],
                          },
                          () => this.updateSelectedController()
                        );
                      },
                      image: PolygonLogo,
                    },
                    {
                      onClick: () => {
                        this.setState(
                          {
                            selectedLimbo: AppChain.METER,
                            selectedLimboNFTs: [],
                          },
                          () => this.updateSelectedController()
                        );
                      },
                      image: MeterLogo,
                    },
                  ]}
                  onUpdate={() => this.updateSelectedController()}
                />
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}

          {this.state.selectedNFT === PosibleNFTs.MULTI_EMPLOYEES ? (
            <div className="ct-employees">
              {this.state.limboEmployees && this.props.appState.appData ? (
                <BuilderEmployeesGrid
                  nftsPerPage={8}
                  employeesLoader={this.state.employeesLoader}
                  employees={this.state.limboEmployees}
                  appData={this.props.appState.appData}
                  page={this.state.limboPage}
                  title={posibleChains[this.state.selectedLimbo]?.chainName + " Limbo (NFT)"}
                  subtitle={"You can see your Limbo NFTs in other networks, " + this.state.selectedLimboNFTs.length}
                  gameSelection={this.state.selectedLimboNFTs as EmployeeNFT[]}
                  onChangeEmployeesPage={(page: number) => this.setState({ limboPage: page })}
                  onClick={(employee: EmployeeNFT) => {
                    let searchSelected = this.state.selectedLimboNFTs.findIndex((nft) => nft.id === employee.id);

                    if (searchSelected === -1) {
                      let newState = [...this.state.selectedLimboNFTs];
                      newState.push(employee);

                      this.setState({
                        selectedLimboNFTs: newState as EmployeeNFT[],
                      });
                    } else {
                      let newState = [...this.state.selectedLimboNFTs];
                      newState.splice(searchSelected, 1);

                      this.setState({
                        selectedLimboNFTs: newState as EmployeeNFT[],
                      });
                    }
                  }}
                  action={
                    this.state.selectedLimboNFTs.length > 0
                      ? {
                          onClick: async () => {
                            if (this.props.blockChain.controller?.multiEmployees && this.props.blockChain.controller.nftBridgeStorage) {
                              const selectedContractChain = posibleContractAddress[relatedModeToChain[this.state.selectedLimbo]];

                              const msg = {
                                domain: {
                                  chainId: parseInt(BlockChainHelpers.getAppChain().chainId, 16),
                                  name: "BusinessBuilders.city",
                                  verifyingContract: this.props.blockChain.controller.nftBridgeStorage?.address,
                                  version: "1",
                                },
                                message: {
                                  fromContract: selectedContractChain
                                    ? selectedContractChain[Contract.MULTI_EMPLOYEE] || this.props.blockChain.controller.multiEmployees.address
                                    : "",
                                  fromNetwork: parseInt(posibleChains[this.state.selectedLimbo].chainId, 16).toString(),
                                  toNetwork: parseInt(BlockChainHelpers.getAppChain().chainId, 16).toString(),
                                  nfts: this.state.selectedLimboNFTs.map((nft) => nft.id),
                                  owner: this.props.blockChain.controller.selectedAccount,
                                },
                                primaryType: "Request",
                                types: {
                                  EIP712Domain: [
                                    { name: "name", type: "string" },
                                    { name: "version", type: "string" },
                                    { name: "chainId", type: "uint256" },
                                    {
                                      name: "verifyingContract",
                                      type: "address",
                                    },
                                  ],
                                  Request: [
                                    {
                                      name: "fromContract",
                                      type: "address",
                                    },
                                    { name: "fromNetwork", type: "string" },
                                    { name: "toNetwork", type: "string" },
                                    { name: "owner", type: "string" },
                                    { name: "nfts", type: "uint256[]" },
                                  ],
                                },
                              };

                              const eth = BlockChainHelpers.getEthWindow();

                              if (eth) {
                                const sign = await eth.request({
                                  method: "eth_signTypedData_v4",
                                  params: [this.props.blockChain.controller.selectedAccount, JSON.stringify(msg)],
                                });

                                this.setState({ selectedLimboNFTs: [] }, async () => {
                                  if (sign) {
                                    const mintingRequest = await ApiHelpers.requestMinting(
                                      msg.message.fromNetwork,
                                      msg.message.toNetwork,
                                      msg.message.fromContract,
                                      msg.message.nfts,
                                      sign
                                    );

                                    if (mintingRequest) {
                                      if (mintingRequest.rejected === false) {
                                        const bridgeToast = toast.loading(
                                          "[Request] Your request has been sent, you will be notified when it is completed. Please, save the transaction serial (" +
                                            mintingRequest.serial +
                                            ")"
                                        );

                                        this.setState(
                                          {
                                            bridgeToast: bridgeToast as string,
                                          },
                                          () => {
                                            localStorage.setItem(bridgeStorageKey, mintingRequest.serial);

                                            this._prepareBridgeInterval();
                                          }
                                        );
                                      } else {
                                        toast.error("[Request] Server rejection. " + mintingRequest.rejected);
                                      }
                                    } else {
                                      toast.error("[Server] The server did not respond correctly ");
                                    }
                                  } else {
                                    toast.error("[Signature] Sign error. Please, send the error the the telegram group.");
                                  }
                                });
                              }
                            }
                          },
                          icon: this.state.approvedNFTs ? "fas fa-exchange-alt" : "fas fa-exclamation-triangle",
                        }
                      : undefined
                  }
                  actions={[
                    {
                      onClick: () => {
                        this.setState(
                          {
                            selectedLimbo: AppChain.POLYGON,
                            selectedLimboNFTs: [],
                          },
                          () => this.updateSelectedController()
                        );
                      },
                      image: PolygonLogo,
                    },
                    {
                      onClick: () => {
                        this.setState(
                          {
                            selectedLimbo: AppChain.METER,
                            selectedLimboNFTs: [],
                          },
                          () => this.updateSelectedController()
                        );
                      },
                      image: MeterLogo,
                    },
                  ]}
                  onUpdate={() => this.updateSelectedController()}
                />
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}

          {this.state.selectedNFT === PosibleNFTs.MINI_EMPLOYEES ? (
            <div className="ct-employees">
              {this.state.limboEmployees && this.props.appState.appData && this.props.blockChain.controller?.miniEmployees ? (
                <BuilderEmployeesGrid
                  nftsPerPage={8}
                  employeesLoader={this.state.employeesLoader}
                  employees={this.state.limboEmployees}
                  appData={this.props.appState.appData}
                  page={this.state.limboPage}
                  title={posibleChains[this.state.selectedLimbo]?.chainName + " Limbo (NFT)"}
                  onlyMiniEmployees
                  subtitle={"You can see your Limbo NFTs in other networks, " + this.state.selectedLimboNFTs.length}
                  gameSelection={this.state.selectedLimboNFTs as EmployeeNFT[]}
                  onChangeEmployeesPage={(page: number) => this.setState({ limboPage: page })}
                  onClick={(employee: EmployeeNFT) => {
                    let searchSelected = this.state.selectedLimboNFTs.findIndex((nft) => nft.id === employee.id);

                    if (searchSelected === -1) {
                      let newState = [...this.state.selectedLimboNFTs];
                      newState.push(employee);

                      this.setState({
                        selectedLimboNFTs: newState as EmployeeNFT[],
                      });
                    } else {
                      let newState = [...this.state.selectedLimboNFTs];
                      newState.splice(searchSelected, 1);

                      this.setState({
                        selectedLimboNFTs: newState as EmployeeNFT[],
                      });
                    }
                  }}
                  miniEmployeesContract={this.props.blockChain.controller?.miniEmployees}
                  action={
                    this.state.selectedLimboNFTs.length > 0
                      ? {
                          onClick: async () => {
                            if (this.props.blockChain.controller?.miniEmployees && this.props.blockChain.controller.nftBridgeStorage) {
                              const selectedContractChain = posibleContractAddress[relatedModeToChain[this.state.selectedLimbo]];

                              const msg = {
                                domain: {
                                  chainId: parseInt(BlockChainHelpers.getAppChain().chainId, 16),
                                  name: "BusinessBuilders.city",
                                  verifyingContract: this.props.blockChain.controller.nftBridgeStorage?.address,
                                  version: "1",
                                },
                                message: {
                                  fromContract: selectedContractChain
                                    ? selectedContractChain[Contract.MINI_EMPLOYEES] || this.props.blockChain.controller.miniEmployees.address
                                    : "",
                                  fromNetwork: parseInt(posibleChains[this.state.selectedLimbo].chainId, 16).toString(),
                                  toNetwork: parseInt(BlockChainHelpers.getAppChain().chainId, 16).toString(),
                                  nfts: this.state.selectedLimboNFTs.map((nft) => nft.id),
                                  owner: this.props.blockChain.controller.selectedAccount,
                                },
                                primaryType: "Request",
                                types: {
                                  EIP712Domain: [
                                    { name: "name", type: "string" },
                                    { name: "version", type: "string" },
                                    { name: "chainId", type: "uint256" },
                                    {
                                      name: "verifyingContract",
                                      type: "address",
                                    },
                                  ],
                                  Request: [
                                    {
                                      name: "fromContract",
                                      type: "address",
                                    },
                                    { name: "fromNetwork", type: "string" },
                                    { name: "toNetwork", type: "string" },
                                    { name: "owner", type: "string" },
                                    { name: "nfts", type: "uint256[]" },
                                  ],
                                },
                              };

                              const eth = BlockChainHelpers.getEthWindow();

                              if (eth) {
                                const sign = await eth.request({
                                  method: "eth_signTypedData_v4",
                                  params: [this.props.blockChain.controller.selectedAccount, JSON.stringify(msg)],
                                });

                                this.setState({ selectedLimboNFTs: [] }, async () => {
                                  if (sign) {
                                    const mintingRequest = await ApiHelpers.requestMinting(
                                      msg.message.fromNetwork,
                                      msg.message.toNetwork,
                                      msg.message.fromContract,
                                      msg.message.nfts,
                                      sign
                                    );

                                    if (mintingRequest) {
                                      if (mintingRequest.rejected === false) {
                                        const bridgeToast = toast.loading(
                                          "[Request] Your request has been sent, you will be notified when it is completed. Please, save the transaction serial (" +
                                            mintingRequest.serial +
                                            ")"
                                        );

                                        this.setState(
                                          {
                                            bridgeToast: bridgeToast as string,
                                          },
                                          () => {
                                            localStorage.setItem(bridgeStorageKey, mintingRequest.serial);

                                            this._prepareBridgeInterval();
                                          }
                                        );
                                      } else {
                                        toast.error("[Request] Server rejection. " + mintingRequest.rejected);
                                      }
                                    } else {
                                      toast.error("[Server] The server did not respond correctly ");
                                    }
                                  } else {
                                    toast.error("[Signature] Sign error. Please, send the error the the telegram group.");
                                  }
                                });
                              }
                            }
                          },
                          icon: this.state.approvedNFTs ? "fas fa-exchange-alt" : "fas fa-exclamation-triangle",
                        }
                      : undefined
                  }
                  actions={[
                    {
                      onClick: () => {
                        this.setState(
                          {
                            selectedLimbo: AppChain.POLYGON,
                            selectedLimboNFTs: [],
                          },
                          () => this.updateSelectedController()
                        );
                      },
                      image: PolygonLogo,
                    },
                    {
                      onClick: () => {
                        this.setState(
                          {
                            selectedLimbo: AppChain.METER,
                            selectedLimboNFTs: [],
                          },
                          () => this.updateSelectedController()
                        );
                      },
                      image: MeterLogo,
                    },
                  ]}
                  onUpdate={() => this.updateSelectedController()}
                />
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}

          {this.state.selectedNFT === PosibleNFTs.FACTORIES ? (
            <div className="ct-factories">
              {this.props.appState.appData && this.state.factoriesLimbo ? (
                <BuilderFactoriesGrid
                  nftsPerPage={8}
                  factoriesLoader={this.state.factoriesLoader}
                  factories={this.state.factoriesLimbo}
                  appData={this.props.appState.appData}
                  page={this.state.limboPage}
                  title={posibleChains[this.state.selectedLimbo]?.chainName + " Limbo (NFT)"}
                  subtitle={"You can see your Limbo NFTs in other networks, " + this.state.selectedLimboNFTs.length}
                  gameSelection={this.state.selectedLimboNFTs as FactoryNFT[]}
                  onClick={(factory: FactoryNFT) => {
                    let searchSelected = this.state.selectedLimboNFTs.findIndex((nft) => nft.id === factory.id);

                    if (searchSelected === -1) {
                      let newState = [...this.state.selectedLimboNFTs];
                      newState.push(factory);

                      this.setState({
                        selectedLimboNFTs: newState as EmployeeNFT[],
                      });
                    } else {
                      let newState = [...this.state.selectedLimboNFTs];
                      newState.splice(searchSelected, 1);

                      this.setState({
                        selectedLimboNFTs: newState as EmployeeNFT[],
                      });
                    }
                  }}
                  action={
                    this.state.selectedLimboNFTs.length > 0
                      ? {
                          onClick: async () => {
                            if (this.props.blockChain.controller?.factories && this.props.blockChain.controller.nftBridgeStorage) {
                              const selectedContractChain = posibleContractAddress[relatedModeToChain[this.state.selectedLimbo]];

                              const msg = {
                                domain: {
                                  chainId: parseInt(BlockChainHelpers.getAppChain().chainId, 16),
                                  name: "BusinessBuilders.city",
                                  verifyingContract: this.props.blockChain.controller.nftBridgeStorage?.address,
                                  version: "1",
                                },
                                message: {
                                  fromContract: selectedContractChain
                                    ? selectedContractChain[Contract.FACTORIES] || this.props.blockChain.controller.factories.address
                                    : "",
                                  fromNetwork: parseInt(posibleChains[this.state.selectedLimbo].chainId, 16).toString(),
                                  toNetwork: parseInt(BlockChainHelpers.getAppChain().chainId, 16).toString(),
                                  nfts: this.state.selectedLimboNFTs.map((nft) => nft.id),
                                  owner: this.props.blockChain.controller.selectedAccount,
                                },
                                primaryType: "Request",
                                types: {
                                  EIP712Domain: [
                                    { name: "name", type: "string" },
                                    { name: "version", type: "string" },
                                    { name: "chainId", type: "uint256" },
                                    {
                                      name: "verifyingContract",
                                      type: "address",
                                    },
                                  ],
                                  Request: [
                                    {
                                      name: "fromContract",
                                      type: "address",
                                    },
                                    { name: "fromNetwork", type: "string" },
                                    { name: "toNetwork", type: "string" },
                                    { name: "owner", type: "string" },
                                    { name: "nfts", type: "uint256[]" },
                                  ],
                                },
                              };

                              const eth = BlockChainHelpers.getEthWindow();

                              if (eth) {
                                const sign = await eth.request({
                                  method: "eth_signTypedData_v4",
                                  params: [this.props.blockChain.controller.selectedAccount, JSON.stringify(msg)],
                                });

                                this.setState({ selectedLimboNFTs: [] }, async () => {
                                  if (sign) {
                                    const mintingRequest = await ApiHelpers.requestMinting(
                                      msg.message.fromNetwork,
                                      msg.message.toNetwork,
                                      msg.message.fromContract,
                                      msg.message.nfts,
                                      sign
                                    );

                                    if (mintingRequest) {
                                      if (mintingRequest.rejected === false) {
                                        const bridgeToast = toast.loading(
                                          "[Request] Your request has been sent, you will be notified when it is completed. Please, save the transaction serial (" +
                                            mintingRequest.serial +
                                            ")"
                                        );

                                        this.setState(
                                          {
                                            bridgeToast: bridgeToast as string,
                                          },
                                          () => {
                                            localStorage.setItem(bridgeStorageKey, mintingRequest.serial);

                                            this._prepareBridgeInterval();
                                          }
                                        );
                                      } else {
                                        toast.error("[Request] Server rejection. " + mintingRequest.rejected);
                                      }
                                    } else {
                                      toast.error("[Server] The server did not respond correctly ");
                                    }
                                  } else {
                                    toast.error("[Signature] Sign error. Please, send the error the the telegram group.");
                                  }
                                });
                              }
                            }
                          },
                          icon: this.state.approvedNFTs ? "fas fa-exchange-alt" : "fas fa-exclamation-triangle",
                        }
                      : undefined
                  }
                  actions={[
                    {
                      onClick: () => {
                        this.setState(
                          {
                            selectedLimbo: AppChain.POLYGON,
                            selectedLimboNFTs: [],
                          },
                          () => this.updateSelectedController()
                        );
                      },
                      image: PolygonLogo,
                    },
                    {
                      onClick: () => {
                        this.setState(
                          {
                            selectedLimbo: AppChain.METER,
                            selectedLimboNFTs: [],
                          },
                          () => this.updateSelectedController()
                        );
                      },
                      image: MeterLogo,
                    },
                  ]}
                  onUpdate={() => this.updateSelectedController()}
                  onChangeFactoriesPage={(page: number) => {
                    this.setState({ limboPage: page });
                  }}
                />
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}

          {/* {this.state.selectedNFT === PosibleNFTs.MINI_EMPLOYEES ? (
            <div className="ct-employees ct-mini-employees">
              {this.state.miniEmployees && this.props.appState.appData ? (
                <BuilderEmployeesGrid
                  nftsPerPage={8}
                  employeesLoader={this.state.miniEmployeesLoader}
                  employees={this.state.miniEmployees}
                  appData={this.props.appState.appData}
                  page={this.state.miniPage}
                  onlyMiniEmployees={true}
                  title="Mini Employees (NFT)"
                  onChangeEmployeesPage={(miniPage: number) =>
                    this.setState({ miniPage })
                  }
                  subtitle={
                    "Selected Mini employees " +
                    this.state.selectedEmployees.length +
                    "/ " +
                    this.state.bridgeData.storage.open
                  }
                  onUpdate={() => this.loadAndSetMiniEmployeesController()}
                />
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
          {this.state.selectedNFT === PosibleNFTs.MULTI_EMPLOYEES ? (
            <div className="ct-employees ct-multi-employees">
              {this.state.multiEmployees && this.props.appState.appData ? (
                <BuilderEmployeesGrid
                  nftsPerPage={8}
                  employeesLoader={this.state.multiEmployeesLoader}
                  employees={this.state.multiEmployees}
                  appData={this.props.appState.appData}
                  page={this.state.multiEmployeesPage}
                  onlyMultiEmployees={true}
                  title="Multi Employees Employees (NFT)"
                  onChangeEmployeesPage={(multiEmployeesPage: number) =>
                    this.setState({ multiEmployeesPage })
                  }
                  subtitle={
                    "Selected Multi employees " +
                    this.state.selectedMultiEmployees.length +
                    "/ " +
                    this.state.bridgeData.storage.maxNFTs
                  }
                  onUpdate={() => this.loadAndSetMultiEmployeesController()}
                />
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
          {this.state.selectedNFT === PosibleNFTs.FACTORIES ? (
            <div className="ct-employees">
              {this.props.appState.appData && this.state.factories ? (
                <BuilderFactoriesGrid
                  nftsPerPage={8}
                  factoriesLoader={this.state.factoriesLoader}
                  factories={this.state.factories}
                  appData={this.props.appState.appData}
                  page={this.props.appState.employeesPagination}
                  withStakingData={true}
                  onUpdate={() => this.onUpdateData(null)}
                  onChangeFactoriesPage={(page: number) =>
                    this.setState({ factoriesPage: page })
                  }
                  subtitle={
                    "Selected Factories " +
                    this.state.selectedEmployees.length +
                    "/ " +
                    this.state.bridgeData.storage.maxNFTs
                  }
                  onClick={() => {}}
                  selected={undefined}
                />
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )} */}
        </div>
      </React.Fragment>
    );
  }
}
