import { ContractBaseModel } from "../abstracts/contract";
import { AbstractEmployeesTeam } from "../abstracts/employeesTeam";

export class EmployeesTeam extends ContractBaseModel<AbstractEmployeesTeam> {
  getCustomerTeams() {
    return this.contract
      .getCustomerTeams(this.selectedAccount)
      .call(this.config);
  }

  getMiniData(id: number) {
    return this.contract.getMiniData(id).call(this.config);
  }

  getTotalEmployeesOfCustomer() {
    return this.contract
      .getTotalEmployeesOfCustomer(this.selectedAccount)
      .call(this.config);
  }

  getAllPercentages(id: number) {
    return this.contract.getAllPercentages(id).call(this.config);
  }
}
