import React from "react";
import { AppErrorCode, Contract } from "../../core/app";
import { UtilsHelpers } from "../../core/helpers/utils";
import { BuildersController } from "../../core/modules/builders";
import { EmployeesController } from "../../core/modules/employees";
import { MultiEmployeeDeployerController } from "../../core/modules/multiEmployeeDeployer";
import { TokenController } from "../../core/modules/token";
import { EmployeeNFT } from "../../core/nfts/employee";
import { ApplicationState } from "../../storage/state/app/state";
import { BlockChainState } from "../../storage/state/blockChain/state";
import { BuilderEmployeesGrid } from "../organisms/builder/employees/grid";
import { TeamLeader } from "../organisms/teamLeader";

interface MultiEmployeesComponentProps {
  appState: ApplicationState;
  blockChain: BlockChainState;
  onLoadCustomerData: (inTheEnd: boolean) => void;
  onToggleLoader: (froce: boolean) => void;
  onSetBlockChainError: (error: AppErrorCode) => void;
}

interface MultiEmployeesComponentState {
  buildersController: BuildersController | null;
  employees: EmployeesController | null;
  multiEmployeesDeployer: MultiEmployeeDeployerController | null;
  token: TokenController | null;
  maticAllowance: number;
  tokenAllowance: number;
  confirmState: boolean;
  page: number;
  deployerAllowance: number;
  employeesBalance: number;
}

export class MultiEmployeesPage extends React.Component<MultiEmployeesComponentProps, MultiEmployeesComponentState> {
  constructor(props: MultiEmployeesComponentProps) {
    super(props);

    this.state = {
      buildersController: null,
      multiEmployeesDeployer: null,
      token: null,
      employees: null,
      maticAllowance: 0,
      tokenAllowance: 0,
      confirmState: false,
      page: 0,
      deployerAllowance: 0,
      employeesBalance: 0,
    };
  }

  async componentDidMount() {
    await this.preloadData();
    this.props.onToggleLoader(false);
  }

  async preloadEmployeeController() {
    let employees = null;

    UtilsHelpers.debugger("Preload employees controller.");

    if (
      this.props.blockChain.controller?.employees &&
      this.props.blockChain.controller?.miniEmployees &&
      this.props.blockChain.controller?.multiEmployees &&
      this.props.blockChain.controller?.employeesExpanded &&
      this.props.blockChain.controller?.miniEmployeesDeployer &&
      this.props.blockChain.controller?.employeesTeam &&
      this.props.blockChain.controller?.nftBridgeStorage &&
      this.props.appState.appData
    ) {
      employees = new EmployeesController(
        this.props.blockChain.controller.employees,
        this.props.blockChain.controller.employeesExpanded,
        this.props.blockChain.controller.miniEmployees,
        this.props.blockChain.controller.miniEmployeesDeployer,
        this.props.blockChain.controller.multiEmployees,
        this.props.blockChain.controller.employeesTeam,
        this.props.blockChain.controller.nftBridgeStorage,
        this.props.appState.appData
      );
      await employees.loadMultiEmployeesData();
    }

    UtilsHelpers.debugger("Finish Preload employees controller.");

    return employees;
  }

  async preloadData() {
    let token = null;
    let deployerAllowance = 0;
    let employeesBalance = 0;

    if (this.props.blockChain.controller?.token && this.props.appState.appData?.contractsAddress) {
      token = new TokenController(this.props.blockChain.controller.token);

      deployerAllowance = UtilsHelpers.normalizeWei(
        await this.props.blockChain.controller.token.allowance(
          this.props.blockChain.controller.token.selectedAccount,
          this.props.appState.appData?.contractsAddress[Contract.MULTI_EMPLOYEE_D]
        )
      );

      employeesBalance = await this.props.blockChain.controller.employees?.balanceOf(this.props.blockChain.controller.token.selectedAccount);
    }

    const employees = await this.preloadEmployeeController();
    const multiEmployeesDeployer = await this.preloadMultiEmployeeController();

    this.setState({
      token,
      employees,
      multiEmployeesDeployer,
      deployerAllowance,
      employeesBalance,
    });
  }

  async preloadMultiEmployeeController() {
    let employees = null;

    UtilsHelpers.debugger("Preload employees controller.");
    if (this.props.blockChain.controller?.multiEmployeesDeployer && this.props.appState.appData) {
      employees = new MultiEmployeeDeployerController(this.props.blockChain.controller.multiEmployeesDeployer);

      await employees.loadDeployerData();
    }

    UtilsHelpers.debugger("Finish Preload employees controller.");

    return employees;
  }

  async onUpdateData(error: AppErrorCode | null) {
    if (!error) {
      await this.preloadData();
      this.props.onLoadCustomerData(true);
    } else this.props.onSetBlockChainError(error);
  }

  render() {
    const employeePrice = this.state.multiEmployeesDeployer?.data?.employeePrice || 0;

    return (
      <React.Fragment>
        {this.props.blockChain.customer?.teamLeader ? <TeamLeader teamLeader={this.props.blockChain.customer?.teamLeader} /> : ""}
        <div className="ct-max-container ct-multi-employees-page">
          <div className="ct-page-actions-container">
            <div className="ct-container">
              <div className="ct-data">
                <h1>Multi Employees</h1>
                <small>
                  Build your NFT empire. This employee is an initiative to improve our ecosystem. Give it value in the NFTs market and ensure our
                  growth. It will works with all of your teams.
                </small>
                <a href="https://docs.businessbuilders.city/ecosystems/polygon/employees-nfts/children" target="_blank" rel="noopener noreferrer">
                  MultiEmployee documentation
                </a>
                <small>read before buying your employee</small>
              </div>
              {this.props.blockChain.controller?.multiEmployeesDeployer &&
              this.props.blockChain.customer?.tokensData &&
              this.props.appState.appData &&
              this.props.appState.appData.customerToken &&
              this.state.token ? (
                <div className="ct-actions">
                  {this.state.confirmState ? (
                    <button
                      className="ct-main-button"
                      onClick={() =>
                        this.setState({
                          confirmState: false,
                        })
                      }
                    >
                      <span className="fas fa-times"></span>
                    </button>
                  ) : (
                    ""
                  )}
                  {!this.state.confirmState ? (
                    <button
                      onClick={() =>
                        this.setState({
                          confirmState: true,
                        })
                      }
                      className="ct-main-button"
                    >
                      Mint Multi Employee
                    </button>
                  ) : (
                    ""
                  )}
                  {this.state.confirmState ? (
                    <div className="ct-payed-confirm">
                      <span>
                        <strong>
                          Necessary FTB: {employeePrice} {this.props.appState.appData?.customerToken.loadTokenSymbol}{" "}
                        </strong>
                      </span>
                      <span>
                        <strong>FTB Balance: </strong>
                        {this.props.blockChain.customer?.tokensData.loadTokenBalance.toFixed()}{" "}
                        {this.props.appState.appData?.customerToken.loadTokenSymbol}
                      </span>
                      <span>
                        <strong>Necessary MTR: </strong>
                        {this.state.multiEmployeesDeployer?.data?.deployerPrice} MTR{" "}
                      </span>
                      <span>
                        <strong>MTR Balance: </strong>
                        {this.props.blockChain.customer?.tokensData.mainTokenBalance.toFixed()} MTR
                      </span>
                      <span>
                        <strong>Necessary Employees: </strong>
                        {this.state.employeesBalance}/ 5
                      </span>

                      {this.state.deployerAllowance >= employeePrice ? (
                        <button
                          className="ct-main-button"
                          disabled={employeePrice > this.props.blockChain.customer?.tokensData.loadTokenBalance || this.state.employeesBalance < 5}
                          onClick={() => {
                            this.state.multiEmployeesDeployer?.mintEmployee(
                              this.state.multiEmployeesDeployer?.data?.deployerPrice || 0,
                              (error: AppErrorCode | null) => {
                                this.onUpdateData(error);
                              }
                            );
                          }}
                        >
                          Confirm
                        </button>
                      ) : (
                        <button
                          className="ct-main-button"
                          onClick={() => {
                            if (this.props.appState.appData) {
                              this.state.token?.approveTokenSpend(
                                this.props.appState.appData?.contractsAddress[Contract.MULTI_EMPLOYEE_D],
                                employeePrice,
                                (error: AppErrorCode | null) => {
                                  this.onUpdateData(error);
                                }
                              );
                            }
                          }}
                        >
                          Approve
                        </button>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          {this.state.employees && this.props.appState.appData && this.state.token ? (
            <BuilderEmployeesGrid
              nftsPerPage={8}
              token={this.state.token}
              employees={this.state.employees}
              appData={this.props.appState.appData}
              page={this.state.page}
              onUpdate={() => this.preloadData()}
              onlyMultiEmployees
              onChangeEmployeesPage={(page: number) => this.setState({ page })}
              onRandomize={(nft: EmployeeNFT) => {
                if (this.props.blockChain.controller?.multiEmployeesDeployer) {
                  this.props.blockChain.controller.multiEmployeesDeployer.radomizeMyEmployee(nft.id, (error) => {
                    this.onUpdateData(error);
                  });
                }
              }}
            />
          ) : (
            ""
          )}
        </div>
      </React.Fragment>
    );
  }
}
