import { appConfig } from "../app";
import { BlockChainHelpers } from "./chain";

export class ApiHelpers {
  static configuration = {
    host: appConfig.backendController,
  };

  public static async get(url: string) {
    try {
      const json = await fetch(url);
      return await json.json();
    } catch (error) {
      return false;
    }
  }

  public static async post(url: string, data: any) {
    try {
      const json = await fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });
      return await json.json();
    } catch (error) {
      return false;
    }
  }

  public static async uploadImages(formData: FormData) {
    try {
      const json = await fetch(this.configuration.host + "/upload-images", {
        method: "POST",
        body: formData,
      });
      return await json.json();
    } catch (error) {
      return false;
    }
  }

  public static async preloadEmployees(contract: string, employees: number[]) {
    return ApiHelpers.post(
      ApiHelpers.configuration.host +
        "/preloaded-nfts/" +
        contract +
        "/" +
        parseInt(BlockChainHelpers.getAppChain().chainId, 16),
      { ids: employees }
    );
  }

  public static async preloadFactoryRelations(owner: string, factories: number[]) {
    return ApiHelpers.post(
      ApiHelpers.configuration.host +
        "/preloaded-factory-relations/" +
        owner +
        "/" +
        parseInt(BlockChainHelpers.getAppChain().chainId, 16),
      { factories }
    );
  }

  public static async getUserLogs(owner: string) {
    return ApiHelpers.get(ApiHelpers.configuration.host + "/get-user-logs/" + owner);
  }

  public static async saveProfileData(
    image: string,
    name: string,
    network: string,
    sign: string,
    owner: string,
    selectedCity: string,
    communityLink: string
  ) {
    return ApiHelpers.post(ApiHelpers.configuration.host + "/save-user-profile", {
      image,
      name,
      network,
      sign,
      owner,
      selectedCity,
      communityLink,
    });
  }

  public static async getProfileData(owner: string) {
    return ApiHelpers.get(
      ApiHelpers.configuration.host +
        "/user-profile/" +
        owner +
        "/" +
        parseInt(BlockChainHelpers.getAppChain().chainId, 16)
    );
  }

  public static async limboNFTs(network: number, owner: string, contract: string) {
    return ApiHelpers.get(ApiHelpers.configuration.host + "/limbo-nfts/" + network + "/" + owner + "/" + contract);
  }

  public static async limboData() {
    return ApiHelpers.get(ApiHelpers.configuration.host + "/limbo-data");
  }

  public static async requestMinting(
    fromNetwork: string,
    toNetwork: string,
    fromContract: string,
    nfts: number[],
    sign: string
  ) {
    return ApiHelpers.post(ApiHelpers.configuration.host + "/request-minting", {
      fromNetwork,
      toNetwork,
      fromContract,
      nfts,
      sign,
    });
  }

  public static async getNFTWarnings(address: string) {
    return ApiHelpers.get(ApiHelpers.configuration.host + "/get-banning-resolution/" + address);
  }

  public static async getBridgeTransaction(id: string) {
    return ApiHelpers.get(ApiHelpers.configuration.host + "/transaction/" + id);
  }

  public static async getFactoryRelation(factory: number) {
    return ApiHelpers.get(ApiHelpers.configuration.host + "/relation/" + factory);
  }
}
