import React from "react";
import { MiniEmployees } from "../../../../core/contracts/miniEmployees";
import { EmployeeNFT, EmployeeProperties, EmployeeProperty } from "../../../../core/nfts/employee";
import { UtilsHelpers } from "../../../../core/helpers/utils";
import { EmployeesController } from "../../../../core/modules/employees";
import { MiniEmployeeDeployerController } from "../../../../core/modules/miniEmployeeDeployer";
import { TokenController } from "../../../../core/modules/token";
import { AppData } from "../../../../core/types";
import { StaticEmployeeNFT } from "../../../molecules/nft/employees/employee";

import "./grid.styles.css";

export interface BuilderEmployeesGridProps {
  page: number;
  nftsPerPage: number;
  appData: AppData;
  token?: TokenController;
  employees: EmployeesController;
  miniEmployeesDeployer?: MiniEmployeeDeployerController;
  employeesLoader?: boolean;
  withMiniEmployeesData?: boolean;
  small?: boolean;
  fullWith?: boolean;
  title?: string;
  subtitle?: string;
  removeSelection?: EmployeeNFT[];
  gameSelection?: EmployeeNFT[];
  deactivatedSelection?: EmployeeNFT[];
  onlyMiniEmployees?: boolean;
  onlyMultiEmployees?: boolean;
  withoutMultiEmployees?: boolean;
  selectedClass?: string;
  miniEmployeesContract?: MiniEmployees;
  withoutSpecial?: boolean;
  action?: { onClick: () => void; icon: string };
  actions?: { onClick: () => void; icon?: string; image?: string }[];
  onUpdate?: () => void;
  onSell?: (id: number, price: number) => void;
  onBurn?: (id: number) => void;
  onApprove?: (address: string, amount: number) => void;
  onTransfer?: (to: string) => void;
  onSendToStake?: (id: number) => void;
  onRemoveFromStake?: (id: number) => void;
  onChangeEmployeesPage?: (page: number) => void;
  onSelect?: (employee: EmployeeNFT) => void;
  onClick?: (employee: EmployeeNFT) => void;
  onClose?: () => void;
  onUpgrade?: (nft: EmployeeNFT) => void;
  onRandomize?: (nft: EmployeeNFT) => void;
}

export interface BuilderEmployeesGridState {
  properties: EmployeeProperties;
  filter: boolean;
  points: boolean;
  id: boolean;
}

export class BuilderEmployeesGrid extends React.Component<BuilderEmployeesGridProps, BuilderEmployeesGridState> {
  constructor(props: BuilderEmployeesGridProps) {
    super(props);

    this.state = {
      properties: {
        head: null,
        points: null,
        hands: null,
        legs: null,
        body: null,
        id: null,
      },
      filter: false,
      points: false,
      id: true,
    };
  }

  filteredNFTs() {
    return (
      this.props.onlyMiniEmployees ? this.props.employees.miniEmployeesData : this.props.employees.employeesData
    ).filter((nft: EmployeeNFT) => {
      if (
        this.props.removeSelection &&
        this.props.removeSelection.find((employee) => employee.id === nft.id && !nft.isMultiEmployee)
      ) {
        return false;
      }

      if (this.props.withoutMultiEmployees && nft.isMultiEmployee) {
        return false;
      }

      return Object.keys(nft.properties).every((propertyKey: string) => {
        return (
          this.state.properties[propertyKey as EmployeeProperty] === null ||
          this.state.properties[propertyKey as EmployeeProperty]?.toString() ===
            nft.properties[propertyKey as EmployeeProperty]?.toString()
        );
      });
    });
  }

  render() {
    const filteredNFTs = this.filteredNFTs();
    const totalPages = Math.ceil(filteredNFTs.length / this.props.nftsPerPage);
    const selectedNFTs = [];

    if (this.state.points && !this.state.id) {
      filteredNFTs.sort((a, b) => {
        if (a.points < b.points) return 1;
        if (a.points > b.points) return -1;
        return 0;
      });
    }

    if (this.state.id && !this.state.points) {
      filteredNFTs.sort((a, b) => {
        if (a.id < b.id) return 1;
        if (a.id > b.id) return -1;
        return 0;
      });
    }

    for (let i = 0; i < totalPages; i++) {
      if (i === this.props.page) {
        for (let j = i * this.props.nftsPerPage; j < this.props.nftsPerPage * i + this.props.nftsPerPage; j++) {
          if (filteredNFTs[j]) selectedNFTs.push(filteredNFTs[j]);
        }
      }
    }

    return (
      <div className={"ct-builder-grid ct-employees-grid" + (this.props.fullWith ? " ct-fullwith" : "")}>
        <div className="ct-sub-container">
          <div className="ct-title">
            <h1>
              {this.props.title ? this.props.title : "Employees (NFTs)"}{" "}
              <small>{this.props.subtitle ? this.props.subtitle : ""}</small>
            </h1>
            <div className="ct-actions">
              {this.props.actions && this.props.actions.length > 0
                ? this.props.actions.map((action, index) => {
                    if (action.image) {
                      return (
                        <img
                          key={index}
                          src={action.image}
                          onClick={() => (action?.onClick ? action.onClick() : null)}
                          alt="block chain logo"
                        ></img>
                      );
                    } else if (action.icon) {
                      return (
                        <span
                          key={index}
                          className={action.icon}
                          onClick={() => (action?.onClick ? action.onClick() : null)}
                        ></span>
                      );
                    } else {
                      return "";
                    }
                  })
                : ""}
              {this.props.action && this.props.action.icon && this.props.action.onClick ? (
                <span
                  className={this.props.action.icon}
                  onClick={() => (this.props.action?.onClick ? this.props.action.onClick() : null)}
                ></span>
              ) : (
                ""
              )}
              <span
                onClick={() => (this.props.onUpdate ? this.props.onUpdate() : undefined)}
                className="fas fa-sync-alt"
              ></span>
              {this.state.filter ? (
                <span onClick={() => this.setState({ filter: !this.state.filter })} className="fas fa-times"></span>
              ) : (
                <span onClick={() => this.setState({ filter: !this.state.filter })} className="fas fa-search"></span>
              )}
              {this.props.onClose ? (
                <span
                  className="fas fa-times"
                  onClick={() => (this.props.onClose ? this.props.onClose() : null)}
                ></span>
              ) : (
                ""
              )}
            </div>
          </div>
          {!this.props.employeesLoader ? (
            !this.state.filter ? (
              totalPages > 0 ? (
                <div className="ct-grid-container">
                  {selectedNFTs.map((nft, index) => {
                    return (
                      <StaticEmployeeNFT
                        onTransfer={
                          this.props.onTransfer
                            ? (to: string) => (this.props.onTransfer ? this.props.onTransfer(to) : null)
                            : undefined
                        }
                        onBurn={
                          this.props.onBurn
                            ? () => (this.props.onBurn && nft.id ? this.props.onBurn(nft.id) : null)
                            : undefined
                        }
                        onSendToStake={
                          this.props.onSendToStake
                            ? () => (this.props.onSendToStake && nft.id ? this.props.onSendToStake(nft.id) : null)
                            : undefined
                        }
                        onRemoveFromStake={
                          this.props.onRemoveFromStake
                            ? () =>
                                this.props.onRemoveFromStake && nft.id ? this.props.onRemoveFromStake(nft.id) : null
                            : undefined
                        }
                        onSell={
                          this.props.onSell
                            ? (price: number) => (this.props.onSell ? this.props.onSell(nft.id, price) : null)
                            : undefined
                        }
                        onSelect={
                          this.props.onSelect
                            ? (employee: EmployeeNFT) => (this.props.onSelect ? this.props.onSelect(employee) : null)
                            : undefined
                        }
                        onClick={
                          this.props.onClick
                            ? (employee: EmployeeNFT) => (this.props.onClick ? this.props.onClick(employee) : null)
                            : undefined
                        }
                        selected={
                          !!this.props.gameSelection?.find(
                            (employee) => employee.id === nft.id && employee.isMultiEmployee === nft.isMultiEmployee
                          )
                        }
                        onUpgrade={
                          this.props.onUpgrade
                            ? (nft: EmployeeNFT) => (this.props.onUpgrade ? this.props.onUpgrade(nft) : undefined)
                            : undefined
                        }
                        deactivated={
                          !!this.props.deactivatedSelection?.find(
                            (employee) => employee.id === nft.id && employee.isMultiEmployee === nft.isMultiEmployee
                          )
                        }
                        nft={nft}
                        miniEmployees={this.props.miniEmployeesContract}
                        key={index + "-" + nft.id}
                        token={this.props.token}
                        employees={this.props.employees}
                        withMiniEmployeesData={this.props.withMiniEmployeesData}
                        selectedClass={this.props.selectedClass}
                        small={this.props.small}
                        onRandomize={(employee: EmployeeNFT) =>
                          this.props.onRandomize ? this.props.onRandomize(employee) : null
                        }
                      />
                    );
                  })}
                  <div className="ct-pagination">
                    {this.props.page > 0 ? (
                      <span
                        className="fas fa-chevron-left"
                        onClick={() => {
                          let momentPage: number = this.props.page;
                          if (momentPage > 0) momentPage--;
                          if (this.props.onChangeEmployeesPage) {
                            this.props.onChangeEmployeesPage(momentPage);
                          }
                        }}
                      ></span>
                    ) : (
                      ""
                    )}
                    <span>{this.props.page + 1}</span>
                    {this.props.page < totalPages - 1 ? (
                      <span
                        className="fas fa-chevron-right"
                        onClick={() => {
                          let momentPage: number = this.props.page;
                          if (momentPage < totalPages - 1) momentPage++;
                          if (this.props.onChangeEmployeesPage) {
                            this.props.onChangeEmployeesPage(momentPage);
                          }
                        }}
                      ></span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                <div className="ct-without-data">
                  <div className="spinner"></div>
                  {totalPages === 0 ? <p>You dont have Employees.</p> : ""}
                </div>
              )
            ) : (
              <div className="ct-filters">
                <h5>Filters</h5>
                <div className="ct-filter">
                  <label htmlFor="points">Points</label>
                  <input
                    type="number"
                    onChange={(e) =>
                      this.setState({
                        properties: {
                          ...this.state.properties,
                          points: e.target.value !== "" ? e.target.value : null,
                        },
                      })
                    }
                  />
                </div>
                {["head", "body", "legs", "hands"].map((type) => {
                  return (
                    <div className="ct-filter">
                      <label htmlFor={type}>{type}</label>
                      <select
                        onChange={(e) =>
                          this.setState({
                            properties: {
                              ...this.state.properties,
                              [type]:
                                e.target.options[e.target.selectedIndex].value === ""
                                  ? null
                                  : e.target.options[e.target.selectedIndex].value,
                            },
                          })
                        }
                        name={type}
                        id=""
                      >
                        <option value="">- None -</option>
                        {this.props.appData.employeesData.buildTypes.map((type, index) => {
                          return (
                            <option key={index} value={type}>
                              {UtilsHelpers.getTypeName(Number(type))}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  );
                })}
                <div className="ct-filter ct-sort-filter">
                  <label htmlFor="sort-by">Sort by</label>
                  <label htmlFor="points">
                    <input
                      type="radio"
                      checked={this.state.points}
                      name="points-sort"
                      onChange={(e) =>
                        this.setState({
                          points: e.target.checked,
                          id: !e.target.checked,
                        })
                      }
                    />
                    Points
                  </label>
                  <label htmlFor="id-sort">
                    <input
                      type="radio"
                      checked={this.state.id}
                      name="id-sort"
                      onChange={(e) =>
                        this.setState({
                          id: e.target.checked,
                          points: !e.target.checked,
                        })
                      }
                    />
                    ID
                  </label>
                </div>
                <div className="ct-buttons">
                  <button onClick={() => this.setState({ filter: !this.state.filter })} className="ct-main-button">
                    Search (Total NFTs: {filteredNFTs.length})
                  </button>
                  <button
                    className="ct-main-button"
                    onClick={() =>
                      this.setState({
                        properties: {
                          head: null,
                          points: null,
                          body: null,
                          legs: null,
                          hands: null,
                          id: null,
                        },
                      })
                    }
                  >
                    Reset
                  </button>
                </div>
              </div>
            )
          ) : (
            <div className="ct-without-data">
              <div className="spinner"></div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
