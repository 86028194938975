import { AppErrorCode } from "../app";
import { MultiEmployeeDeployer } from "../contracts/multiEmployeeDeployer";
import { UtilsHelpers } from "../helpers/utils";

interface DeployerData {
  CREATOR_FEE: number;
  LIQUIDITY_AGREGATOR_FEE: number;
  deployerPrice: number;
  employeePrice: number;
}

export class MultiEmployeeDeployerController {
  public data: DeployerData | null = null;

  constructor(public contract: MultiEmployeeDeployer) {}

  public async loadDeployerData() {
    const data = await this.contract.getEmployeesData();
    if (data) {
      this.data = {
        ...data,
        deployerPrice: UtilsHelpers.normalizeWei(data.deployerPrice),
        employeePrice: UtilsHelpers.normalizeWei(data.employeePrice),
      };
    }
  }

  public mintEmployee(
    amount: number,
    callback?: (error: AppErrorCode | null) => void
  ) {
    UtilsHelpers.debugger("Mint Employee");

    if (this.contract.selectedAccount) {
      UtilsHelpers.debugger(
        "Try Mint NFT (" + this.contract.selectedAccount + ")"
      );

      if (callback) this.contract.mintEmployee(amount, callback);
    } else {
      UtilsHelpers.debugger("Mint is not available.");
      if (callback) callback(AppErrorCode.MINT_IS_NOT_VALID);
    }
  }
}
