import { toast } from "react-toastify";
import { BlockChainState } from "../storage/state/blockChain/state";
import { AppErrorCode, Contract } from "./app";
import { BlockChain } from "./chain";
import { ApiHelpers } from "./helpers/api";
import { BlockChainHelpers } from "./helpers/chain";
import { UtilsHelpers } from "./helpers/utils";
import { AppData, TeamLeaderNFT } from "./types";

export class Customer {
  constructor(
    public _address: string | null,
    public appDataModel: AppData,
    public teamLeader: null | TeamLeaderNFT
  ) {}

  get tokensData() {
    return this.appDataModel.customerToken;
  }

  //BlockChain

  static async loadCustomerData(blockChain: BlockChain): Promise<{
    customer: null | Customer;
    error: AppErrorCode | null;
    appData: AppData | null;
  }> {
    UtilsHelpers.debugger("Load customer Data.");

    try {
      if (
        blockChain.token &&
        blockChain.employees &&
        blockChain.employeeDeployer &&
        blockChain.baseDeployer &&
        blockChain.selectedAccount &&
        blockChain.factories &&
        blockChain.factoryDeployer &&
        blockChain.miniEmployees &&
        blockChain.miniEmployeesDeployer &&
        blockChain.multiEmployeesDeployer &&
        blockChain.multiEmployees &&
        blockChain.citiesDeployer &&
        blockChain.teamLeader &&
        blockChain.teamLeaderValidations &&
        blockChain.teamBuilder &&
        blockChain.employeesTeam &&
        blockChain.nftBridgeStorage
      ) {
        const mainTokenBalance = UtilsHelpers.normalizeWei(
          (await BlockChainHelpers.getMainTokenBalance(
            blockChain.selectedAccount
          )) || "0"
        );

        const loadTokenBalance = UtilsHelpers.normalizeWei(
          await blockChain.token.balanceOf(blockChain.selectedAccount)
        );

        const baseDeployerData = {
          buildModels: ["1", "2", "3", "4"],
          buildTypes: ["1", "2", "3", "4", "5"],
          probabilitiesTotal: "100",
          typeProbabilities: ["1", "5", "10", "30", "54"],
        };

        const loadTokenName = "Factory Builders Token";
        const loadTokenSymbol = "FTB";

        const employeeDeployerData = {
          creatorFee: "5",
          employeePoolFee: "1",
          playToEarnFee: "89",
          price: "10000000000000000000000",
          stakingFee: "5",
        };

        const factoryDeployerData = {
          burnEmployees: "5",
          creatorFee: "5",
          playToEarnFee: "90",
          price: "5000000000000000000000",
          stakingFee: "5",
        };

        const miniEmployeeDeployerData = {
          employeePrice: "1000000000000000000000",
          minterPointsPrice: "2000",
          payedMints: "",
          maxPayedMints: "",
          packageSize: "5",
          packageDiscount: "5",
          maxMerges: "10",
          creatorFee: "",
          employeeStakingFee: "",
          playToEarnFee: "",
          validateMaxPayedMints: "",
        };

        const nftWarning = await ApiHelpers.getNFTWarnings(
          blockChain.selectedAccount
        );

        let teamLeader: TeamLeaderNFT | null = null;

        try {
          teamLeader =
            await blockChain.teamLeaderValidations.getTeamLeaderInfo();
        } catch (error) {
          console.log("[Team Leader] Invalid NFT.");
        }

        if (nftWarning) {
          toast.error(
            nftWarning._context +
              "Related Addresses:" +
              nftWarning._relatedAddresses?.map(
                (address: string) =>
                  address.substr(address.length - 10, address.length) + " "
              ),
            { closeOnClick: true }
          );
        }

        const appDataModel: AppData = AppData.buildFromTemplates(
          {
            mainTokenBalance,
            loadTokenBalance,
            loadTokenSymbol,
            loadTokenName,
          },
          {
            ...baseDeployerData,
            ...employeeDeployerData,
          },
          {
            ...baseDeployerData,
            ...factoryDeployerData,
          },
          {
            ...baseDeployerData,
            ...miniEmployeeDeployerData,
          },
          {
            [Contract.TOKEN]: blockChain.token.address,
            [Contract.EMPLOYEE_DEPLOYER]: blockChain.employeeDeployer.address,
            [Contract.EMPLOYEES]: blockChain.employees.address,
            [Contract.BASE_DEPLOYER]: blockChain.baseDeployer.address,
            [Contract.FACTORIES]: blockChain.factories.address,
            [Contract.FACTORY_DEPLOYER]: blockChain.factoryDeployer.address,
            [Contract.MINI_EMPLOYEES]: blockChain.miniEmployees.address,
            [Contract.MULTI_EMPLOYEE]: blockChain.multiEmployees.address,
            [Contract.NFT_BRIDGE_STORAGE]: blockChain.nftBridgeStorage.address,
            [Contract.CITIES_DEPLOYER]: blockChain.citiesDeployer.address,
            [Contract.TEAM_BUILDER]: blockChain.teamBuilder.address,
            [Contract.EMPLOYEES_TEAM]: blockChain.employeesTeam.address,
            [Contract.MULTI_EMPLOYEE_D]:
              blockChain.multiEmployeesDeployer.address,
            [Contract.MINI_EMPLOYEES_D]:
              blockChain.miniEmployeesDeployer.address,
          }
        );

        return {
          customer: new Customer(
            blockChain.selectedAccount,
            appDataModel,
            teamLeader as TeamLeaderNFT
          ),
          error: null,
          appData: appDataModel,
        };
      } else {
        toast.error("[ERROR] " + AppErrorCode.INVALID_CONTRACT_LOADING);

        return {
          error: AppErrorCode.INVALID_CONTRACT_LOADING,
          customer: null,
          appData: null,
        };
      }
    } catch (error) {
      toast.error("[ERROR] " + AppErrorCode.INVALID_CONTRACT_LOADING);
      console.log(error);
      return {
        error: AppErrorCode.INVALID_CONTRACT_LOADING,
        customer: null,
        appData: null,
      };
    }
  }

  //Storage

  static setCustomer(state: BlockChainState, customer: Customer) {
    return { ...state, customer };
  }

  static clearCustomer(state: BlockChainState) {
    return { ...state, customer: null };
  }
}
