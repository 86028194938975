export enum AppMode {
  DEV = "dev",
  PRO = "pro",
  METER = "meter",
  TEST = "test",
  TEST_METER = "test-meter",
  TEST_POLYGON = "test-polygon",
  POLYGON = "polygon",
}

export enum AppChain {
  BSC = "bsc",
  BSCT = "bsc-test",
  METER = "meter",
  METER_TEST = "meter-test",
  POLYGON_TEST = "polygon-test",
  POLYGON = "polygon",
  GANACHE = "ganache",
}

export enum Contract {
  TOKEN = "token",
  SPECIAL_TOKEN = "special-token",
  CITIES = "cities",
  EMPLOYEES = "employees",
  EMPLOYEES_EXPANDED = "employees-expanded",
  MULTI_EMPLOYEE = "multi-employee",
  MINI_EMPLOYEES = "mini-employees",
  MULTI_EMPLOYEE_D = "multi-employee-deployer",
  MINI_EMPLOYEES_D = "mini-employees-deployer",
  EMPLOYEE_DEPLOYER = "employee-deployer",
  FACTORY_DEPLOYER = "factory-deployer",
  CITIES_DEPLOYER = "cities-deployer",
  CITIES_STORAGE = "cities-storage",
  BASE_DEPLOYER = "base-deployer",
  FACTORIES = "factories",
  NFT_BRIDGE_STORAGE = "nft-bridge-storage",
  CITY_GETTERS = "city-getters",
  UNIVERSITIES = "universities",
  TEAM_LEADER_V = "team-leader-validations",
  TEAM_LEADER = "team-leader",
  TEAM_BUILDER = "team-builder",
  EMPLOYEES_TEAM = "employees-team",
}

export enum AppErrorCode {
  BAD_REQUEST = "bad_request",
  INCORRECT_BLOCKCHAIN_NETWORK = "incorrect-block-chain-network",
  INVALID_PROVIDER = "invalid-provider",
  INVALID_CONTRACT_LOADING = "invalid-contract-loading",
  // NFT errors
  MINT_IS_NOT_VALID = "mint-is-not-valid",
  RESET_DEPLOYMENTS_ERROR = "reset-deployments-error",
  MINT_ERROR = "mint-error",
  ADD_EMPLOYEE_TO_FACTORY = "add-employee-to-factory-error",
  BURN_ERROR = "burn-error",
  UPGRADE_ERROR = "upgrade-error",
  GROW_ERROR = "grow-error",
  GROW_ALL_ERROR = "grow-all-error",
  CANT_GROW_ERROR = "cant-grow-error",
  CANT_GROW_ALL_ERROR = "cant-grow-all-error",
  HAVENT_UPGRADES = "havent-upgrades",
  ADD_TO_WHITELIST = "add-to-whitelist",
  ON_SAVE_MINTER_POINTS = "on-save-minter-points",
  // App errors
  USER_DENIED_TRANSACTION = "user-denied-transaction",
  INVALID_APPROVED_SPEND = "invalid-approved-spend",
  INVALID_TESTNET_REQUEST = "invalid-testnet-request",
  //Connections errors
  ADD_MODEL_ERROR = "add-model-error",
  ADD_TYPE_ERROR = "add-type-error",
  REMOVE_MODEL_ERROR = "remove-model-error",
  REMOVE_TYPE_ERROR = "remove-type-error",
  SEND_EMPLOYEE_TO_WORK_ERROR = "send-employee-to-work-error",
  //Employee staking
  SEND_EMPLOYEE_TO_STAKING = "send-employee-to-staking",
  REMOVE_EMPLOYEE_FROM_STAKING = "remove-employee-from-staking",
  TAKE_CUSTOMER_REWARDS = "take-customer-rewards",
  //Factory staking
  SEND_FACTORY_TO_STAKING = "send-factory-to-staking",
  REMOVE_FACTORY_FROM_STAKING = "remove-factory-from-staking",
  SEND_CUSTOMER_STAKING_REWARDS = "send-customer-staking-rewards",
  INVALID_POINTS = "invalid-points",
  //Token
  APPROVE_SPEND = "approved-spend",
  INCREASE_ALLOWANCE = "increase-allowance",
  //Employees
  BURN_EMPLOYEE = "burn-employee",
  APPROVE_EMPLOYEE = "approve-employee",
  SAFE_TRANSFER_FROM = "safe-transfer-from",
  //Marketplace
  SELL_ERROR = "sell-error",
  BUY_ERROR = "buy-error",
  CANCEL_SELL_ERROR = "cancel-sell-error",
  //Game
  REQUEST_TOKENS = "request-tokens",
  PLAY_ERROR = "play-error",
  //Builders
  REQUEST_ERROR = "request-error",
  //Bridge
  MINTER_REQUEST_ERROR = "request-error",
  LIMBO_REQUEST_ERROR = "limbo-error",
}

export const ULTRA_ERRORS = [AppErrorCode.INVALID_PROVIDER, AppErrorCode.INCORRECT_BLOCKCHAIN_NETWORK, AppErrorCode.INVALID_CONTRACT_LOADING];

export const appConfig = {
  mode: AppMode.POLYGON,
  open: true,
  confirmedBlocks: 0,
  blocktimeout: 1000,
  fastTransaction: "60",
  backendController: "https://back.businessbuilders.city",
  universeUrl: "https://universe.businessbuilders.city",
};

export interface AppError {
  message: string;
  code: AppErrorCode;
}

export interface Currency {
  name?: string;
  symbol?: string;
  decimals?: number;
}

export interface ChainData {
  chainId: string;
  chainName?: string;
  nativeCurrency?: Currency;
  rpcUrls?: string[];
  blockExplorerUrls?: string[] | null;
}

export type PosibleChainsType = {
  [mode in AppChain]: ChainData;
};

export type PosibleContractAddress = {
  [mode in AppMode]:
    | {
        [contract in Contract]: string | null;
      }
    | null;
};

export const artHost = {
  [AppMode.DEV]: "https://art.businessbuilders.city/static",
  [AppMode.TEST]: "https://art.businessbuilders.city/static",
  [AppMode.PRO]: "https://art.businessbuilders.city/static",
  [AppMode.METER]: "https://art.businessbuilders.city/static",
  [AppMode.TEST_METER]: "https://art.businessbuilders.city/static",
};

export const posibleContractAddress: PosibleContractAddress = {
  [AppMode.DEV]: null,
  [AppMode.TEST]: {
    [Contract.TOKEN]: "0x934d44b5Acb8aB9DceA6425342c95f8DfB50129d",
    [Contract.SPECIAL_TOKEN]: "",
    [Contract.EMPLOYEES]: "0x1df07136525c158d50489554df259738F632B93e",
    [Contract.EMPLOYEES_EXPANDED]: null,
    [Contract.BASE_DEPLOYER]: "0x94E08ab6e5B1e9e2360b72cf983D43d221644c34",
    [Contract.EMPLOYEE_DEPLOYER]: "0xBBc46098D4D6446ABdEF4d1B7DAfbc069Dd372AA",
    [Contract.FACTORIES]: null,
    [Contract.FACTORY_DEPLOYER]: null,
    [Contract.MINI_EMPLOYEES]: null,
    [Contract.MINI_EMPLOYEES_D]: null,
    [Contract.MULTI_EMPLOYEE]: null,
    [Contract.MULTI_EMPLOYEE_D]: null,
    [Contract.CITIES]: null,
    [Contract.CITIES_DEPLOYER]: null,
    [Contract.CITIES_STORAGE]: null,
    [Contract.NFT_BRIDGE_STORAGE]: null,
    [Contract.CITY_GETTERS]: null,
    [Contract.UNIVERSITIES]: null,
    [Contract.TEAM_LEADER_V]: null,
    [Contract.TEAM_LEADER]: null,
    [Contract.EMPLOYEES_TEAM]: null,
    [Contract.TEAM_BUILDER]: null,
  },
  [AppMode.PRO]: null,
  [AppMode.METER]: {
    [Contract.TOKEN]: "0x6CFe9AdaD5215195c1Aa9755DAed29360e6Ab986",
    [Contract.SPECIAL_TOKEN]: "0x228ebBeE999c6a7ad74A6130E81b12f9Fe237Ba3",
    [Contract.EMPLOYEES]: "0xFb5222679a578498E2f515ba339422443B329A73",
    [Contract.CITIES]: "0x66b1a3d81b77f15866340C38B69790AfAF4F489A",
    [Contract.MULTI_EMPLOYEE]: "0xfA9aD073CD0043bCe5169dc0E49BE8FF909AAB92",
    [Contract.MINI_EMPLOYEES]: "0xc67822e0F63aA4Bb22cFfaE8D761d3F6744193Db",
    [Contract.FACTORIES]: "0x31F5F941bDD788019e65798e45f2EB576F33093e",
    [Contract.BASE_DEPLOYER]: "0xfF78D924ceb7790152F540901aF3395A2eB57f1c",
    [Contract.EMPLOYEES_EXPANDED]: "0x6E3b3eddc6Ec9E42BA3F711F8c8e01afDA8b3E89",
    [Contract.EMPLOYEE_DEPLOYER]: "0xeD96660431430D8A2a9B1D989aeDD04dCF0d2Ac3",
    [Contract.FACTORY_DEPLOYER]: "0xDd1c3C8a9eBa2E52E4F3Eb704Af5F6Dd0783cf82",
    [Contract.MINI_EMPLOYEES_D]: "0xA3349910D7b393f5D9dA1E205364306669B72335",
    [Contract.MULTI_EMPLOYEE_D]: "0xA1C437D81F5Da5Ab8978BF8cd976135Bcb5605bb",
    [Contract.CITIES_DEPLOYER]: "0xF90c96A1295d9Ec01F095C0AE68c4D05169e9908",
    [Contract.CITIES_STORAGE]: "0xdf695e2f798672EeC4Ea64a96c39569dE4200Aa2",
    [Contract.NFT_BRIDGE_STORAGE]: "0xF1e2Cb34affF2E6F79936fE04deD739f6C866E90",
    [Contract.CITY_GETTERS]: "0x02946Bb2DffFE3f9Ebee19807Ab2e6afA16a52B6",
    [Contract.UNIVERSITIES]: "0x1709EfA8cfB3eaEAa75fC7932B77526e0e2Ea65A",
    [Contract.TEAM_LEADER_V]: "0x6eA8950cf9468dBc169Ef1527CB7Bd4A7840c105",
    [Contract.TEAM_LEADER]: "0xF8afF7c27FA8B57C9e8B293bdAB07a831EFfb525",
    [Contract.EMPLOYEES_TEAM]: "0x9e860E9629671cDC5CF05D2Cf5f3FeBCD8c8b082",
    [Contract.TEAM_BUILDER]: "0xE0B283A6b6082326e4c8d84c9B28A1fF088ecE62",
  },
  [AppMode.TEST_METER]: {
    [Contract.TOKEN]: "0x6c7bA82B52ACfE6023Eae200FdFb8A0E38002bDe",
    [Contract.SPECIAL_TOKEN]: "0x6c7bA82B52ACfE6023Eae200FdFb8A0E38002bDe",
    [Contract.EMPLOYEES]: "0x7a988EDA3D6a69567214E180301B97F62dcD4D53",
    [Contract.EMPLOYEES_EXPANDED]: "0xfB2058c04b86B2D9C8Dd72843152F8585ccEa83d",
    [Contract.EMPLOYEE_DEPLOYER]: "0xF181e1e6321F3775FCC13be97CB39cc9BB018d63",
    [Contract.BASE_DEPLOYER]: "0xd1590004332BBcAfA49675d7CcCc84733E7e101e",
    [Contract.FACTORIES]: "0xA0F5BFEEf5624ebdC0e254660AE2b60e8FB8fca7",
    [Contract.FACTORY_DEPLOYER]: "0xD199A0F50A1d078ECc961E53C49E28736a479cd4",
    [Contract.MINI_EMPLOYEES]: "0xB82c40A54eaD7dc2662ae3f80649cFb2012F83fB",
    [Contract.MINI_EMPLOYEES_D]: "0x8a35FE8674c7973d7d0371426D3685327c86C489",
    [Contract.MULTI_EMPLOYEE]: "0xecEc3c6f5ae5d6712f0709B80784E8990Dc6227A",
    [Contract.MULTI_EMPLOYEE_D]: "0xf4A515961377d3536854190A95cb72D73AcfAf9D",
    [Contract.CITIES]: "0xf21f7f50b02CAfC0C9F58FdD611F3726E571DCcd",
    [Contract.CITIES_DEPLOYER]: "0x3085f4fF9C57451a4a34678DDEd970DEEcD3EFCc",
    [Contract.CITIES_STORAGE]: null,
    [Contract.NFT_BRIDGE_STORAGE]: "0xe785bD993708Bb08E20A8E6b2439a607752BBE9f",
    [Contract.CITY_GETTERS]: null,
    [Contract.UNIVERSITIES]: null,
    [Contract.TEAM_LEADER_V]: null,
    [Contract.TEAM_LEADER]: null,
    [Contract.EMPLOYEES_TEAM]: null,
    [Contract.TEAM_BUILDER]: null,
  },
  [AppMode.TEST_POLYGON]: {
    [Contract.TOKEN]: "0x8D8190931173A3B28EF8B9C4106Faf9771Aa105c",
    [Contract.SPECIAL_TOKEN]: "",
    [Contract.EMPLOYEES]: "0x636B5665Ded4A0Ed1BbE14b2a3577AF3b783cd0f",
    [Contract.EMPLOYEES_EXPANDED]: null,
    [Contract.EMPLOYEE_DEPLOYER]: "0x479126149dbB85214b6d064C08A17C35ed1068fc",
    [Contract.BASE_DEPLOYER]: "0xF71925C5e4093E618c5bB33b88a138c000Cd4629",
    [Contract.FACTORIES]: "0x9E30a4B924Bd7E85d6C317899253a526f936E1e5",
    [Contract.FACTORY_DEPLOYER]: "0x4043C1B636e7d1f49c6D93ef7ab7d85aB2F79749",
    [Contract.MINI_EMPLOYEES]: null,
    [Contract.MINI_EMPLOYEES_D]: null,
    [Contract.MULTI_EMPLOYEE]: null,
    [Contract.MULTI_EMPLOYEE_D]: null,
    [Contract.CITIES]: null,
    [Contract.CITIES_DEPLOYER]: null,
    [Contract.CITIES_STORAGE]: null,
    [Contract.NFT_BRIDGE_STORAGE]: null,
    [Contract.CITY_GETTERS]: null,
    [Contract.UNIVERSITIES]: null,
    [Contract.TEAM_LEADER_V]: null,
    [Contract.TEAM_LEADER]: null,
    [Contract.EMPLOYEES_TEAM]: null,
    [Contract.TEAM_BUILDER]: null,
  },
  [AppMode.POLYGON]: {
    [Contract.TOKEN]: "0xF305012EA754252184f1071C86ae99fAc5B40320",
    [Contract.SPECIAL_TOKEN]: "0xF305012EA754252184f1071C86ae99fAc5B40320",
    [Contract.EMPLOYEES]: "0x67Ad4650B50bB4646E93faeCCf6b3796e8780F18",
    [Contract.EMPLOYEES_EXPANDED]: "0xaf30acc5c31CfAa12b8f1d6FbA74ac4940fED02f",
    [Contract.FACTORIES]: "0xacad20cA54E173d24735035A725A7D1ACA60D7A3",
    [Contract.MINI_EMPLOYEES]: "0x1099901774C74B0849575B621b7ddD24DD02ea3c",
    [Contract.MULTI_EMPLOYEE]: "0x6884e7657D203ccb1f1f4C4Bca59CB94A538955B",
    [Contract.BASE_DEPLOYER]: "0x0827A6a41966E0F4f60d5f7eE9d3897297CEf280",
    [Contract.EMPLOYEE_DEPLOYER]: "0x69f85DAFdEBc93E0A5fb3905914016c0Dc71F96c",
    [Contract.FACTORY_DEPLOYER]: "0xCa224bFd490d0Da1EE5eBf39F712eb41Ff87a1AE",
    [Contract.MULTI_EMPLOYEE_D]: "0x1F9E400Fe1626ABb164133Ce21962E22450b1Abe",
    [Contract.MINI_EMPLOYEES_D]: "0xBe76F4Cc6406ebD64aB818bF4EbFE8c1AD68B293",
    [Contract.CITIES]: "0x32595A1B89aBC1642e6406e3a9Bd5c31Cbc44cFe",
    [Contract.CITIES_DEPLOYER]: "0x8B224b6cce6105C34E1E3F05B7F7145D71DB1E90",
    [Contract.CITIES_STORAGE]: "0xcb4DABb3EaCaf95d9905a6065b7f3b202536dB2e",
    [Contract.NFT_BRIDGE_STORAGE]: "0x1B5349D7E065200Ae2d1f78173CEA20F15670945",
    [Contract.CITY_GETTERS]: "0x480b36427CBD175aF1e8cE43003a8Edc07f44C95",
    [Contract.UNIVERSITIES]: "0x3eFc82874C28bCb7f40E260697277d547bF2E485",
    [Contract.TEAM_LEADER_V]: "0x50c60EE650553E85626dC1d88b115d43Ce61C9a6",
    [Contract.TEAM_LEADER]: "0xE89C1be01B2eF78D42891B8726696816e630700E",
    [Contract.EMPLOYEES_TEAM]: "0xd8C59F8A72b7DAd3523DE866Bd07754a67111c94",
    [Contract.TEAM_BUILDER]: "0x5161f6a82e2FFc4584Da3CBE1B7539DFeFc6FfF0",
  },
};

export const relatedModeToChain: { [key in AppChain]: AppMode } = {
  [AppChain.BSC]: AppMode.PRO,
  [AppChain.METER]: AppMode.METER,
  [AppChain.POLYGON]: AppMode.POLYGON,
  [AppChain.BSCT]: AppMode.TEST,
  [AppChain.GANACHE]: AppMode.DEV,
  [AppChain.METER_TEST]: AppMode.TEST_METER,
  [AppChain.POLYGON_TEST]: AppMode.TEST_POLYGON,
};

export const posibleChains: PosibleChainsType = {
  [AppChain.BSC]: {
    chainId: "0x38",
    chainName: AppChain.BSC,
    nativeCurrency: {
      symbol: "BNB",
      name: "Binance",
      decimals: 18,
    },
    rpcUrls: ["https://bsc-dataseed.binance.org/"],
    blockExplorerUrls: ["https://bscscan.com"],
  },
  [AppChain.BSCT]: {
    chainId: "0x61",
    chainName: AppChain.BSCT,
    nativeCurrency: {
      symbol: "BNB",
      name: "Binance",
      decimals: 18,
    },
    rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
    blockExplorerUrls: ["https://testnet.bscscan.com"],
  },
  [AppChain.GANACHE]: {
    chainId: "0x539",
    chainName: AppChain.GANACHE,
    nativeCurrency: {
      symbol: "BNB",
      name: "Binance",
      decimals: 18,
    },
    rpcUrls: ["http://localhost:7545"],
    blockExplorerUrls: ["http://localhost:7545"],
  },
  [AppChain.METER_TEST]: {
    chainId: "0x53",
    chainName: AppChain.METER_TEST,
    nativeCurrency: {
      symbol: "MTR",
      name: "METER",
      decimals: 18,
    },
    rpcUrls: ["https://rpctest.meter.io"],
    blockExplorerUrls: ["https://scan-warringstakes.meter.io"],
  },
  [AppChain.POLYGON_TEST]: {
    chainId: "0x13881",
    chainName: AppChain.POLYGON_TEST,
    nativeCurrency: {
      symbol: "MATIC",
      name: "MATIC",
      decimals: 18,
    },
    rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
    blockExplorerUrls: ["https://mumbai.polygonscan.com"],
  },
  [AppChain.METER]: {
    chainId: "0x52",
    chainName: AppChain.METER,
    nativeCurrency: {
      symbol: "MTR",
      name: "METER",
      decimals: 18,
    },
    rpcUrls: ["https://rpc.meter.io"],
    blockExplorerUrls: ["https://scan.meter.io"],
  },
  [AppChain.POLYGON]: {
    chainId: "0x89",
    chainName: AppChain.POLYGON,
    nativeCurrency: {
      symbol: "MATIC",
      name: "MATIC",
      decimals: 18,
    },
    rpcUrls: ["https://polygon-rpc.com"],
    blockExplorerUrls: ["https://polygonscan.com"],
  },
};
