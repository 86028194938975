import BBLogo from "../../../assets/images/BB_LOGO.svg";
import { RouteComponentProps, withRouter } from "react-router";
import { useEffect } from "react";
import { Customer } from "../../../core/customer";

import "./top.styles.css";

interface TopNavigationProps {
  onToggleLeftNav: () => void;
  onLoadBlockChain: () => void;
  onLoadCustomerData: () => void;
  onToggleLoader: (force: boolean) => void;
  customer: Customer | null;
}

const UnlinkTopNavigation = (props: TopNavigationProps & RouteComponentProps) => {
  useEffect(() => {
    const routerListener = props.history.listen(() => {
      window.scrollTo(0, 0);
      props.onToggleLoader(true);
    });

    return () => {
      if (routerListener) routerListener();
    };
  }, [props, props.history]);

  return (
    <div className="ct-top-nav">
      <div className="ct-max-container">
        <div className="ct-title">
          <h1>
            <img src={BBLogo} alt="BusinessBuilders logo" />
            <span className="ct-title">
              <span>BusinessBuilders</span>
              <span>Polygon Ecosystem</span>
            </span>
          </h1>
        </div>

        <div className="ct-links">
          {props.customer?.appDataModel ? (
            <div className="ct-data">
              <p className="ct-address">{props.customer?._address}</p>
              <p className="ct-balance">{props.customer?.appDataModel.customerToken.mainTokenBalance.toFixed(1)} MATIC</p>
              <p className="ct-balance">
                <a
                  href="https://quickswap.exchange/#/swap?outputCurrency=0xF305012EA754252184f1071C86ae99fAc5B40320"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="fas fa-plus"></span>
                </a>
                {props.customer?.appDataModel.customerToken.loadTokenBalance.toFixed(1)} {props.customer.appDataModel.customerToken.loadTokenSymbol}
              </p>
            </div>
          ) : (
            <button onClick={() => (props.onLoadBlockChain ? props.onLoadBlockChain() : null)}>Connect</button>
          )}
          <div className="ct-actions">
            <span onClick={() => props.onLoadCustomerData()} className="fas fa-sync-alt"></span>
            <span onClick={() => props.onToggleLeftNav()} className="ct-left-toggler fas fa-bars" id="ct-page-left-bar-opener"></span>
            {/* <a
              href="https://docs.businessbuilders.city"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="fas fa-file-alt"></span>
            </a>
            <a
              href="https://twitter.com/NFTBBuilders"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="fab fa-twitter"></span>
            </a>
            <a
              href="https://instagram.com/businessbuildersnft"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="fab fa-instagram"></span>
            </a>
            <Link to="/">
              <span className="fas fa-home"></span>
            </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export const TopNavigation = withRouter(UnlinkTopNavigation);
