import React from "react";
import Web3 from "web3";
import { appConfig, AppErrorCode } from "../../core/app";
import { ApiHelpers } from "../../core/helpers/api";
import { ApplicationState } from "../../storage/state/app/state";
import { BlockChainState } from "../../storage/state/blockChain/state";
import { TeamLeader } from "../organisms/teamLeader";

interface RelationsProps {
  appState: ApplicationState;
  blockChain: BlockChainState;
  onLoadCustomerData: (inTheEnd: boolean) => void;
  onToggleLoader: (froce: boolean) => void;
  onSetBlockChainError: (error: AppErrorCode) => void;
}

enum FilterOption {
  ACTIVE_RELATIONS = "active-relations",
  CITIES_LOGS = "cities-logs",
  FACTORIES_LOGS = "factories-logs",
}

interface RelationState {
  relationRewards: number;
  factoriesRewards: number;
  citiesRewards: number;
  miniRewards: number;
  citiesXP: number;
  lastBlock: number;
  createdCityRelations: any[];
  removedCityRelations: any[];
  createdFactoryRelations: any[];
  removedFactoryRelations: any[];
  activeRelations: any[];
  selectedFilterOption: FilterOption;
}

export class RelationsPage extends React.Component<RelationsProps, RelationState> {
  constructor(props: RelationsProps) {
    super(props);

    this.state = {
      relationRewards: 0,
      factoriesRewards: 0,
      citiesRewards: 0,
      citiesXP: 0,
      miniRewards: 0,
      lastBlock: 0,
      selectedFilterOption: FilterOption.ACTIVE_RELATIONS,
      createdCityRelations: [],
      removedCityRelations: [],
      createdFactoryRelations: [],
      removedFactoryRelations: [],
      activeRelations: [],
    };
  }

  async componentDidMount() {
    this.props.onToggleLoader(false);
    this._getCustomerActiveRelations();
    this.getCustomerLogs();
    this._getCustomerRewards();
  }

  private _selectFilter(filter: FilterOption) {
    this.setState({ selectedFilterOption: filter }, async () => {
      this._getCustomerActiveRelations();
      this.getCustomerLogs();
    });
  }

  private async getCustomerLogs() {
    if (this.props.blockChain.controller?.selectedAccount) {
      const userLogs = await ApiHelpers.getUserLogs(this.props.blockChain.controller?.selectedAccount);

      console.log(userLogs);

      if (
        userLogs &&
        userLogs.createCityRelations &&
        userLogs.createFactoryRelations &&
        userLogs.removeCityRelations &&
        userLogs.removeFactoryRelations
      ) {
        this.setState({
          createdCityRelations: userLogs.createCityRelations.map((event: any) => {
            return { ...event, event: "CityRelation" };
          }),
          createdFactoryRelations: userLogs.createFactoryRelations.map((event: any) => {
            return { ...event, event: "FactoryAddition" };
          }),
          removedCityRelations: userLogs.removeCityRelations.map((event: any) => {
            return { ...event, event: "RemoveCityRelation" };
          }),
          removedFactoryRelations: userLogs.removeFactoryRelations.map((event: any) => {
            return { ...event, event: "RemoveFactoryAddition" };
          }),
        });
      }
    }
  }
  private async _getCustomerActiveRelations() {
    if (
      this.props.blockChain.controller?.cityRelationsStorage &&
      this.props.blockChain.controller.selectedAccount &&
      this.props.blockChain.controller.cityGetters
    ) {
      const relations = await this.props.blockChain.controller.cityRelationsStorage.getCustomerRelations(
        this.props.blockChain.controller.selectedAccount
      );

      const ownerRelations = await ApiHelpers.preloadFactoryRelations(this.props.blockChain.controller.selectedAccount, relations);

      console.log(ownerRelations);

      if (ownerRelations && Array.isArray(ownerRelations)) {
        this.setState({ activeRelations: ownerRelations });
      }
    }
  }

  private async _getCustomerRewards() {
    if (this.props.blockChain.controller?.universities) {
      const rewards = await this.props.blockChain.controller.universities.getUserRewards();

      if (rewards) {
        this.setState({
          relationRewards: Number(Web3.utils.fromWei((rewards.relationsRewards || 0).toString(), "ether")),
          factoriesRewards: Number(Web3.utils.fromWei((rewards.factoryRewards || 0).toString(), "ether")),
          citiesRewards: Number(Web3.utils.fromWei((rewards.cityRewards || 0).toString(), "ether")),
          miniRewards: Number(Web3.utils.fromWei((rewards.miniEmployeeRewards || 0).toString(), "ether")),
          citiesXP: Number(rewards.universitiesXP),
        });
      }
    }
  }

  private _onUpdateData(error: AppErrorCode | null) {
    if (error) this.props.onSetBlockChainError(error);
    this._getCustomerRewards();
    this.props.onLoadCustomerData(true);
  }

  render() {
    return (
      <React.Fragment>
        {this.props.blockChain.customer?.teamLeader ? <TeamLeader teamLeader={this.props.blockChain.customer?.teamLeader} /> : ""}
        <div className="ct-max-container ct-relations-page">
          <div className="ct-page-actions-container">
            <div className="ct-container">
              <div className="ct-data">
                <h1>Relations</h1>
                <small>Grow and earn FTB with your NFT empire.</small>
                <small>
                  <strong>Relations rewards:</strong> {this.state.relationRewards?.toFixed(1)} FTB
                </small>
                <small>
                  <strong>Factories rewards:</strong> {this.state.factoriesRewards?.toFixed(1)} FTB
                </small>
                <small>
                  <strong>Cities rewards:</strong> {this.state.citiesRewards?.toFixed(1)} FTB
                </small>
                <small>
                  <strong>Mini Employee Rewards:</strong> {this.state.miniRewards?.toFixed(1)} FTB
                </small>
              </div>
              <div className="ct-actions">
                <button
                  className="ct-main-button"
                  onClick={() => {
                    if (this.props.blockChain.controller?.universities) {
                      this.props.blockChain.controller.universities.withdrawRelationsRewards((error: AppErrorCode | null) => {
                        this._onUpdateData(error);
                      });
                    }
                  }}
                >
                  Withdraw relation rewards
                </button>
                <button
                  className="ct-main-button"
                  onClick={() => {
                    if (this.props.blockChain.controller?.universities) {
                      this.props.blockChain.controller.universities.withdrawFactoriesRewards((error: AppErrorCode | null) => {
                        this._onUpdateData(error);
                      });
                    }
                  }}
                >
                  Withdraw Factories rewards
                </button>
                <button
                  className="ct-main-button"
                  onClick={() => {
                    if (this.props.blockChain.controller?.universities) {
                      this.props.blockChain.controller.universities.withdrawCityRewards((error: AppErrorCode | null) => {
                        this._onUpdateData(error);
                      });
                    }
                  }}
                >
                  Withdraw Cities rewards
                </button>
                <button
                  className="ct-main-button"
                  onClick={() => {
                    if (this.props.blockChain.controller?.universities) {
                      this.props.blockChain.controller.universities.withdrawMiniEmployeesRewards((error: AppErrorCode | null) => {
                        this._onUpdateData(error);
                      });
                    }
                  }}
                >
                  Withdraw MiniEmployees rewards
                </button>
              </div>
            </div>
          </div>
          <div className="ct-relations-filter">
            <div className="ct-buttons">
              <button className="ct-main-button" onClick={() => this._selectFilter(FilterOption.ACTIVE_RELATIONS)}>
                Active relations
              </button>
              <button className="ct-main-button" onClick={() => this._selectFilter(FilterOption.CITIES_LOGS)}>
                City relations Logs
              </button>
              <button className="ct-main-button" onClick={() => this._selectFilter(FilterOption.FACTORIES_LOGS)}>
                Factory relations Logs
              </button>
            </div>
            {this.state.selectedFilterOption === FilterOption.ACTIVE_RELATIONS ? (
              <p>
                <strong>Active relations: </strong> If you send your employees to the factory you will be able to see your employees relations in this
                area.
              </p>
            ) : (
              ""
            )}
            {this.state.selectedFilterOption === FilterOption.CITIES_LOGS ? (
              <p>
                <strong>City relations Logs: </strong> Here you can see all the relationships between factories and cities that you have created and
                have been deleted. Note that when you remove a factory from a city by force you will have to pay an amount depending on the percentage
                of bankruptcy (Hard remove price).
              </p>
            ) : (
              ""
            )}
            {this.state.selectedFilterOption === FilterOption.FACTORIES_LOGS ? (
              <p>
                <strong>Factory relations logs: </strong> These are the records of all the employees you have sent to factories.
              </p>
            ) : (
              ""
            )}
          </div>
          {this.state.selectedFilterOption === FilterOption.ACTIVE_RELATIONS ? (
            <div className="ct-city-relations">
              <div className="ct-relations">
                {this.state.activeRelations.map((relation, index) => {
                  const individualEmployeeMultiplicator = Math.floor(relation._totalEmployees / 5);

                  const totalGeneralMultiplicator = individualEmployeeMultiplicator + relation._multiplier;

                  const realMultiplier = totalGeneralMultiplicator > relation._maxMultiplier ? relation._maxMultiplier : totalGeneralMultiplicator;

                  const temporalRewards =
                    (realMultiplier * (relation._points + relation._factoryPoints) * relation._percentage) / 100 + relation._payment;

                  const totalRewards = temporalRewards + temporalRewards * 0.05;

                  return (
                    <div className="ct-event" key={index}>
                      <div className="ct-info">
                        <p>
                          <strong>City: </strong> {relation._cityName}
                        </p>
                        <p>
                          <strong>Factory: </strong> {relation._factory}
                        </p>
                        <p>
                          <strong>Type: </strong> {relation._factoryType}
                        </p>
                        <p>
                          <strong>Payment: </strong> {Number(relation._payment).toFixed(2)} FTB
                        </p>
                        <p>
                          <strong>Total Employees: </strong> {relation._totalEmployees}
                        </p>
                        <p>
                          <strong>Points: </strong> {relation._points}
                        </p>
                        <p>
                          <strong>Percentage: </strong> {relation._percentage}%
                        </p>
                        <p>
                          <strong>Factory Multiplicator: </strong> {relation._multiplier} / 40
                        </p>
                        <p>
                          <strong>Individual Employees Multiplicator: </strong> {individualEmployeeMultiplicator} / 10
                        </p>
                        <p>
                          <strong>Individual Max Multiplicator: </strong> {relation._maxMultiplier} / 40
                        </p>
                        <p>
                          <strong>Rewards Multiplicator: {realMultiplier}</strong>
                        </p>
                        <p>
                          <strong>Rewards: </strong> {Number(totalRewards).toFixed(2)} FTB
                        </p>
                      </div>
                      <div className="ct-actions">
                        <a
                          href={appConfig.universeUrl + "?world=1&city=" + relation._city + "&factory=" + relation._factory}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span className="fas fa-plane"></span>
                        </a>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            ""
          )}
          {this.state.selectedFilterOption === FilterOption.CITIES_LOGS ? (
            <div className="ct-city-relations">
              <div className="ct-events">
                {this.state.createdCityRelations
                  .concat(this.state.removedCityRelations)
                  .sort((a, b) => Number(b._time) - Number(a._time))
                  .map((event, index) => {
                    return (
                      <div className="ct-event" key={event._time + "-" + index}>
                        <span
                          className={"fas fa-" + (event.event === "CityRelation" ? "plus-circle ct-icon-green" : "minus-circle ct-icon-red")}
                        ></span>
                        <div className="ct-header">
                          <h5>
                            {" "}
                            {event.event === "CityRelation"
                              ? "Event: Add Factory"
                              : event.event === "RemoveCityRelation"
                              ? "Event: Remove Factory"
                              : ""}
                          </h5>
                          <small>{new Date(Number(event._time) * 1000).toUTCString()}</small>
                        </div>
                        <div className="ct-info">
                          <p>
                            <strong>City:</strong> {event._city}
                          </p>
                          <p>
                            <strong>Factory:</strong> {event._factory}
                          </p>
                          {event.event === "RemoveCityRelation" ? (
                            <p>
                              <strong>Hard Payment: </strong> {event._hardPayment + " FTB"}
                            </p>
                          ) : (
                            ""
                          )}
                          {event.event === "RemoveCityRelation" ? (
                            <p>
                              <strong>Rewards: </strong>
                              {event._rewards + " FTB"}
                            </p>
                          ) : (
                            ""
                          )}
                          {event.event === "CityRelation" ? (
                            <p>
                              <strong>Payment: </strong> {event._payment + " FTB"}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : (
            ""
          )}
          {this.state.selectedFilterOption === FilterOption.FACTORIES_LOGS ? (
            <div className="ct-city-relations">
              <div className="ct-events">
                {this.state.createdFactoryRelations
                  .concat(this.state.removedFactoryRelations)
                  .sort((a, b) => Number(b._time) - Number(a._time))
                  .map((event, index) => {
                    return (
                      <div className="ct-event" key={event._time + "-" + index}>
                        <span
                          className={"fas fa-" + (event.event === "FactoryAddition" ? "plus-circle ct-icon-green" : "minus-circle ct-icon-red")}
                        ></span>
                        <div className="ct-header">
                          <h5>
                            {event.event === "FactoryAddition"
                              ? "Event: Send employees"
                              : event.event === "RemoveFactoryAddition"
                              ? "Event: Remove employees"
                              : ""}
                          </h5>
                          <small>{new Date(Number(event._time) * 1000).toUTCString()}</small>
                        </div>
                        <div className="ct-info">
                          <p>
                            <strong>City:</strong> {event._city}
                          </p>
                          <p>
                            <strong>Factory:</strong> {event._factory}
                          </p>
                          {event.event === "FactoryAddition" ? (
                            <p>
                              <strong>Entry:</strong> {event._entryPayment + " FTB"}
                            </p>
                          ) : (
                            ""
                          )}
                          {event.event === "FactoryAddition" ? (
                            <p>
                              <strong>Percentage:</strong> {event._relationPercentage}
                            </p>
                          ) : (
                            ""
                          )}
                          {event.event === "FactoryAddition" ? (
                            <p>
                              <strong>Points:</strong> Employees {event._relationPoints} + Factory {event._factoryPoints}
                            </p>
                          ) : (
                            ""
                          )}
                          {event.event === "FactoryAddition" ? (
                            <p>
                              <strong>Employees:</strong> {event._totalEmployees}
                            </p>
                          ) : (
                            ""
                          )}
                          {event.event === "FactoryAddition" ? (
                            <p>
                              <strong>Owner:</strong> {event._agregator}
                            </p>
                          ) : (
                            ""
                          )}
                          {event.event === "RemoveFactoryAddition" ? (
                            <p>
                              <strong>Rewards:</strong> {event._rewards + " FTB"}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </React.Fragment>
    );
  }
}
