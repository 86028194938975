import React from "react";
import Dropzone from "react-dropzone";
import Particles from "react-tsparticles";
import { appConfig, AppErrorCode } from "../../core/app";
import { BlockChainState } from "../../storage/state/blockChain/state";
import { ApplicationState } from "../../storage/state/app/state";
import { UtilsHelpers } from "../../core/helpers/utils";
import { TeamLeaderNFT } from "../../core/types";
import { loadFull } from "tsparticles";
import { ApiHelpers } from "../../core/helpers/api";
import { toast } from "react-toastify";
import { BlockChainHelpers } from "../../core/helpers/chain";

interface ProfileComponentProps {
  appState: ApplicationState;
  blockChain: BlockChainState;
  onLoadCustomerData: (inTheEnd: boolean) => void;
  onToggleLoader: (froce: boolean) => void;
  onSetBlockChainError: (error: AppErrorCode) => void;
}

interface ProfileComponentState {
  validNFT: boolean;
  profileName: string;
  communityLink: string;
  profileCity: string;
  imageError: string;
  teamLeader: TeamLeaderNFT | null;
  selectedImageFile: File | null;
  rewardsInfo: any[];
  selectedImageData: string | ArrayBuffer | null;
}

export class ProfilePage extends React.PureComponent<ProfileComponentProps, ProfileComponentState> {
  constructor(props: ProfileComponentProps) {
    super(props);

    this.state = {
      validNFT: true,
      teamLeader: null,
      imageError: "",
      profileName: "",
      communityLink: "",
      profileCity: "",
      rewardsInfo: [],
      selectedImageFile: null,
      selectedImageData: null,
    };
  }

  async componentDidMount() {
    this.preloadControllers();
    this.loadAndSetProfile();
    this.loadAndSetRewards();
    this.props.onToggleLoader(false);
  }

  async preloadControllers(closeLoader: boolean = false) {
    let validNFT = false;
    let teamLeader = null;

    try {
      if (this.props.blockChain.controller?.teamLeaderValidations) {
        const teamLeaderProps =
          (await this.props.blockChain.controller.teamLeaderValidations.getTeamLeaderInfo()) as TeamLeaderNFT;

        if (teamLeaderProps && !teamLeaderProps.inBlackList && teamLeaderProps.maxMultiplier) {
          validNFT = !!teamLeaderProps;
          teamLeader = teamLeaderProps;
        } else throw new Error("Invalid profile NFT");
      }
    } catch (error) {
      UtilsHelpers.debugger("[USER PROFILE] Team leader validation error.");
    }

    this.setState({ validNFT, teamLeader }, () => {
      this.props.onToggleLoader(closeLoader);
    });
  }

  async onUpdateData(error: AppErrorCode | null) {
    if (error) this.props.onSetBlockChainError(error);
    await this.preloadControllers();
    this.loadAndSetProfile();
    this.loadAndSetRewards();
    this.props.onLoadCustomerData(true);
  }

  async loadAndSetProfile() {
    if (this.props.blockChain.controller?.selectedAccount) {
      const profileData = await ApiHelpers.getProfileData(this.props.blockChain.controller.selectedAccount);

      this.setState({
        profileName: profileData._name,
        profileCity: profileData._selectedCity,
        communityLink: profileData._communityLink,
        selectedImageData: profileData._image,
      });
    }
  }

  async loadAndSetRewards() {
    if (this.props.blockChain.controller?.teamLeaderValidations && this.props.blockChain.controller.selectedAccount) {
      const rewardsInfo = await this.props.blockChain.controller?.teamLeaderValidations?.getRewardsInfo(
        this.props.blockChain.controller.selectedAccount
      );

      this.setState({ rewardsInfo });
    }
  }

  async initParticles(main: any) {
    await loadFull(main);
  }

  async dropFiles(files: File[]) {
    if (files.length === 1) {
      if (files[0].size <= 10485760) {
        const formData = new FormData();
        formData.append("images", files[0]);
        const imageUpload = await ApiHelpers.uploadImages(formData);

        if (Array.isArray(imageUpload) && imageUpload[0]) {
          this.setState({
            selectedImageData: appConfig.backendController + "/" + imageUpload[0].path,
            selectedImageFile: files[0],
          });
        } else this.setState({ imageError: "Invalid image uploading." });
      } else this.setState({ imageError: "Image files size" });
    } else this.setState({ imageError: "Image files number" });
  }

  async onSaveProfile() {
    if (this.state.profileName && this.state.selectedImageData) {
      if (this.props.blockChain.controller?.selectedAccount && this.props.blockChain.customer?.teamLeader?.teamLeader) {
        const nftID = this.props.blockChain.customer?.teamLeader?.teamLeader?.uri
          .split("?")[1]
          .split(";")
          .find((part) => part.split(":")[0] === "id")
          ?.split(":")[1];

        if (nftID) {
          const msg = {
            domain: {
              chainId: parseInt(BlockChainHelpers.getAppChain().chainId, 16),
              name: "BusinessBuilders.city",
              verifyingContract: this.props.blockChain.controller.teamLeader?.address,
              version: "1",
            },
            message: {
              id: Number(nftID),
              owner: this.props.blockChain.controller?.selectedAccount,
            },
            primaryType: "Request",
            types: {
              EIP712Domain: [
                { name: "name", type: "string" },
                { name: "version", type: "string" },
                { name: "chainId", type: "uint256" },
                {
                  name: "verifyingContract",
                  type: "address",
                },
              ],
              Request: [
                { name: "id", type: "uint256" },
                { name: "owner", type: "address" },
              ],
            },
          };

          const eth = BlockChainHelpers.getEthWindow();

          if (eth) {
            const sign = await eth.request({
              method: "eth_signTypedData_v4",
              params: [this.props.blockChain.controller.selectedAccount, JSON.stringify(msg)],
            });

            const saveProfile = await ApiHelpers.saveProfileData(
              this.state.selectedImageData.toString(),
              this.state.profileName,
              parseInt(BlockChainHelpers.getAppChain().chainId.toString(), 16).toString(),
              sign,
              this.props.blockChain.controller?.selectedAccount,
              this.state.profileCity,
              this.state.communityLink
            );

            if (saveProfile) this.loadAndSetProfile();
            else toast.error("Saving error, try later.");
          } else toast.error("Invalid ETH Provider.");
        } else toast.error("Invalid URI");
      } else toast.error("Invalid data loading.");
    } else toast.error("Fill all necessary imputs.");
  }

  render() {
    let maxXP = 0;
    let xpPercentage = 0;
    let level = 0;

    if (this.state.teamLeader) {
      maxXP =
        this.state.teamLeader?.teamLeader.leader.nextLevelXP - this.state.teamLeader?.teamLeader.leader.nextLevelXP / 2;

      xpPercentage = ((this.state.teamLeader.teamLeader.leader.xp - maxXP) * 100) / maxXP;

      level = this.state.teamLeader.teamLeader.leader.level;
    }

    return (
      <React.Fragment>
        <div className="ct-max-container ct-profile-page">
          <div className="ct-page-actions-container ct-mb-10">
            <div className="ct-container">
              <div className="ct-data">
                <h1>Team leader</h1>
                <small>Protect and control your account.</small>
                <h4 className="ct-mt-5">Information</h4>
                <small>
                  <strong>XP: </strong>
                  {this.state.teamLeader?.teamLeader.leader.xp}
                </small>
                <small>
                  <strong>Max multiplier: </strong>
                  {this.state.teamLeader?.maxMultiplier}
                </small>
                <small>
                  <strong>Level: </strong>
                  {this.state.teamLeader?.teamLeader.leader.level}
                </small>
                <small>
                  <strong>Next level XP: </strong>
                  {this.state.teamLeader?.teamLeader.leader.nextLevelXP}
                </small>
                <a
                  href="https://docs.businessbuilders.city/the-game/user-profile"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Team leader documentation
                </a>
              </div>
              <div className="ct-actions">
                {this.state.validNFT ? (
                  ""
                ) : (
                  <button
                    onClick={() => {
                      try {
                        if (this.props.blockChain.controller?.teamLeaderValidations) {
                          this.props.blockChain.controller.teamLeaderValidations.mintLeader((error) => {
                            this.onUpdateData(error);
                          });
                        } else {
                          throw new Error("[USER PROFILE] Invalid minting");
                        }
                      } catch (error) {
                        UtilsHelpers.debugger("[USER PROFILE] Invalid minting");
                      }
                    }}
                    className="ct-main-button"
                  >
                    Mint
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        {this.props.blockChain.customer?.teamLeader?.teamLeader ? (
          <div className="ct-level">
            <Particles
              id="tsparticles"
              init={(main) => this.initParticles(main)}
              style={{ width: xpPercentage + "% !important" }}
              options={{
                background: {
                  color: {
                    value: "rgba(255,255,255,0)",
                  },
                  opacity: 0,
                },
                fpsLimit: 30,
                interactivity: {
                  events: {
                    onClick: {
                      enable: false,
                      mode: "push",
                    },
                    onHover: {
                      enable: false,
                      mode: "repulse",
                    },
                    resize: false,
                  },
                  modes: {
                    push: {
                      quantity: 2,
                    },
                    repulse: {
                      distance: 200,
                      duration: 0.4,
                    },
                  },
                },
                particles: {
                  color: {
                    value: "rgb(27, 191, 27)",
                  },
                  links: {
                    enable: false,
                  },
                  collisions: {
                    enable: false,
                  },
                  move: {
                    direction: "none",
                    enable: true,
                    random: false,
                    speed: 2,
                    straight: false,
                  },
                  number: {
                    density: {
                      enable: true,
                      area: 50,
                    },
                    value: 20,
                  },
                  opacity: {
                    value: 0.4,
                  },
                  shape: {
                    type: "circle",
                  },
                  size: {
                    value: { min: 2, max: 4 },
                  },
                },
                fullScreen: false,
                detectRetina: false,
              }}
            ></Particles>
            <div
              className="ct-xp-bar"
              style={{
                width: xpPercentage + "%",
              }}
            ></div>
            <div className="ct-text">
              <strong>
                Level: {level} - {xpPercentage.toFixed(0)} %
              </strong>
            </div>
          </div>
        ) : (
          ""
        )}

        {this.props.blockChain.customer?.teamLeader?.teamLeader ? (
          <React.Fragment>
            <div className="ct-mt-20 ct-max-container ct-update-profile">
              <h3>update profile</h3>
              <div className="ct-image">
                {this.state.selectedImageData ? (
                  <div className="ct-selected-image">
                    <img src={this.state.selectedImageData.toString()} alt="" />
                    <span
                      className="fas fa-times"
                      onClick={() =>
                        this.setState({
                          selectedImageFile: null,
                          selectedImageData: null,
                        })
                      }
                    ></span>
                  </div>
                ) : (
                  <Dropzone onDrop={(acceptedFiles) => this.dropFiles(acceptedFiles)}>
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p>Click or drop your profile image</p>
                        {this.state.imageError ? <p>{this.state.imageError}</p> : ""}
                      </div>
                    )}
                  </Dropzone>
                )}
              </div>
              <div className="ct-profile">
                <div className="ct-input">
                  <label htmlFor="ct-name">Name: </label>
                  <input
                    type="text"
                    id="ct-name"
                    name="ct-name"
                    placeholder="Name"
                    onChange={(e) => this.setState({ profileName: e.target.value })}
                    value={this.state.profileName}
                  />
                </div>
                <div className="ct-input">
                  <label htmlFor="ct-link">Community Link: </label>
                  <input
                    type="text"
                    id="ct-link"
                    name="ct-link"
                    placeholder="Link URL"
                    onChange={(e) => this.setState({ communityLink: e.target.value })}
                    value={this.state.communityLink}
                  />
                </div>
                <div className="ct-input">
                  <label htmlFor="ct-city">Selected city: </label>
                  <input
                    type="text"
                    id="ct-city"
                    name="ct-city"
                    placeholder="City ID"
                    onChange={(e) => this.setState({ profileCity: e.target.value })}
                    value={this.state.profileCity}
                  />
                </div>
                <button className="ct-main-button" onClick={() => this.onSaveProfile()}>
                  Save
                </button>
              </div>
            </div>
            <p className="ct-profile-data">
              Your profile picture will be displayed in your city and on the top players' board. You can change this
              data at any time and your NFT will be visible in TofuNFT with the data you provide, normally Tofu uses a
              caching policy so the data will not be updated instantly and you may need to update your NFT metadata in
              the marketplace. We reserve the policy to remove any image if it is not related to NFTs or causes
              displeasure to the community.{" "}
            </p>
          </React.Fragment>
        ) : (
          <div className="ct-minting-wanring">
            <p>Mint your Leader to see your profile and the leaderboard.</p>
            <button
              onClick={() => {
                if (this.props.blockChain.controller?.teamLeaderValidations) {
                  this.props.blockChain.controller.teamLeaderValidations.mintLeader((error) => {
                    this.onUpdateData(error);
                  });
                }
              }}
              className="ct-main-button"
            >
              Mint NFT profile
            </button>
          </div>
        )}

        {this.props.blockChain.customer?.teamLeader?.teamLeader ? (
          <div className="ct-max-container ct-rewards">
            <h4>LEVEL REWARDS</h4>
            <div className="ct-all-rewards">
              {this.state.rewardsInfo.length > 0
                ? this.state.rewardsInfo.map((reward, index) => {
                    return (
                      <div className="ct-reward" key={index}>
                        <div className="ct-header">
                          <h4>
                            REWARD (Level: {reward.rewards.level} - {reward.canRequest ? "Enabled" : "Disabled"})
                          </h4>
                          <button
                            disabled={!reward.canRequest}
                            className="ct-main-button"
                            onClick={() => {
                              if (this.props.blockChain.controller?.teamLeaderValidations) {
                                this.props.blockChain.controller.teamLeaderValidations.requestRewards(
                                  index,
                                  (error) => {
                                    this.onUpdateData(error);
                                  }
                                );
                              }
                            }}
                          >
                            request
                          </button>
                        </div>
                        <div className="ct-prizes">
                          {reward.rewards.employee.points > 0 ? (
                            <div className="ct-employee">
                              <img
                                src={
                                  "http://art.businessbuilders.city/employees?a=head:" +
                                  reward.rewards.employee.head +
                                  ";body:" +
                                  reward.rewards.employee.body +
                                  ";legs:" +
                                  reward.rewards.employee.legs +
                                  ";hands:" +
                                  reward.rewards.employee.hands +
                                  ";points:" +
                                  reward.rewards.employee.points +
                                  ";id:1&onlyImage=true"
                                }
                                alt=""
                              />
                              <div className="ct-prize-info">
                                <h5>Employee</h5>
                                <span>
                                  <strong>Head: </strong>{" "}
                                  {UtilsHelpers.getTypeName(Number(reward.rewards.employee.head))}
                                </span>
                                <span>
                                  <strong>Body: </strong>{" "}
                                  {UtilsHelpers.getTypeName(Number(reward.rewards.employee.body))}
                                </span>
                                <span>
                                  <strong>Legs: </strong>{" "}
                                  {UtilsHelpers.getTypeName(Number(reward.rewards.employee.legs))}
                                </span>
                                <span>
                                  <strong>Hands: </strong>{" "}
                                  {UtilsHelpers.getTypeName(Number(reward.rewards.employee.hands))}
                                </span>
                                <span>
                                  <strong>Points: </strong> {reward.rewards.employee.points}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div className="ct-null">
                              <span className="fas fa-times"></span>
                            </div>
                          )}
                          {reward.rewards.miniEmployee.points > 0 ? (
                            <div className="ct-mini-employee">
                              <img
                                src={
                                  "http://art.businessbuilders.city/mini?a=head:" +
                                  reward.rewards.miniEmployee.head +
                                  ";body:" +
                                  reward.rewards.miniEmployee.body +
                                  ";legs:" +
                                  reward.rewards.miniEmployee.legs +
                                  ";hands:" +
                                  reward.rewards.miniEmployee.hands +
                                  ";points:" +
                                  reward.rewards.miniEmployee.points +
                                  ";net:" +
                                  reward.rewards.miniEmployee.net +
                                  ";id:1&onlyImage=true"
                                }
                                alt=""
                              />
                              <div className="ct-prize-info">
                                <h5>Mini Employee</h5>
                                <span>
                                  <strong>Head: </strong>{" "}
                                  {UtilsHelpers.getTypeName(Number(reward.rewards.miniEmployee.head))}
                                </span>
                                <span>
                                  <strong>Body: </strong>{" "}
                                  {UtilsHelpers.getTypeName(Number(reward.rewards.miniEmployee.body))}
                                </span>
                                <span>
                                  <strong>Legs: </strong>{" "}
                                  {UtilsHelpers.getTypeName(Number(reward.rewards.miniEmployee.legs))}
                                </span>
                                <span>
                                  <strong>Hands: </strong>{" "}
                                  {UtilsHelpers.getTypeName(Number(reward.rewards.miniEmployee.hands))}
                                </span>
                                <span>
                                  <strong>Points: </strong> {reward.rewards.miniEmployee.points}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div className="ct-null">
                              <span className="fas fa-times"></span>
                            </div>
                          )}
                          {reward.rewards.factory.points > 0 ? (
                            <div className="ct-factory">
                              <img
                                src={
                                  "http://art.businessbuilders.city/factories?a=build:" +
                                  reward.rewards.factory.build +
                                  ";model:" +
                                  reward.rewards.factory.model +
                                  ";points:" +
                                  reward.rewards.factory.points +
                                  ";id:1&onlyImage=true"
                                }
                                alt=""
                              />
                              <div className="ct-prize-info">
                                <h5>Factory</h5>
                                <span>
                                  <strong>Type: </strong>{" "}
                                  {UtilsHelpers.getTypeName(Number(reward.rewards.factory.build))}
                                </span>
                                <span>
                                  <strong>Model: </strong> {reward.rewards.factory.model}
                                </span>
                                <span>
                                  <strong>Points: </strong> {reward.rewards.factory.points}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div className="ct-null">
                              <span className="fas fa-times"></span>
                            </div>
                          )}
                          {reward.rewards.multiEmployee.points > 0 ? (
                            <div className="ct-multi-employee">
                              <img
                                src={
                                  "http://art.businessbuilders.city/employees?a=head:" +
                                  reward.rewards.multiEmployee.head +
                                  ";body:" +
                                  reward.rewards.multiEmployee.body +
                                  ";legs:" +
                                  reward.rewards.multiEmployee.legs +
                                  ";hands:" +
                                  reward.rewards.multiEmployee.hands +
                                  ";points:" +
                                  reward.rewards.multiEmployee.points +
                                  ";id:1&onlyImage=true"
                                }
                                alt=""
                              />
                              <div className="ct-prize-info">
                                <h5>Multi Employee</h5>
                                <span>
                                  <strong>Head: </strong>{" "}
                                  {UtilsHelpers.getTypeName(Number(reward.rewards.multiEmployee.head))}
                                </span>
                                <span>
                                  <strong>Body: </strong>{" "}
                                  {UtilsHelpers.getTypeName(Number(reward.rewards.multiEmployee.body))}
                                </span>
                                <span>
                                  <strong>Legs: </strong>{" "}
                                  {UtilsHelpers.getTypeName(Number(reward.rewards.multiEmployee.legs))}
                                </span>
                                <span>
                                  <strong>Hands: </strong>{" "}
                                  {UtilsHelpers.getTypeName(Number(reward.rewards.multiEmployee.hands))}
                                </span>
                                <span>
                                  <strong>Points: </strong> {reward.rewards.multiEmployee.points}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div className="ct-null">
                              <span className="fas fa-times"></span>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })
                : ""}
            </div>
          </div>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}
