import { AppErrorCode } from "../app";
import { Employees } from "../contracts/employees";
import { EmployeesExpanded } from "../contracts/employeesExpanded";
import { MiniEmployeeDeployer } from "../contracts/miniEmployeeDeployer";
import { MiniEmployees } from "../contracts/miniEmployees";
import { MultiEmployees } from "../contracts/multiEmployees";
import { UtilsHelpers } from "../helpers/utils";
import { AppData } from "../types";
import { ApiHelpers } from "../helpers/api";
import { EmployeeNFT } from "../nfts/employee";
import { toast } from "react-toastify";
import { NFTBridgeStorage } from "../contracts/nftBridgeStorage";
import { EmployeesTeam } from "../contracts/employeesTeam";
import { EmployeesTeamNFT } from "../nfts/teams";

interface EmployeeBasisData {
  _id: number;
  _body: number;
  _head: number;
  _legs: number;
  _merges: number;
  _network: string;
  _nftID: number;
  _points: number;
  _hands: number;
  _xp: number;
}

interface EmployeesTeamBasisData {
  package: Number;
  totalXP: Number;
  stakedToken: Number;
  totalEmployees: Number;
  totalSpecial: Number;
  totalNormal: Number;
  totalPoints: Number;
  typesPart: Number[];
  isMini: Boolean;
  uri: String;
}

export class EmployeesController {
  public employeesData: EmployeeNFT[] = [];
  public miniEmployeesData: EmployeeNFT[] = [];
  public teamsData: EmployeesTeamNFT[] = [];
  public stakedEmployees: number[] = [];
  public customerEmployees: number[] = [];
  public multiCustomerEmployees: number[] = [];
  public miniCustomerEmployees: number[] = [];
  public customerTeams: number[] = [];
  public inJobCounter: number = 0;

  constructor(
    public contract: Employees,
    public employeesExpanded: EmployeesExpanded,
    public miniEmployees: MiniEmployees,
    public miniEmployeesDeployer: MiniEmployeeDeployer,
    public multiEmployees: MultiEmployees,
    public employeeTeams: EmployeesTeam,
    public bridgeStorage: NFTBridgeStorage,
    public appData: AppData
  ) {}

  private parseChainEmployeeData(data: any) {
    return {
      head: data[0]["head"],
      body: data[0]["body"],
      legs: data[0]["legs"],
      hands: data[0]["hands"],
      points: data[0]["points"],
      uri: data[1],
    };
  }

  private parseChainMiniEmployeeData(data: any) {
    return {
      head: data[0]["employee"]["head"],
      body: data[0]["employee"]["body"],
      legs: data[0]["employee"]["legs"],
      hands: data[0]["employee"]["hands"],
      points: data[0]["employee"]["points"],
      xp: data[0]["employee"]["xp"],
      uri: data[0][1],
    };
  }

  private parseDatabaseEmployeeData(data: any) {
    return {
      head: data._head,
      body: data._body,
      legs: data._legs,
      hands: data._hands,
      points: data._points,
      uri: data._uri,
      xp: data._xp,
      merges: data._merges,
    };
  }

  private parseDatabaseMultiEmployeeData(data: any) {
    return {
      head: data._head,
      body: data._head,
      legs: data._head,
      hands: data._head,
      points: data._points,
      uri: data._uri,
      xp: data._xp,
      merges: data._merges,
    };
  }

  private parseDatabaseEmployeesTeamData(data: any) {
    return {
      package: data._package,
      totalXP: data._totalXP,
      stakedToken: data._stakedToken,
      totalEmployees: data._totalEmployees,
      totalSpecial: data._totalSpecial,
      totalNormal: data._totalEmployees - data._totalSpecial,
      totalPoints: data._totalPoints,
      typesPart: data._typesParts,
      isMini: data._isMini,
      uri: data._uri,
    };
  }

  public async loadEmployee(id: number, employeeData: EmployeeBasisData | null = null) {
    const employee =
      employeeData === null ? this.parseChainEmployeeData(await this.contract.getEmployee(id)) : this.parseDatabaseEmployeeData(employeeData);

    this.customerEmployees.push(id);

    this.employeesData.push(
      EmployeeNFT.createWithContractTemplate({
        ...employee,
        id,
        appData: this.appData,
      })
    );
  }

  public async loadMultiEmployee(id: number, employeeData: EmployeeBasisData | null = null) {
    const employee =
      employeeData === null
        ? this.parseChainEmployeeData(await this.multiEmployees.getEmployee(id))
        : this.parseDatabaseMultiEmployeeData(employeeData);

    this.multiCustomerEmployees.push(id);

    this.employeesData.push(
      EmployeeNFT.createWithContractTemplate({
        ...employee,
        id,
        appData: this.appData,
        isMultiEmployee: true,
      })
    );
  }

  public async loadEmployeesTeam(id: number, teamData: EmployeesTeamBasisData | null = null) {
    const team = teamData === null ? null : this.parseDatabaseEmployeesTeamData(teamData);

    this.customerTeams.push(id);

    this.teamsData.push(
      EmployeesTeamNFT.createWithContractTemplate({
        ...team,
        id,
        appData: this.appData,
      })
    );
  }

  public async loadMiniEmployee(id: number, employeeData: EmployeeBasisData | null = null) {
    const employee =
      employeeData === null
        ? this.parseChainMiniEmployeeData(await this.miniEmployeesDeployer.getMiniEmployeeExpanded(id))
        : this.parseDatabaseEmployeeData(employeeData);

    this.miniEmployeesData.push(
      EmployeeNFT.createMiniWithContractTemplate({
        ...employee,
        id,
        appData: this.appData,
        isMiniEmployee: true,
      })
    );
  }

  public async loadFullEmployeesData(withoutMulti: boolean = false) {
    this.employeesData = [];
    this.customerEmployees = [];

    if (!withoutMulti) {
      const multiEmployees = await this.multiEmployees.getCustomerEmployees();

      if (Array.isArray(multiEmployees)) {
        let preloadedMultiEmployees = await ApiHelpers.preloadEmployees(this.multiEmployees.address, multiEmployees);

        if (preloadedMultiEmployees && Array.isArray(preloadedMultiEmployees)) {
          preloadedMultiEmployees = preloadedMultiEmployees.filter(
            (nft: any, index: number) => preloadedMultiEmployees.findIndex((searched: any) => searched._nftID === nft._nftID) === index
          );

          for (let i = 0; i < preloadedMultiEmployees.length; i++) {
            await this.loadMultiEmployee(Number(preloadedMultiEmployees[i]._nftID), preloadedMultiEmployees[i]);
          }
        }
      }
    }

    const employees = await this.contract.getCustomerEmployees();

    if (Array.isArray(employees)) {
      let preloadedEmployees = await ApiHelpers.preloadEmployees(this.contract.address, employees);

      if (preloadedEmployees && Array.isArray(preloadedEmployees)) {
        preloadedEmployees = preloadedEmployees.filter(
          (nft: any, index: number) => preloadedEmployees.findIndex((searched: any) => searched._nftID === nft._nftID) === index
        );

        for (let i = 0; i < preloadedEmployees.length; i++) {
          await this.loadEmployee(Number(preloadedEmployees[i]._nftID), preloadedEmployees[i]);
        }
      }
    }
  }

  public async loadSpecificEmployeesData(employees: EmployeeBasisData[], isMiniEmployee: boolean, isMultiEmployee: boolean) {
    this.employeesData = [];
    this.customerEmployees = [];

    if (Array.isArray(employees)) {
      let loadedEmployees = employees.filter(
        (nft: EmployeeBasisData, index: number) => employees.findIndex((searched: EmployeeBasisData) => searched._id === nft._id) === index
      );

      for (let i = 0; i < loadedEmployees.length; i++) {
        if (isMiniEmployee) {
          await this.loadMiniEmployee(Number(loadedEmployees[i]._nftID), loadedEmployees[i]);
        } else if (isMultiEmployee) {
          await this.loadMultiEmployee(Number(loadedEmployees[i]._nftID), loadedEmployees[i]);
        } else {
          await this.loadEmployee(Number(loadedEmployees[i]._nftID), loadedEmployees[i]);
        }
      }
    }
  }

  public async loadMiniEmployeesData() {
    this.miniEmployeesData = [];
    this.miniCustomerEmployees = [];

    try {
      const customerEmployees = await this.miniEmployees.getCustomerEmployees();

      if (Array.isArray(customerEmployees)) {
        let preloadedEmployees = await ApiHelpers.preloadEmployees(this.miniEmployees.address, customerEmployees);

        if (preloadedEmployees && Array.isArray(preloadedEmployees)) {
          preloadedEmployees = preloadedEmployees.filter(
            (nft: any, index: number) => preloadedEmployees.findIndex((searched: any) => searched._nftID === nft._nftID) === index
          );

          for (let i = 0; i < preloadedEmployees.length; i++) {
            await this.loadMiniEmployee(Number(preloadedEmployees[i]._nftID), preloadedEmployees[i]);
          }
        }
      }
    } catch (error) {
      toast.success("Loading error.");
    }
  }

  public async loadMultiEmployeesData() {
    this.multiCustomerEmployees = [];

    const multiEmployees = await this.multiEmployees.getCustomerEmployees();

    if (Array.isArray(multiEmployees)) {
      let preloadedMultiEmployees = await ApiHelpers.preloadEmployees(this.multiEmployees.address, multiEmployees);

      if (preloadedMultiEmployees && Array.isArray(preloadedMultiEmployees)) {
        preloadedMultiEmployees = preloadedMultiEmployees.filter(
          (nft: any, index: number) => preloadedMultiEmployees.findIndex((searched: any) => searched._nftID === nft._nftID) === index
        );

        for (let i = 0; i < preloadedMultiEmployees.length; i++) {
          await this.loadMultiEmployee(Number(preloadedMultiEmployees[i]._nftID), preloadedMultiEmployees[i]);
        }
      }
    }
  }

  public async loadTeamsData() {
    this.customerTeams = [];

    const teams = await this.employeeTeams.getCustomerTeams();

    console.log(teams);

    if (Array.isArray(teams)) {
      let preloadedTeams = await ApiHelpers.preloadEmployees(this.employeeTeams.address, teams);

      if (preloadedTeams && Array.isArray(preloadedTeams)) {
        preloadedTeams = preloadedTeams.filter(
          (nft: any, index: number) => preloadedTeams.findIndex((searched: any) => searched._nftID === nft._nftID) === index
        );

        for (let i = 0; i < preloadedTeams.length; i++) {
          await this.loadEmployeesTeam(Number(preloadedTeams[i]._nftID), preloadedTeams[i]);
        }
      }
    }
  }

  public approveEmployee(to: string, employee: number, multi: boolean, mini: boolean, callback?: (error: AppErrorCode | null) => void) {
    UtilsHelpers.debugger("Approve employee to" + to);

    UtilsHelpers.debugger("Try to to approve token spend (" + this.contract.selectedAccount + ")");

    if (callback) {
      if (multi) {
        this.multiEmployees?.approve(to, employee, callback);
      } else if (mini) {
        this.miniEmployees?.approve(to, employee, callback);
      } else this.contract?.approve(to, employee, callback);
    }
  }

  async getApproved(id: number, multi: boolean, mini: boolean): Promise<string> {
    if (multi) return this.multiEmployees?.getApproved(id);
    else if (mini) return this.miniEmployees?.getApproved(id);
    else return this.contract?.getApproved(id);
  }
}
