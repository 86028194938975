import Web3 from "web3";
import EventEmitter from "events";

import { NavWindow } from "../components/types";
import { BlockChainState } from "../storage/state/blockChain/state";
import { ContractsState } from "../storage/state/contracts/state";
import { ABINetworkData, ContractData } from "./contracts";
import { BlockChainHelpers } from "./helpers/chain";
import {
  appConfig,
  AppErrorCode,
  Contract,
  posibleContractAddress,
  ULTRA_ERRORS,
} from "./app";
import { UtilsHelpers } from "./helpers/utils";
import { Token } from "./contracts/token";
import { Employees } from "./contracts/employees";
import { Marketplace } from "./contracts/marketplace";
import { EmployeeDeployer } from "./contracts/employeeDeployer";
import { BaseDeployer } from "./contracts/baseDeployer";
import { Factories } from "./contracts/factories";
import { FactoryDeployer } from "./contracts/factoryDeployer";
import { MiniEmployees } from "./contracts/miniEmployees";
import { MiniEmployeeDeployer } from "./contracts/miniEmployeeDeployer";
import { MultiEmployees } from "./contracts/multiEmployees";
import { MultiEmployeeDeployer } from "./contracts/multiEmployeeDeployer";
import { EmployeesExpanded } from "./contracts/employeesExpanded";
import { NFTBridgeStorage } from "./contracts/nftBridgeStorage";
import { Cities } from "./contracts/cities";
import { CitiesDeployer } from "./contracts/citiesDeployer";
import { CityRelationsStorage } from "./contracts/cityRelationsStorage";
import { CityUniversities } from "./contracts/cityUniversities";
import { VoltLPRewards } from "./contracts/voltLPRewards";
import { VoltRewards } from "./contracts/voltRewards";
import { TeamLeaderValidations } from "./contracts/teamLeaderValidations";
import { CityGetters } from "./contracts/cityGetters";
import { AbstractTeamBuilder } from "./abstracts/teamBuilder";
import { TeamBuilder } from "./contracts/teamBuilder";
import { EmployeesTeam } from "./contracts/employeesTeam";

export interface BlockChainData {
  accounts: string[];
  chainId: number | null;
}

export interface RelatedContracts {
  [Contract.TOKEN]: any;
  [Contract.SPECIAL_TOKEN]: any;
  [Contract.EMPLOYEES]: any;
  [Contract.EMPLOYEE_DEPLOYER]: any;
  [Contract.BASE_DEPLOYER]: any;
  [Contract.FACTORIES]: any;
  [Contract.FACTORY_DEPLOYER]: any;
  [Contract.MINI_EMPLOYEES]: any;
  [Contract.MINI_EMPLOYEES_D]: any;
  [Contract.MULTI_EMPLOYEE_D]: any;
  [Contract.MULTI_EMPLOYEE]: any;
  [Contract.EMPLOYEES_EXPANDED]: any;
  [Contract.CITIES]: any;
  [Contract.CITIES_DEPLOYER]: any;
  [Contract.CITIES_STORAGE]: any;
  [Contract.NFT_BRIDGE_STORAGE]: any;
  [Contract.UNIVERSITIES]: any;
  [Contract.CITY_GETTERS]: any;
  [Contract.TEAM_LEADER_V]: any;
  [Contract.TEAM_LEADER]: any;
  [Contract.TEAM_BUILDER]: any;
  [Contract.EMPLOYEES_TEAM]: any;
}

export interface RelatedContractsInstances {
  [Contract.TOKEN]: Token | null;
  [Contract.SPECIAL_TOKEN]: Token | null;
  [Contract.EMPLOYEES]: Employees | null;
  [Contract.EMPLOYEE_DEPLOYER]: EmployeeDeployer | null;
  [Contract.BASE_DEPLOYER]: BaseDeployer | null;
  [Contract.FACTORIES]: Factories | null;
  [Contract.FACTORY_DEPLOYER]: FactoryDeployer | null;
  [Contract.MULTI_EMPLOYEE]: MultiEmployees | null;
  [Contract.MULTI_EMPLOYEE_D]: MultiEmployeeDeployer | null;
  [Contract.MINI_EMPLOYEES]: MiniEmployees | null;
  [Contract.MINI_EMPLOYEES_D]: MiniEmployeeDeployer | null;
  [Contract.EMPLOYEES_EXPANDED]: EmployeesExpanded | null;
  [Contract.CITIES]: Cities | null;
  [Contract.CITIES_DEPLOYER]: CitiesDeployer | null;
  [Contract.NFT_BRIDGE_STORAGE]: NFTBridgeStorage | null;
  [Contract.NFT_BRIDGE_STORAGE]: NFTBridgeStorage | null;
  [Contract.CITIES_STORAGE]: CityRelationsStorage | null;
  [Contract.UNIVERSITIES]: CityUniversities | null;
  [Contract.CITY_GETTERS]: CityGetters | null;
  [Contract.TEAM_LEADER_V]: TeamLeaderValidations | null;
  [Contract.TEAM_LEADER]: MiniEmployees | null;
  [Contract.TEAM_BUILDER]: TeamBuilder | null;
  [Contract.EMPLOYEES_TEAM]: EmployeesTeam | null;
}

export enum BlockChainEvent {
  LOAD_CONTRACT = "load-contract",
  LOAD_CONTRACT_ERROR = "load-contract-error",
  END_CONTRACT_LOADING = "end-contract-loading",
  CHANGE_NETWORK = "change-network",
}

export enum BlockChainErrorEvent {}

export class SmartContract {}

export class BlockChain {
  private _accounts: string[] = [];
  private _provider: Web3 | null = null;
  private _chainId: number | null = null;
  private _contractsData: ContractsState | null = null;
  private _contractKeys: string[] = [];
  private _web3: Web3 | null = null;

  public buildTypes: string[] | null = null;
  public buildModels: string[] | null = null;

  private _contracts: RelatedContracts = {
    [Contract.TOKEN]: null,
    [Contract.SPECIAL_TOKEN]: null,
    [Contract.EMPLOYEES]: null,
    [Contract.EMPLOYEE_DEPLOYER]: null,
    [Contract.BASE_DEPLOYER]: null,
    [Contract.FACTORY_DEPLOYER]: null,
    [Contract.FACTORIES]: null,
    [Contract.MULTI_EMPLOYEE]: null,
    [Contract.MULTI_EMPLOYEE_D]: null,
    [Contract.MINI_EMPLOYEES]: null,
    [Contract.MINI_EMPLOYEES_D]: null,
    [Contract.EMPLOYEES_EXPANDED]: null,
    [Contract.NFT_BRIDGE_STORAGE]: null,
    [Contract.CITIES]: null,
    [Contract.CITIES_DEPLOYER]: null,
    [Contract.CITIES_DEPLOYER]: null,
    [Contract.CITIES_STORAGE]: null,
    [Contract.UNIVERSITIES]: null,
    [Contract.CITY_GETTERS]: null,
    [Contract.TEAM_LEADER_V]: null,
    [Contract.TEAM_LEADER]: null,
    [Contract.TEAM_BUILDER]: null,
    [Contract.EMPLOYEES_TEAM]: null,
  };

  private _contractsInstance: RelatedContractsInstances = {
    [Contract.TOKEN]: null,
    [Contract.SPECIAL_TOKEN]: null,
    [Contract.EMPLOYEES]: null,
    [Contract.EMPLOYEE_DEPLOYER]: null,
    [Contract.BASE_DEPLOYER]: null,
    [Contract.FACTORY_DEPLOYER]: null,
    [Contract.FACTORIES]: null,
    [Contract.MULTI_EMPLOYEE]: null,
    [Contract.MULTI_EMPLOYEE_D]: null,
    [Contract.MINI_EMPLOYEES]: null,
    [Contract.MINI_EMPLOYEES_D]: null,
    [Contract.EMPLOYEES_EXPANDED]: null,
    [Contract.NFT_BRIDGE_STORAGE]: null,
    [Contract.CITIES]: null,
    [Contract.CITIES_DEPLOYER]: null,
    [Contract.CITIES_STORAGE]: null,
    [Contract.UNIVERSITIES]: null,
    [Contract.CITY_GETTERS]: null,
    [Contract.TEAM_LEADER_V]: null,
    [Contract.TEAM_LEADER]: null,
    [Contract.TEAM_BUILDER]: null,
    [Contract.EMPLOYEES_TEAM]: null,
  };

  // Subscriptions
  principalListener: EventEmitter = new EventEmitter();

  /* -------------------------------------------------------------------------- */
  /*                           ANCHOR Contract Loading                          */
  /* -------------------------------------------------------------------------- */

  async loadBlockChainData(
    contractsData: ContractsState,
    callback?: (err: AppErrorCode | null, blockChain?: BlockChain) => void
  ) {
    if (this._contractsStateIsValid(contractsData)) {
      if (await BlockChainHelpers.loadWeb3()) {
        this._provider = BlockChainHelpers.getProvider();
        UtilsHelpers.debugger("Web3 is loaded.");

        if (!!this._provider) {
          UtilsHelpers.debugger("Provider is loaded.");

          let error: AppErrorCode | null = null;

          this._contractsData = contractsData;
          this._accounts = await this._provider.eth.requestAccounts();
          this._chainId = await this._provider.eth.getChainId();
          this._web3 = (window as NavWindow).web3 as Web3;
          this._contractKeys = Object.keys(this._contractsData);

          UtilsHelpers.debugger(
            "BlockChain Connection\n" +
              "   Selected Account: " +
              this.selectedAccount +
              "\n   Chain ID: " +
              this._chainId
          );

          // Load all contracts

          if (this._contractKeys.length > 0) {
            for (let i = 0; i < this._contractKeys.length; i++) {
              let contractName: Contract = this._contractKeys[i] as Contract;

              UtilsHelpers.debugger(
                "Search contract data (" + contractName + ")."
              );

              if (this._contractsData[contractName]) {
                UtilsHelpers.debugger(
                  "Loading contract (" + this._contractKeys[i] + ")"
                );

                let contractData =
                  this._contractsData[this._contractKeys[i] as Contract];

                if (contractData) {
                  let contractLoading: AppErrorCode | null =
                    await this._loadContract(contractData);

                  if (contractLoading !== null) {
                    error = contractLoading;

                    UtilsHelpers.debugger(
                      "Contract cannot be loading (" + error + ")."
                    );

                    break;
                  } else {
                    UtilsHelpers.debugger(
                      "Contract is loaded (" + contractData.contract + ")"
                    );
                  }
                } else UtilsHelpers.debugger("Contract data is not valid.");
              }
            }
          } else {
            UtilsHelpers.debugger("There are no contracts to load.");
          }

          if (error === null) {
            this.principalListener.emit(BlockChainEvent.END_CONTRACT_LOADING);
          }

          if (callback) callback(error, this);
        } else {
          UtilsHelpers.debugger("Invalid provider");
          if (callback) callback(AppErrorCode.INVALID_PROVIDER, this);
        }
      } else {
        UtilsHelpers.debugger("Web3 can not be loaded.");
        if (callback) callback(AppErrorCode.INVALID_PROVIDER);
      }
    } else {
      UtilsHelpers.debugger("Contracts state is not valid..");
      if (callback) callback(AppErrorCode.INVALID_CONTRACT_LOADING);
    }

    return this;
  }

  private async _loadContract(
    contractData: ContractData
  ): Promise<AppErrorCode | null> {
    let error: AppErrorCode | null = AppErrorCode.INVALID_CONTRACT_LOADING;

    const modeContracts = posibleContractAddress[appConfig.mode];

    const contractsAddress = modeContracts
      ? modeContracts[contractData.contract]
      : null;

    if (this._contractDataIsValid(contractData)) {
      UtilsHelpers.debugger("Contract data is valid.");

      if (
        modeContracts === null ||
        (modeContracts !== null && !contractsAddress)
      ) {
        UtilsHelpers.debugger("Use data network");
        let network = await this._networkIsValidInContract(contractData);

        if (network && this._web3) {
          this._contracts[contractData.contract] =
            (await new this._web3.eth.Contract(
              contractData.data?.abi,
              network.address
            )) as any;

          UtilsHelpers.debugger(
            "Load contract (" +
              contractData.contract +
              " - " +
              network.address +
              ")"
          );

          if (!!this._contracts[contractData.contract]) {
            this._loadContractInstance(
              this._contracts[contractData.contract],
              contractData.contract
            );
            this.principalListener.emit(
              BlockChainEvent.LOAD_CONTRACT,
              contractData.contract
            );
            error = null;
          }
        } else {
          UtilsHelpers.debugger("You are in incorrect network.");
          this.principalListener.emit(
            AppErrorCode.INCORRECT_BLOCKCHAIN_NETWORK,
            null
          );
          error = AppErrorCode.INCORRECT_BLOCKCHAIN_NETWORK;
        }
      } else if (contractsAddress) {
        UtilsHelpers.debugger("Use static network.");

        if (this._web3) {
          this._contracts[contractData.contract] =
            (await new this._web3.eth.Contract(
              contractData.data?.abi,
              contractsAddress
            )) as any;

          UtilsHelpers.debugger(
            "Load contract (" +
              contractData.contract +
              " - " +
              contractsAddress +
              ")"
          );

          if (!!this._contracts[contractData.contract]) {
            this._loadContractInstance(
              this._contracts[contractData.contract],
              contractData.contract
            );
            this.principalListener.emit(
              BlockChainEvent.LOAD_CONTRACT,
              contractData.contract
            );
            error = null;
          }
        } else {
          UtilsHelpers.debugger("You are in incorrect network.");
          this.principalListener.emit(
            AppErrorCode.INCORRECT_BLOCKCHAIN_NETWORK,
            null
          );
          error = AppErrorCode.INCORRECT_BLOCKCHAIN_NETWORK;
        }
      }
    }

    return error;
  }

  private _loadContractInstance(relatedContract: any, contractName: Contract) {
    if (this._web3 && this.selectedAccount) {
      switch (contractName) {
        case Contract.TOKEN:
          this._contractsInstance[contractName] = new Token(
            relatedContract,
            this._web3,
            this.selectedAccount
          );
          break;
        case Contract.SPECIAL_TOKEN:
          this._contractsInstance[contractName] = new Token(
            relatedContract,
            this._web3,
            this.selectedAccount
          );
          break;
        case Contract.EMPLOYEES:
          this._contractsInstance[contractName] = new Employees(
            relatedContract,
            this._web3,
            this.selectedAccount
          );
          break;
        case Contract.EMPLOYEES_EXPANDED:
          this._contractsInstance[contractName] = new EmployeesExpanded(
            relatedContract,
            this._web3,
            this.selectedAccount
          );
          break;
        case Contract.EMPLOYEE_DEPLOYER:
          this._contractsInstance[contractName] = new EmployeeDeployer(
            relatedContract,
            this._web3,
            this.selectedAccount
          );
          break;
        case Contract.BASE_DEPLOYER:
          this._contractsInstance[contractName] = new BaseDeployer(
            relatedContract,
            this._web3,
            this.selectedAccount
          );
          break;
        case Contract.FACTORIES:
          this._contractsInstance[contractName] = new Factories(
            relatedContract,
            this._web3,
            this.selectedAccount
          );
          break;
        case Contract.FACTORY_DEPLOYER:
          this._contractsInstance[contractName] = new FactoryDeployer(
            relatedContract,
            this._web3,
            this.selectedAccount
          );
          break;
        case Contract.MULTI_EMPLOYEE:
          this._contractsInstance[contractName] = new MultiEmployees(
            relatedContract,
            this._web3,
            this.selectedAccount
          );
          break;
        case Contract.MULTI_EMPLOYEE_D:
          this._contractsInstance[contractName] = new MultiEmployeeDeployer(
            relatedContract,
            this._web3,
            this.selectedAccount
          );
          break;
        case Contract.MINI_EMPLOYEES:
          this._contractsInstance[contractName] = new MiniEmployees(
            relatedContract,
            this._web3,
            this.selectedAccount
          );
          break;
        case Contract.MINI_EMPLOYEES_D:
          this._contractsInstance[contractName] = new MiniEmployeeDeployer(
            relatedContract,
            this._web3,
            this.selectedAccount
          );
          break;
        case Contract.NFT_BRIDGE_STORAGE:
          this._contractsInstance[contractName] = new NFTBridgeStorage(
            relatedContract,
            this._web3,
            this.selectedAccount
          );
          break;
        case Contract.CITIES:
          this._contractsInstance[contractName] = new Cities(
            relatedContract,
            this._web3,
            this.selectedAccount
          );
          break;
        case Contract.CITIES_DEPLOYER:
          this._contractsInstance[contractName] = new CitiesDeployer(
            relatedContract,
            this._web3,
            this.selectedAccount
          );
          break;
        case Contract.CITIES_STORAGE:
          this._contractsInstance[contractName] = new CityRelationsStorage(
            relatedContract,
            this._web3,
            this.selectedAccount
          );
          break;
        case Contract.UNIVERSITIES:
          this._contractsInstance[contractName] = new CityUniversities(
            relatedContract,
            this._web3,
            this.selectedAccount
          );
          break;
        case Contract.CITY_GETTERS:
          this._contractsInstance[contractName] = new CityGetters(
            relatedContract,
            this._web3,
            this.selectedAccount
          );
          break;
        case Contract.TEAM_LEADER_V:
          this._contractsInstance[contractName] = new TeamLeaderValidations(
            relatedContract,
            this._web3,
            this.selectedAccount
          );
          break;
        case Contract.TEAM_LEADER:
          this._contractsInstance[contractName] = new MiniEmployees(
            relatedContract,
            this._web3,
            this.selectedAccount
          );
          break;
        case Contract.TEAM_BUILDER:
          this._contractsInstance[contractName] = new TeamBuilder(
            relatedContract,
            this._web3,
            this.selectedAccount
          );
          break;
        case Contract.EMPLOYEES_TEAM:
          this._contractsInstance[contractName] = new EmployeesTeam(
            relatedContract,
            this._web3,
            this.selectedAccount
          );
          break;
        default:
          break;
      }
    }
  }

  get token() {
    return this._contractsInstance[Contract.TOKEN];
  }

  get tokenBase() {
    return this._contracts[Contract.TOKEN];
  }

  get specialToken() {
    return this._contractsInstance[Contract.SPECIAL_TOKEN];
  }

  get specialTokenBase() {
    return this._contracts[Contract.SPECIAL_TOKEN];
  }

  get employees() {
    return this._contractsInstance[Contract.EMPLOYEES];
  }

  get employeesBase() {
    return this._contracts[Contract.EMPLOYEES];
  }

  get baseDeployer() {
    return this._contractsInstance[Contract.BASE_DEPLOYER];
  }

  get baseDeployerBase() {
    return this._contracts[Contract.BASE_DEPLOYER];
  }

  get employeeDeployer() {
    return this._contractsInstance[Contract.EMPLOYEE_DEPLOYER];
  }

  get employeeDeployerBase() {
    return this._contracts[Contract.EMPLOYEE_DEPLOYER];
  }

  get factoryDeployer() {
    return this._contractsInstance[Contract.FACTORY_DEPLOYER];
  }

  get factoryDeployerBase() {
    return this._contracts[Contract.FACTORY_DEPLOYER];
  }

  get factories() {
    return this._contractsInstance[Contract.FACTORIES];
  }

  get factoriesBase() {
    return this._contracts[Contract.FACTORIES];
  }

  get miniEmployees() {
    return this._contractsInstance[Contract.MINI_EMPLOYEES];
  }

  get miniEmployeesBase() {
    return this._contracts[Contract.MINI_EMPLOYEES];
  }

  get miniEmployeesDeployer() {
    return this._contractsInstance[Contract.MINI_EMPLOYEES_D];
  }

  get miniEmployeesDeployerBase() {
    return this._contracts[Contract.MINI_EMPLOYEES_D];
  }

  get multiEmployees() {
    return this._contractsInstance[Contract.MULTI_EMPLOYEE];
  }

  get multiEmployeesBase() {
    return this._contracts[Contract.MULTI_EMPLOYEE];
  }

  get multiEmployeesDeployer() {
    return this._contractsInstance[Contract.MULTI_EMPLOYEE_D];
  }

  get multiEmployeesDeployerBase() {
    return this._contracts[Contract.MULTI_EMPLOYEE_D];
  }

  get employeesExpanded() {
    return this._contractsInstance[Contract.EMPLOYEES_EXPANDED];
  }

  get employeesExpandedBase() {
    return this._contracts[Contract.EMPLOYEES_EXPANDED];
  }

  get nftBridgeStorage() {
    return this._contractsInstance[Contract.NFT_BRIDGE_STORAGE];
  }

  get teamBuilder() {
    return this._contractsInstance[Contract.TEAM_BUILDER];
  }

  get employeesTeam() {
    return this._contractsInstance[Contract.EMPLOYEES_TEAM];
  }

  get nftBridgeStorageBase() {
    return this._contracts[Contract.NFT_BRIDGE_STORAGE];
  }

  get cities() {
    return this._contractsInstance[Contract.CITIES];
  }

  get citiesBase() {
    return this._contracts[Contract.CITIES];
  }

  get citiesDeployer() {
    return this._contractsInstance[Contract.CITIES_DEPLOYER];
  }

  get citiesDeployerBase() {
    return this._contracts[Contract.CITIES_DEPLOYER];
  }

  get cityRelationsStorage() {
    return this._contractsInstance[Contract.CITIES_STORAGE];
  }

  get cityRelationsStorageBase() {
    return this._contracts[Contract.CITIES_STORAGE];
  }

  get universities() {
    return this._contractsInstance[Contract.UNIVERSITIES];
  }

  get cityGetters() {
    return this._contractsInstance[Contract.CITY_GETTERS];
  }

  get teamLeaderValidations() {
    return this._contractsInstance[Contract.TEAM_LEADER_V];
  }

  get teamLeader() {
    return this._contractsInstance[Contract.TEAM_LEADER];
  }

  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                             ANCHOR Validations                             */
  /* -------------------------------------------------------------------------- */

  private async _networkIsValidInContract(contract: ContractData) {
    let network: ABINetworkData | undefined | boolean = false;

    if (this._web3) {
      let networkId = await this._web3.eth.net.getId();

      UtilsHelpers.debugger("Load contract from " + networkId);

      if (networkId === parseInt(BlockChainHelpers.getAppChain().chainId, 16)) {
        network = contract?.data?.networks[networkId];
      }
    }

    return !!network ? network : false;
  }

  private _contractDataIsValid(contract: ContractData | null | undefined) {
    return (
      contract !== undefined &&
      contract !== null &&
      !!contract.data &&
      !!contract.contract &&
      !!contract.data.abi
    );
  }

  private _contractsStateIsValid(contractsState?: ContractsState) {
    let state: ContractsState | null = contractsState
      ? contractsState
      : this._contractsData;

    return state /* && state?.Connections */;
  }

  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                             ANCHOR Getters                                 */
  /* -------------------------------------------------------------------------- */

  get selectedAccount() {
    return this._accounts?.length ? this._accounts[0] : null;
  }

  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                            ANCHOR Class actions                            */
  /* -------------------------------------------------------------------------- */

  private _destroy() {
    this.principalListener.removeAllListeners();
  }

  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                               ANCHOR Storage                               */
  /* -------------------------------------------------------------------------- */

  static saveBlockChainController(
    state: BlockChainState,
    controller: BlockChain
  ): BlockChainState {
    return {
      ...state,
      controller,
      customer: null,
      error: null,
      firstLoad: true,
    };
  }

  static destroyBlockChainController(state: BlockChainState): BlockChainState {
    if (state.controller) state.controller._destroy();
    return { ...state, controller: null };
  }

  static setBlockChainError(
    state: BlockChainState,
    error: AppErrorCode
  ): BlockChainState {
    if (ULTRA_ERRORS.includes(error)) {
      return { ...state, error, customer: null, controller: null };
    } else return { ...state, error };
  }

  /* -------------------------------------------------------------------------- */
}
