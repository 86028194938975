import { AbstractCityUniversities } from "../abstracts/cityUniversities";
import { ContractBaseModel } from "../abstracts/contract";
import { appConfig, AppErrorCode } from "../app";

export class CityUniversities extends ContractBaseModel<AbstractCityUniversities> {
  getUserRewards() {
    return this.contract.getUserRewards(this.selectedAccount).call(this.config);
  }

  getCitiesRewards() {
    return this.contract.getUserRewards(this.selectedAccount).call(this.config);
  }

  canTeamPlay(id: number) {
    return this.contract.canTeamPlay(id).call(this.config);
  }

  withdrawRelationsRewards(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .withdrawRelationsRewards()
      .send({
        ...this.config,
        gasPrice: this.web3.utils.toWei(appConfig.fastTransaction, "Gwei"),
      })
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINT_ERROR, receipt, callback);
      })
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.MINT_ERROR, error, callback)
      );
  }

  withdrawFactoriesRewards(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .withdrawFactoriesRewards()
      .send({
        ...this.config,
        gasPrice: this.web3.utils.toWei(appConfig.fastTransaction, "Gwei"),
      })
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINT_ERROR, receipt, callback);
      })
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.MINT_ERROR, error, callback)
      );
  }

  withdrawCityRewards(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .withdrawCityRewards()
      .send({
        ...this.config,
        gasPrice: this.web3.utils.toWei(appConfig.fastTransaction, "Gwei"),
      })
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINT_ERROR, receipt, callback);
      })
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.MINT_ERROR, error, callback)
      );
  }

  withdrawMiniEmployeesRewards(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .withdrawMiniEmployeesRewards()
      .send({
        ...this.config,
        gasPrice: this.web3.utils.toWei(appConfig.fastTransaction, "Gwei"),
      })
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINT_ERROR, receipt, callback);
      })
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.MINT_ERROR, error, callback)
      );
  }

  useUniversitiesXP(
    employees: number[],
    callback: (error: AppErrorCode | null) => void
  ) {
    return this.contract
      .useUniversitiesXP(employees)
      .send({
        ...this.config,
        gasPrice: this.web3.utils.toWei(appConfig.fastTransaction, "Gwei"),
      })
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINT_ERROR, receipt, callback);
      })
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.MINT_ERROR, error, callback)
      );
  }
}
